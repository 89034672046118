import React, {useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';

import AuthImage from '../images/backgrounds/quer.jpg';
import AuthDecoration from '../images/auth-decoration.png';

import {useDispatch} from "react-redux";
import {SessionState, updateAvatar, updateSession} from "../store/sessionStore";
import ApiCaller from "../lib/ApiCaller";
import AuthApi from "../api/AuthApi";
import Settings from "../lib/settings";
import User from '../lib/common/models/user';
import Logo from "../components/layout/partials/Logo";
import UserApi from "../api/UserApi";


const Signin = () => {

  const dispatch = useDispatch();
  const inputs = ['email', 'password'];
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const history = useHistory();

  const doAuth = (e: React.FormEvent) => {
    e.preventDefault();

    if(!email || !password) {
      return;
    }

    var authApi = new AuthApi(new ApiCaller());
    authApi.login(email, password).then((data:any) => {
      if(data.status != "confirmed") {
        history.push('/registered');
        return;
      }
      let user = new User({
        name: data.name,
        email: data.email,
        token: data.token,
        type: data.type,
        status: data.status
      });
      user.id=data._id;
      const userApi = new UserApi(new ApiCaller());
      const url = userApi.getAvatarUrl(user.id || '');
      const sessionData:SessionState = {
        loggedIn: user.email? true : false,
        ready: true,
        userInfo: user,
        type: user.type,
        layout: user.type === 'admin' ? 'nextest' : user.type,
        avatar: url
      };
      dispatch(updateSession(sessionData));

      Settings.setCurrentUser(user).then(() => {
        history.push("/"+data.type);
      });

    }).catch(err => {
      console.error(err);
      alert('Usuario o contraseña incorrectos');
    });
    e.preventDefault();
  }

  useEffect(()=>{{
    // const e:any={preventDefault:()=>{}};
    // doAuth(e);
  }})

  return (
    <main className="bg-white">

      <div className="relative md:flex">


        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">

            <div className="w-full">
              <div className="flex mb-12">
                <Logo/>
              </div>
              {/* Form */}
              <form onSubmit={doAuth}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address</label>
                    <input id="email" className="form-input w-full" type="email"  value={email} onChange={e => {setEmail(e.target.value)}}/>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
                    <input id="password" className="form-input w-full" type="password" autoComplete="on" value={password} onChange={e => {setPassword(e.target.value)}}/>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link className="text-sm underline hover:no-underline" to="/reset-password">Forgot Password?</Link>
                  </div>
                  <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white ml-3" type="submit" >Sign In</button>
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account? <Link className="font-medium text-nexoGreen-500 hover:text-nexoGreen-600" to="/signup">Sign Up</Link>
                </div>
                {/* Warning */}
                <div className="mt-5">
                  <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                    <svg className="inline w-3 h-3 flex-shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">
                      This app is under Beta Testing
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8" src={AuthDecoration} width="118" height="124" alt="Authentication decoration" />
        </div>

      </div>

    </main>
  );
}

export default Signin;
