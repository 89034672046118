import React, {useEffect, useState} from 'react';
import ModalBasic from "../../components/ModalBasic";
import JenkinsApi from "../../api/JenkinsApi";
import ApiCaller from "../../lib/ApiCaller";
import toast from "../../shared/utils/toast";
import PadApi from "../../api/PadApi";
import {formatDate, formatHour} from "../../shared/utils/dateTime";

interface props {
    feedbackModalOpen:boolean;
    setFeedbackModalOpen: Function;
    contact: any;
}

const DetailContactForm: React.FC<props> = ({feedbackModalOpen,setFeedbackModalOpen, contact}) => {

    const statusColor = (status:string) => {
        switch (status) {
            case 'ANSWERED':
                return 'bg-green-100 text-green-600';
            case 'REQUESTED':
                return 'bg-yellow-100 text-yellow-600';
            case 'FAILURE':
                return 'bg-red-100 text-red-500';
            default:
                return 'bg-gray-100 text-gray-500';
        }
    };

    const [kom, setKom] = useState([])

    useEffect(()=>{
        const api = new PadApi(new ApiCaller());
        contact && api.getLogStateFromPhone(contact.phone).then(kom => {
            setKom(kom);
        })
    },[contact]);

    const vars = ['name', 'place', 'date', 'phone', 'email', 'status'];

    return (<div className="m-1.5">
        {/* Start */}
        <ModalBasic id="contact-modal" modalOpen={feedbackModalOpen} setModalOpen={setFeedbackModalOpen} title="Detalles del contacto">
            {/* Modal content */}
            <div className="px-5 py-4">
                <div className="text-sm">
                    <div className="font-semibold text-blue-800 mb-2">Información de la reserva</div>
                    <div className="space-y-2">
                        { contact && vars.map(k=><div className={'flex'}>
                            <label className="block text-sm font-small mb-1 pt-4 pr-4" htmlFor={k}>{k}</label>
                            <input id={k} className={`form-input w-full px-2 py-1 text-sm`} type="text" required value={contact[k]}/>
                        </div>) }
                        { contact && Object.keys(contact).map(k=><div className="text-xs inline-flex font-medium bg-light-blue-100 text-light-blue-600 rounded-full text-center px-2.5 py-1">{k}</div>) }
                    </div>
                    {/* Work History */}
                    <div className={'pt-4'}>
                        <h2 className="text-blue-800 font-semibold mb-2">Comunicaciones</h2>
                        <div className="bg-white p-4 border border-gray-200 rounded-sm shadow-sm">
                            <ul className="space-y-3">
                                { kom && kom.map((k:any) => <><li className="sm:flex sm:items-center sm:justify-between">
                                    <div className="sm:flex-grow flex items-center text-sm">
                                        {/* Icon */}
                                        <div className={`w-8 h-8 rounded-full flex-shrink-0 ${k.status=='ANSWERED'?'bg-green-500':'bg-yellow-500'} my-2 mr-3`}>
                                            <svg className="w-8 h-8 fill-current text-yellow-50" viewBox="0 0 32 32">
                                                <path d="M21 14a.75.75 0 0 1-.75-.75 1.5 1.5 0 0 0-1.5-1.5.75.75 0 1 1 0-1.5 1.5 1.5 0 0 0 1.5-1.5.75.75 0 1 1 1.5 0 1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1 0 1.5 1.5 1.5 0 0 0-1.5 1.5.75.75 0 0 1-.75.75Zm-7 10a1 1 0 0 1-1-1 4 4 0 0 0-4-4 1 1 0 0 1 0-2 4 4 0 0 0 4-4 1 1 0 0 1 2 0 4 4 0 0 0 4 4 1 1 0 0 1 0 2 4 4 0 0 0-4 4 1 1 0 0 1-1 1Z" />
                                            </svg>
                                        </div>
                                        {/* Position */}
                                        <div>
                                            <div className="font-medium text-gray-800">{k.type}</div>
                                            <div className="flex flex-nowrap items-center space-x-2 whitespace-nowrap">
                                                <div className={ statusColor(k.status)}>{k.status}</div>
                                                <div className="text-gray-400">·</div>
                                                <div>{formatDate(k.createdAt)}</div>
                                                <div>{formatHour(k.createdAt)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tags */}
                                    <div className="sm:ml-2 mt-2 sm:mt-0">
                                        <ul className="flex flex-wrap sm:justify-end -m-1">
                                            <li className="m-1">
                                                <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-gray-200 hover:border-gray-300 shadow-sm bg-white text-gray-500 duration-150 ease-in-out">
                                                    {k.images ? `${k.images.length} images` : ''}</button>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    {k.images && k.images.map((i:string)=><a href={`api/v1/documents/images/${i}`} target={'_black'}><img src={`api/v1/documents/images/${i}`}/></a>)}
                                </li>
                                <li>
                                    {k.payload && k.payload.observaciones && <q>{k.payload.observaciones}</q>}
                                </li>
                                </>
                                ) }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal footer */}
            <div className="px-5 py-4 border-t border-gray-200">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button className="btn-sm bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" onClick={(e)=>{e.stopPropagation();setFeedbackModalOpen(false)}}>Ok</button>
                </div>
            </div>
        </ModalBasic>
        </div>
    )
}

export default DetailContactForm;
