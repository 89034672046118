import { combineReducers, createStore } from 'redux'
import { sessionReducer } from './sessionStore'
import { docsReducer } from './docsStore';
import { jenkinsReducer } from './jenkinsStore';
import { padReducer } from './padStore';
import {notificationReducer} from "./notificationStore";
import {pdfReducer} from "./pdfStore";

const appReducer = combineReducers({
    pad: padReducer,
    session: sessionReducer,
    current: docsReducer,
    jenkins: jenkinsReducer,
    notification: notificationReducer,
    pdf: pdfReducer
});

export type AppState = ReturnType<typeof appReducer>

export default function configureStore() {
    const store = createStore(appReducer);
    return store;
}
