import React from 'react';


// import sections
import HeroVoice from '../../../components/sections/HeroVoice';

function Voice (){

return (

       <React.Fragment>
            <HeroVoice />
        </React.Fragment>

  );
}

export default Voice;