import React, { forwardRef } from 'react';

const InputParte = forwardRef(({label, nameField, value,  onChange=()=>{}, errors, type='text'}:
                                   {label:string,nameField:string, errors:any, value:string,type?:string, onChange:any}, ref) => {

    const handleChange = (event:any) => {
        onChange({[nameField]:event.target.value});
    };

  return (<div className="sm:w-1/3">
        <label className="block text-sm font-medium mb-1" htmlFor={nameField}>{label}</label>
          { type=='text' &&
            <input
                id={nameField}
                className={`form-input w-full  ${errors[nameField] && 'border-red-300'}`}
                type="text"
                value={value}
                onChange={handleChange}
            />  }
            { type=='textarea' &&
                <textarea id={nameField}
                          className={`form-textarea w-full focus:border-gray-300 ${errors[nameField] && 'border-red-300'}`}
                          rows={4}
                          placeholder="…"
                          value={value}
                          onChange={handleChange}
                ></textarea> }

        {errors[nameField] && <div className="text-xs mt-1 text-red-500">!!!!!!!!!</div>}
      </div>
  );
});

export default InputParte;
