import React from 'react';
// import section header
import SectionHeader from '../../components/sections/partials/SectionHeader';
// import sections
import HeroPricing from '../../components/sections/HeroPricing';
import Clients from '../../components/sections/Clients';
import GenericSection from '../../components/sections/GenericSection';
import Cta from '../../components/sections/Cta';
// import some required elements
import Accordion from '../../components/elements/Accordion';
import AccordionItem from '../../components/elements/AccordionItem';

class Price extends React.Component {

  state = {
    demoModalActive: false,
    videoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {

    const genericSection02Header = {
      title: 'FAQ - Preguntas Frecuentes'
    }

    return (
      <React.Fragment>
        <HeroPricing className="illustration-section-01" />

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection02Header} className="center-content" />
            <Accordion>
              <AccordionItem title="¿ Cómo puedo usar nextext.io ?" active>
                Es muy fácil, abre una cuenta y crea un proyecto de test, después solo tienes que subir tu set de pruebas, nosotros hacemos el resto y te entregamos los resultados
              </AccordionItem>
              <AccordionItem title="¿ Cómo puedo acceder a los resultados de los tests ?">
                Si a sí lo quieres, podemos guardar los resultados en la plataforma.
                Todo esto lo guardamos de manera ordenada dentro de tu cuenta para que lo puedas utilizar facilmente.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>

        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default Price;
