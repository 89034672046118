import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3'])
}

const defaultProps = {
  children: null,
  tag: 'h2'
}

class SectionSubHeader extends React.Component {

  render() {

    const {
      className,
      data,
      children,
      tag,
      ...props
    } = this.props;

    const classes = classNames(
      'section-subheader',
      className
    );

    const Component = tag;

    return (
      <React.Fragment>
        {(data.Subtitle || data.paragraph) &&
          <div
            {...props}
            className={classes}
          >
            <div className="container">
              {children}
              {data.subtitle &&
                <Component className={
                  classNames(
                    'mt-0','sub-title-smart text-6xl md:text-4xl text-smartCountry-50',
                    data.paragraph ? 'mb-10' : 'mb-0'
                  )}>{data.subtitle}</Component>
              }
              {data.paragraph &&
                <p className="m-0 sub-title-smart text-4xl md:text-2xl text-smartCountry-51">{data.paragraph}</p>
              }
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

SectionSubHeader.propTypes = propTypes;
SectionSubHeader.defaultProps = defaultProps;

export default SectionSubHeader;
