import React from 'react';

// import sections
import GenericSection from "../../components/sections/GenericSection";

function Clients() {

    return (
        <React.Fragment>
              
              <GenericSection>
                  <h1>Clients</h1>
              </GenericSection>

        </React.Fragment>
    );
  }
  
  export default Clients;
  