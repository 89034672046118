import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfigFile from '../css/tailwind.config.js';
import moment from "moment";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile);
}

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const formatThousands = (value) => Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const prepareContactWeekWidget = (contacts) => {
  let passed = [];let failed = [];let labels = [];
  Object.keys(contacts).map(k => {
    labels.push(moment(new Date(k)).week());
    passed.push(contacts[k].length);
    failed.push(0);
  })
  return {passed,failed,labels}
}
export const prepareContactTotalWeekWidget = (contacts) => {
  let adults = [];let kids = [];let bebes = [];let labels = [];
  Object.keys(contacts).map(k => {
    var groupBy = contacts[k]
        .reduce((a, x) => {
          const personas = x.personas || '0-0-0';
          const pers = personas.split('-');
          const date = x['date'].includes(' ') ? x['date'].substring(0,10).trim() : x['date'].trim();
          a[date] = {
            adults: parseInt(a[date]?a[date].adults:0 ) + parseInt(pers[0]),
            kids: parseInt(a[date]?a[date].kids:0 ) + parseInt(pers[1]),
            bebes:parseInt(a[date]?a[date].bebes:0 ) + parseInt(pers[2])
          };
          return a;
        }, {});
    let we = { adults: 0, kids: 0, bebes: 0};
    Object.keys(groupBy).map(d => {
      we = {
        adults: we.adults + parseInt(groupBy[d].adults),
        kids: we.kids + parseInt(groupBy[d].kids),
        bebes: we.bebes + parseInt(groupBy[d].bebes)
      };
    });

    let label = moment(new Date(k)).week();
    labels.push(label);
    adults.push(we.adults);
    kids.push(we.kids);
    bebes.push(we.bebes);
  })
  return {adults,kids,bebes,labels}
}

export const prepareBuildsForChart4 = (builds) => {
  let passed = [];let failed = [];let labels = [];
  if (builds.length==0) return {passed,failed,labels};
  builds.map(b=>{
    let countPassed=0;
    let countFailed=0;
    labels.push(b.number);
    if (b.result!='IN PROGRESS') {
      b.artifact.artifact.data.map(d=>{
        d.elements.map(s=>{
          s.steps.map(r=>{
            if (r.result.status=='passed') countPassed++;
            else countFailed++;
          })
        })
      })
    }

    passed.push(countPassed);
    failed.push(countFailed);
    })
    return {passed,failed,labels}
  }

export const prepareScenarioData = (builds) => {
  let passed = [];let failed = [];let labels = [];
  if (builds.length==0) return {passed,failed,labels};
  builds.map(b=>{
    let countPassed=0;
    let countFailed=0;
    if (b.result!='IN PROGRESS') {
      b.artifact.artifact.data.map(d=>{
        d.elements.map(s=>{
          labels.push(b.number);
          let hasFailed=false;
          s.steps.map(r=>{if (r.result.status=='failed') hasFailed=true;})
          if (hasFailed) countFailed++;
          else countPassed++;
          passed.push(countPassed);
          failed.push(countFailed);
        })
      })
    }


    })
    return {passed,failed,labels}
  }


export const prepareRoatPerDay = (contacts, roats, pay) => {
  let a = {}

  const roatGrouped = roats.reduce((a, x) => {
    const date = x['date'].includes(' ') ? x['date'].substring(0,10).trim() : x['date'].trim();
    (a[date] = a[date] || []).push(x);
    return a;}, {});
  const pagosGrouped = pay.reduce((a, x) => {
    if (x['status']=='PAY') {
      const date = x['date'].includes(' ') ? x['date'].substring(0,10).trim() : x['date'].trim();
      (a[date] = a[date] || []).push(x);
    }
    return a;}, {});
  Object.keys(contacts).map(k => {
    contacts[k].map(x => {
      if (x.status=='CANCELED') return;
      const personas = x.personas || '0-0-0';
      const pers = personas.split('-');

      const parsedDate = new Date(x['dateCaptured']);
      const dateD = new Date( parsedDate.getTime() + Math.abs(parsedDate.getTimezoneOffset()*60000) );
      const date = dateD.toISOString();
      const idxDate = moment(dateD).format('DD/MM/YYYY');

      a[date] = {
        adults: parseInt(a[date]?a[date].adults:0 ) + parseInt(pers[0]),
        kids: parseInt(a[date]?a[date].kids:0 ) + parseInt(pers[1]),
        bebes:parseInt(a[date]?a[date].bebes:0 ) + parseInt(pers[2]),
        roats:(roatGrouped[idxDate]||[]).length,
        reservas:a[date]?a[date].reservas+1:1,
        pagos:(pagosGrouped[idxDate]||[]).length,
      };
    });
  })
  return a;
}

export const days12After = (rows) => {
  return rows;
}

