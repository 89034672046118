import React from 'react';
import {NavLink, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../store/store";
const { pathname } = location;

const Pisosadias = () => {

    const userInfo = useSelector((state:AppState) => state.session.userInfo);
    const location = useLocation();
    const { pathname } = location;

    return (<div className="space-y-8">
        {/* More group */}
        <div>
            <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
                <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span>
                <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">{userInfo.type}</span>
            </h3>
            <ul className="mt-3">
                {/* dash */}
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('dashboard') && 'bg-gray-900'}`}>
                    <NavLink exact to="/dashboard" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('dashboard') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-400 ${pathname.includes('dashboard') && '!text-nexoGreen-500'}`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('dashboard') && 'text-nexoGreen-600'}`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('dashboard') && 'text-nexoGreen-200'}`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Dashboard</span>
                        </div>
                    </NavLink>
                </li>

                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('contacts') && 'bg-gray-900'}`}>
                    <NavLink exact to="/contacts/pisosadias" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('contacts') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <div className="flex items-center">
                                <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                    <path className={`fill-current text-gray-600 ${pathname.includes('contacts') && 'text-indigo-500'}`} d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('contacts') && 'text-indigo-300'}`} d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z" />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Hotel</span>
                            </div>

                        </div>
                    </NavLink>
                </li>

                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('notifications') && 'bg-gray-900'}`}>
                    <NavLink exact to="/notifications" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('notifications') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-600 ${pathname.includes('notifications') && 'text-indigo-500'}`} d="M14.5 7c4.695 0 8.5 3.184 8.5 7.111 0 1.597-.638 3.067-1.7 4.253V23l-4.108-2.148a10 10 0 01-2.692.37c-4.695 0-8.5-3.184-8.5-7.11C6 10.183 9.805 7 14.5 7z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('notifications') && 'text-indigo-300'}`} d="M11 1C5.477 1 1 4.582 1 9c0 1.797.75 3.45 2 4.785V19l4.833-2.416C8.829 16.85 9.892 17 11 17c5.523 0 10-3.582 10-8s-4.477-8-10-8z" />
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Komunications</span>
                        </div>
                    </NavLink>
                </li>

                {/* jects */}
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('roat') && 'bg-gray-900'}`}>
                    <NavLink exact to="/roat/pisosadias" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('roat') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <div className="flex items-center">
                                <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                    <path className={`fill-current text-gray-600 ${pathname.includes('roat') && 'text-indigo-500'}`}  d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('roat') && 'text-indigo-300'}`} d="M1 1h22v23H1z" />
                                    <path className={`fill-current text-gray-600 ${pathname.includes('roat') && 'text-indigo-500'}`} d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z" />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">ROAT</span>
                            </div>
                        </div>
                    </NavLink>
                </li>
                {/* jects */}
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('contacts') && 'bg-gray-900'}`}>
                    <NavLink exact to="/contacts/pisosadias" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('contacts') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <div className="flex items-center">
                                <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                    <path className={`fill-current text-gray-600 ${pathname.includes('contacts') && 'text-indigo-500'}`} d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('contacts') && 'text-indigo-300'}`} d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z" />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Reservas</span>
                            </div>

                        </div>
                    </NavLink>
                </li>
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('vcards') && 'bg-gray-900'}`}>
                    <NavLink exact to="/pisosadias-vcards" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('vcards') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-600 ${pathname.includes('vcards') && 'text-indigo-500'}`} d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('vcards') && 'text-indigo-300'}`} d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z" />
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">vCards</span>
                        </div>
                    </NavLink>
                </li>

                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('pdf-sample') && 'bg-gray-900'}`}>
                    <NavLink exact to="/pdf-sample" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('pdf-sample') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 14 14">
                                    <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                                        <polygon points="51.791 356.65 51.791 23.99 204.5 23.99 282.65 102.07 282.65 356.65" fill="#fff" stroke-width="212.65"/>
                                        <path d="m201.19 31.99 73.46 73.393v243.26h-214.86v-316.66h141.4m6.623-16h-164.02v348.66h246.85v-265.9z" stroke-width="21.791"/>
                                    </g>
                                     <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                                        <polygon points="282.65 356.65 51.791 356.65 51.791 23.99 204.5 23.99 206.31 25.8 206.31 100.33 280.9 100.33 282.65 102.07" fill={`${pathname.includes('pdf-sample') ? '#fff' : '#575E86'}`} stroke-width="212.65"/>
                                        <path d="m198.31 31.99v76.337h76.337v240.32h-214.86v-316.66h138.52m9.5-16h-164.02v348.66h246.85v-265.9l-6.43-6.424h-69.907v-69.842z" stroke-width="21.791"/>
                                    </g>
                                    <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" stroke-width="21.791">
                                        <polygon points="258.31 87.75 219.64 87.75 219.64 48.667 258.31 86.38"/>
                                        <path d="m227.64 67.646 12.41 12.104h-12.41v-12.104m-5.002-27.229h-10.998v55.333h54.666v-12.742z"/>
                                    </g>
                                    <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" fill={`${pathname.includes('pdf-sample') ? '#ed1c24' : '#575E86'}`} stroke-width="212.65">
                                        <polygon points="311.89 284.49 22.544 284.49 22.544 167.68 37.291 152.94 37.291 171.49 297.15 171.49 297.15 152.94 311.89 167.68"/>
                                        <path d="m303.65 168.63 1.747 1.747v107.62h-276.35v-107.62l1.747-1.747v9.362h272.85v-9.362m-12.999-31.385v27.747h-246.86v-27.747l-27.747 27.747v126h302.35v-126z"/>
                                    </g>
                                    <rect x="1.7219" y="7.9544" width="10.684" height="4.0307" fill="none"/>
                                    <g transform="matrix(.04589 0 0 .04589 1.7219 11.733)" fill={`${pathname.includes('pdf-sample') ? '#fff' : '#f9f9f9'}`} stroke-width="21.791" aria-label="PDF"><path d="m9.216 0v-83.2h30.464q6.784 0 12.928 1.408 6.144 1.28 10.752 4.608 4.608 3.2 7.296 8.576 2.816 5.248 2.816 13.056 0 7.68-2.816 13.184-2.688 5.504-7.296 9.088-4.608 3.456-10.624 5.248-6.016 1.664-12.544 1.664h-8.96v26.368zm22.016-43.776h7.936q6.528 0 9.6-3.072 3.2-3.072 3.2-8.704t-3.456-7.936-9.856-2.304h-7.424z"/><path d="m87.04 0v-83.2h24.576q9.472 0 17.28 2.304 7.936 2.304 13.568 7.296t8.704 12.8q3.2 7.808 3.2 18.816t-3.072 18.944-8.704 13.056q-5.504 5.12-13.184 7.552-7.552 2.432-16.512 2.432zm22.016-17.664h1.28q4.48 0 8.448-1.024 3.968-1.152 6.784-3.84 2.944-2.688 4.608-7.424t1.664-12.032-1.664-11.904-4.608-7.168q-2.816-2.56-6.784-3.456-3.968-1.024-8.448-1.024h-1.28z"/><path d="m169.22 0v-83.2h54.272v18.432h-32.256v15.872h27.648v18.432h-27.648v30.464z"/></g>
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">PDF Sample</span>
                        </div>
                    </NavLink>
                </li>

                {/* Settings */}
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('settings') && 'bg-gray-900'}`}>
                    <NavLink exact to="/settings/account" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('settings') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-600 ${pathname.includes('settings') && 'text-nexoGreen-600'}`} d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('settings') && 'text-nexoGreen-400'}`} d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('settings') && 'text-nexoGreen-600'}`} d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('settings') && 'text-nexoGreen-400'}`} d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z" />
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Settings</span>
                        </div>
                    </NavLink>
                </li>


            </ul>
        </div>
    </div>)
}

export default Pisosadias;
