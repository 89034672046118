import React, {useState} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import Logo from './partials/Logo';
import Button from "../elements/Button";

import Settings from "../../lib/settings"
import {AppState} from "../../store/store";
import {useSelector} from "react-redux";
import {Item} from "./Styles";
import AskALaywerTooltip from "../added/AskALaywerTooltip";
import {ToastContainer, toast} from "react-toastify";

interface defaultProps {
  className:string,
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}
const Header : React.FC<defaultProps> = ({className, active, navPosition, hideNav, hideSignin, bottomOuterDivider, bottomDivider}) => {

  const [isActive,sisActive] = useState(false);
  const [country,scountry] = useState('es');

  let nav:any = React.createRef();
  let usermenu:any = React.createRef();
  let hamburger:any = React.createRef();
  let closeClick:Function;

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    usermenu.current.classList.add('show');
    sisActive(true)
  }
  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    usermenu.current && usermenu.current.classList.remove('show');
    sisActive(false)
  }
  const keyPress = (e:any) => {isActive && e.keyCode === 27 && closeMenu();}

  const clickOutside = (e: any) => {
    if (!nav.current) return;
    if (!usermenu.current) return;
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }
  const logout = () => {
    closeMenu();
    Settings.logout().then(() => {
      document.location.href = "/";
    });
  }
  const currentUser = useSelector((state: AppState) => state.session.userInfo);

  React.useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    Settings.getCurrentCountry().then((c) => {
      scountry(c)
    });
  });

    return (
        <header className= "bg-transparent top-0 z-30" ref={nav}>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="border-solid border-2 border-white flex items-center justify-between h-24 -mb-px">
            <div className="flex">
              <Logo/>
            </div>
            <div className="flex items-center">
            {!hideNav &&
              <>
                <Link to={`/signin/`} className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" onClick={closeMenu}  ref={usermenu}>Accede</Link>

                <Link to={`/manager`} onClick={closeMenu} className="pr-4" ><button className="btn bg-grey-500 hover:bg-nexoGreen-100 text-black button-wide-mobile ">Manager de comunicación</button></Link>
                <Link to={`/automation/`} onClick={closeMenu} className="pr-4" ><button className="btn bg-grey-500 hover:bg-nexoGreen-100 text-black button-wide-mobile ">Automatización empresarial</button></Link>
                <Link to={`/iahospitality/`} onClick={closeMenu} className="pr-4" ><button className="btn bg-grey-500 hover:bg-nexoGreen-100 text-black button-wide-mobile ">IA para hostelería</button></Link>
                {/*<Link to={`/clients/`} onClick={closeMenu} className="pr-4" ><button className="btn bg-grey-500 hover:bg-nexoGreen-100 text-black button-wide-mobile ">Nuestros Clientes</button></Link>*/}




         <AskALaywerTooltip
                              onClose={()=>{closeClick()}}
                              placement="bottom"
                              offset={{ top: 30, left:-250 }}
                              renderLink={(linkProps:any) => {
                                 closeClick=linkProps.onClick;
                                 return (<Item {...linkProps} >
                                            <a href="#" className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white ">¿Preguntas?</a>
                                        </Item>)
                               }}
                              onCreate={(ticket:string)=>{closeClick(); toast.success('Se ha creado una solicitud de información con el número de ticket '+ticket)}}
                          />
          {/*<span className={"pl-32"}></span>*/}



                    { (currentUser && currentUser.type=='apiumuser') &&
                      <ul className="list-reset header-nav-right">
                        <li className="reveal-from-top" data-reveal-delay="200">
                          {/*<Link to={`/${country}/signup/`} className="button button-secondary button-wide-mobile button-sm" aria-controls="demo-modal" onClick={openModal}>Pide una Cita</Link>*/}
                        </li>
                        &nbsp;
                        <li className="reveal-from-top" data-reveal-delay="300">
                          <Link to={`/`} className="button button-secondary button-wide-mobile button-sm" onClick={logout}>Logout</Link>
                        </li>
                      </ul> }

              </>}
            </div>
          </div>
        </div>
      </header>
    )

}

export default Header;
