import Settings from '../lib/settings';

class ApiCaller {
    token: string;
    constructor(token?:string) {
        this.token = token?token:"";
    }

    call(url:string, method:string, params?:object, token?:string) : Promise<any>{
        var fetchParams:any = {
            method: method,
            headers: {'Content-Type': 'application/json'},
        }

        if (params) fetchParams.body = JSON.stringify(params);
        if (token) fetchParams.headers['x-access-token'] = token;
        let separator = '?';
        if(url.indexOf('?') !== -1) separator = '&';

        var defUrl = url + (this.token?separator+"token="+this.token:"");

        return fetch(Settings.getApiURL() +defUrl, fetchParams).then(response => response.json());
    }

    callImage(url:string, method:string, params?:object, token?:string) : Promise<any>{
        var fetchParams:any = {
            method: method,
        }

        if (params) {
            fetchParams.body = params;
        }

        let separator = '?';
        if(url.indexOf('?') != -1) {
            separator = '&';
        }
        var defUrl = url + (token ? separator + "token=" + token : "");

        return fetch(Settings.getApiURL() + defUrl, fetchParams)
            .then(response => response.json());
    }

    download(url:string, method:string, params?:object) : Promise<any>{
        var fetchParams:any = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if(params) {
            fetchParams.body = JSON.stringify(params);
        }

        let separator = '?';
        if(url.indexOf('?') != -1) {
            separator = '&';
        }
        var defUrl = url + (this.token?separator+"token="+this.token:"");

        return fetch(Settings.getApiURL() +defUrl, fetchParams)
            .then(response => response.blob());
    }
}

export default ApiCaller;
