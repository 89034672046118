import ApiCaller from '../lib/ApiCaller';
import NewsletterContact from "../lib/common/models/newsletterContact";

class AuthApi {
    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    login(email:string, password:string): Promise<void  | undefined> {
        return this.apiCaller.call('/v1/auth/login', 'POST', {email:email, password:password})
    }

    register(email:string, password:string, name:string): Promise<void  | undefined> {
        return this.apiCaller.call('/v1/auth/register/', 'POST', {name:name, email:email, password:password, type: 'padawan'})
    }

    generateQrAuth(): Promise<string> {
        return this.apiCaller.call('/v1/auth/qr/generate', 'GET')
    }

    getTokenById(id: string): Promise<string> {
        return this.apiCaller.call('/v1/auth/qr/'+id, 'GET');
    }

    verify(email: string, token: string): Promise<void | undefined> {
        return this.apiCaller.call('/v1/auth/verify?email='+email+'&code='+token, 'GET');
    }

    resetPassword(email: string): Promise<void | undefined> {
        return this.apiCaller.call('/v1/auth/resetPassword/'+email, 'GET');
    }

    changePassword(email: string, password: string, token: string): Promise<void | undefined> {
        return this.apiCaller.call('/v1/auth/resetPassword', 'POST', {email: email, password: password, token: token});
    }

    subscribe(contact: NewsletterContact): Promise<boolean | undefined> {
        return this.apiCaller.call('/v1/newsletter/subscribe', 'POST', {
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            jobTitle: contact.jobTitle,
            company: contact.company
        }).then(() => {
            return true;
        }).catch(err => {
            throw Error(err);
        });
    }
}

export default AuthApi;
