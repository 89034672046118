import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import ContactTableItem from './ContactTableItem';
import TestProject from '../../lib/common/models/testProject';
import ApiCaller from "../../lib/ApiCaller";
import {AppState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import PadApi from "../../api/PadApi";
import {setContacts} from "../../store/padStore";

interface ContainerProps {
  selectedItems: any;
  showCustomerReport:Function;
}
const ContactosTable:React.FC<ContainerProps>  = ({selectedItems, showCustomerReport}) => {

  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [list, setList] = useState<any[]>([]);

  const contacts:any[] = useSelector((state: AppState) => state.pad.contacts);


  useEffect(() => {
    // @ts-ignore
    focusHandling('outline');
  }, [list]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(contacts.map((li:any) => li._id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e:any) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line
  }, [isCheck]);

  const showReport = (contact:any) => {
    showCustomerReport(contact);
  }
  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">reservas <span className="text-gray-400 font-medium">{contacts.length}</span></h2>
      </header>
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                    </label>
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Place</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left"></div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Phone</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Communication</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Created on</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Nit</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Actions</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {
                contacts && contacts.map((contact:any) => {
                  return (
                    <ContactTableItem
                        key={contact._id}
                        obj={contact}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(contact._id)}
                        showReport={showReport}
                    />
                  )
                })
              }
            </tbody>
          </table>

        </div>
      </div>

    </div>
  );
}

export default ContactosTable;
