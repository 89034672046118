import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import ApiCaller from "../lib/ApiCaller";
import AuthApi from "../api/AuthApi";
import toast from "../shared/utils/toast";

import AuthImage from '../images/auth-image.jpg';
import AuthDecoration from '../images/auth-decoration.png';
import Logo from "../components/layout/partials/Logo";

function Signup() {

  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const history = useHistory();

  const processForm = (e: React.FormEvent) => {
    console.log('into process form');
    var authApi = new AuthApi(new ApiCaller());
    if(!name || !email || !password){
      toast.error('Por favor, seleccione los campos');
      return;
    }
    authApi.register(email, password, name).then((data: any) => {
      history.push('/registered');
      return;
    }).catch(err => {
      console.error(err);
      toast.error('Error creating user, please try again');
    });
    e.preventDefault();
  }

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">

            <div className="w-full">
              <div className="flex mb-12">
                <Logo/>
              </div>
              {/* Form */}
              <form onSubmit={processForm}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-red-500">*</span></label>
                    <input id="email" className="form-input w-full" type="email"  value={email} onChange={e => {setEmail(e.target.value)}}/>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="name">Full Name <span className="text-red-500">*</span></label>
                    <input id="name" className="form-input w-full" type="name" value={name} onChange={e => {setName(e.target.value)}}/>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="role">Your Role <span className="text-red-500">*</span></label>
                    <select id="role" className="form-select w-full">
                      <option>Designer</option>
                      <option>Developer</option>
                      <option>Accountant</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
                    <input id="password" className="form-input w-full" type="password" autoComplete="on"  value={password} onChange={e => {setPassword(e.target.value)}}/>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <label className="flex items-center">
                      <input type="checkbox" className="form-checkbox" />
                      <span className="text-sm ml-2">Email me about product news.</span>
                    </label>
                  </div>
                  <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white ml-3 whitespace-nowrap" type='submit' >Sign Up</button>
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Have an account? <Link className="font-medium text-nexoGreen-500 hover:text-nexoGreen-600" to="/signin">Sign In</Link>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
        </div>

      </div>

    </main>
  );
}

export default Signup;
