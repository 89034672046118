import axios from 'axios';
import Settings from '../../lib/settings';

export interface IImageView {
  setUploadState: Function;
  alertSuccess: Function;
  alertError: Function;
}

class UploadImagePresenter {
  loggedUser: any;
  view: IImageView;

  constructor(profile: any, view: IImageView) {
    this.loggedUser = profile;
    this.view = view;
  }

  upload(uploadOptions: any, questionName: string) {
    const { onSuccess, onError, file } = uploadOptions;
    const data = new FormData();
    data.append('file', file);
    data.append('type', 'INSTRUCCIONES_IMAGE');
    data.append('uid', file.uid);
    data.append('questionName', questionName);

    axios
      .post(
        `${Settings.getApiURL()}/v1/instrucciones/add-resource?token=${this.loggedUser.token}`,
        data,
        {
          onUploadProgress: (ProgressEvent: any) => {
            this.view.setUploadState({
              selectedFile: [file.name],
              loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
            });
          },
        },
      )
      .then((res: any) => {
        // then print response status
        this.view.alertSuccess('upload success');
        onSuccess(res.data);
      })
      .catch((err: any) => {
        // then print response status
        this.view.alertError('upload fail');
        onError(err);
      });
  }
}

export default UploadImagePresenter;
