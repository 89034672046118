import Storage from './storage';
import Notification from './common/models/notification';

var currentNotification: Notification;
var storedNotifications: Notification[];

export interface SessionNofiticationData {
    notifications: Notification[]
}
var getCurrentSessionData = async ():  Promise<SessionNofiticationData> => {
    var data = await Storage.read('notifications');
    storedNotifications = Notification.prototype.loadMany(data);
    return {notifications: storedNotifications};
}

var getCurrentNotifications = async  (): Promise<Notification[]> => {
    var data = await Storage.read('notifications');
    storedNotifications = Notification.prototype.loadMany(data);
    return storedNotifications;
}

var Notifications = {

    storedNotifications: () : Notification[] => storedNotifications,
    currentNotification: () : Notification => currentNotification,

    getCurrentNotifications: getCurrentNotifications,
    getCurrentSessionData: getCurrentSessionData,

    addNotification: async (cn: Notification) => {
        storedNotifications = await getCurrentNotifications();
        storedNotifications.push(cn);
        return Storage.write('notifications', storedNotifications);
    },
    resetNotifications: () => {
        storedNotifications = [];
        return Storage.write('notifications', '');
    },
    removeNotifications: async (idx:number) => {
        storedNotifications = await getCurrentNotifications();
        return Storage.write('notifications', storedNotifications.filter((n,i)=>i!=idx));
    },

}

export default Notifications;
