import UserRole from "./userRole";

export enum UserTypesLabels {
    ADMIN = "admin",
    SUPERUSER = "superUser",
    QUESTIONWRITER = "questionWriter",
    AUDITOR = "auditor",
    NEXTESTUSER = "nextest",
    PISOSADIAS = "pisosadias",
    PADAWAN = "padawan"


}

export const UserTypes:any = {
    [UserTypesLabels.PISOSADIAS]: "Pisos a Dias",
    [UserTypesLabels.PADAWAN]: "Padawan",
    [UserTypesLabels.NEXTESTUSER]: "Nextest User",
    [UserTypesLabels.ADMIN]: "Administrator",
    [UserTypesLabels.SUPERUSER]: "Super User",
    [UserTypesLabels.QUESTIONWRITER]: "Question Writer",
    [UserTypesLabels.AUDITOR]: "IAMI Auditor"
}

export enum UserStatusLabels {
    PENDING = "pending",
    CONFIRMED = "confirmed",
    INACTIVE = "inactive"
}

export const UserStatus = {
    [UserStatusLabels.PENDING]: "Pending",
    [UserStatusLabels.CONFIRMED]: "Confirmed",
    [UserStatusLabels.INACTIVE]: "Inactive"
}

interface UserInfo {
    name: string,
    email: string,
    token: string,
    type: string,
    status?: string,
    stripeId?: string,
    entityId?: any,
    _id?: string,
}

export interface IProfile {
    _id: string,
    type: string,
    entityId?: any,
    roles?: UserRole
}

class User {
    _id: string|undefined;
    id: string|undefined;
    name: string;
    email: string;
    token: string;
    type: string;
    status: string;
    stripeId: string;
    entityId: any;

    constructor(userInfo: UserInfo) {
        this.name = userInfo.name;
        this.email = userInfo.email;
        this.token = userInfo.token;
        this.type = userInfo.type;
        this.status = userInfo.status ? userInfo.status : '';
        this.stripeId = userInfo.stripeId ? userInfo.stripeId : '';
        this._id = userInfo._id ? userInfo._id : '';
        this.entityId = userInfo.entityId ? userInfo.entityId : undefined;
    }

    load(data: any): User {
        return new User(data);
    }

    toObject(): any {
        return {
            name: this.name,
            email: this.email,
            type: this.type,
            stripeId: this.stripeId,
            status: this.status,
            entityId: this.entityId,
            _id: this._id,
        };
    }

    isEmpty(): boolean {
        return this.email === "";
    }

    isAdmin(): boolean {
        return this.isType(UserTypesLabels.ADMIN);
    }

    isType(type:string): boolean {
        return this.type.toLowerCase() === type.toLowerCase();
    }

    hasAdminRole(): boolean {
        return this.isType(UserTypesLabels.SUPERUSER) || this.isAdmin()
    }

    needsModulePermission(): boolean {
        return this.isType(UserTypesLabels.QUESTIONWRITER)
    }

}

export default User;
