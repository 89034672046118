import React from 'react';
import {formatDateTimeConversational,minutesAndSecs} from "../../shared/utils/dateTime";

function JenkinsBuildTableItem(props) {

  const statusColor = (status) => {
    switch (status) {
      case 'ANSWERED':
        return 'bg-green-100 text-green-600';
      case 'REQUESTED':
        return 'bg-yellow-100 text-yellow-600';
      case 'FAILURE':
        return 'bg-red-100 text-red-500';
      default:
        return 'bg-gray-100 text-gray-500';
    }
  };


  const getReport = () =>  {
      props.showReport(props.obj.payload);
  }

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
          </label>
        </div>
      </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.obj.status)}`}>{props.obj.status}</div>
        </td >
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{props.obj.type}</div>
        </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-light-blue-500">{`${props.obj.payload.place}-${props.obj.payload.date}`}</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">{props.obj.sourcePhone}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{formatDateTimeConversational(props.obj.updatedAt)}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="space-x-1">
          <button className="text-gray-400 hover:text-gray-500 rounded-full" onClick={(e) => { e.stopPropagation(); getReport(); }}>
              <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                  <path className={`fill-current text-gray-600 hover:text-green-500`} d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z" />
                  <path className={`fill-current text-gray-600 hover:text-green-500`} d="M1 1h22v23H1z" />
                  <path className={`fill-current text-gray-400 hover:text-green-300`} d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z" />
              </svg>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default JenkinsBuildTableItem;
