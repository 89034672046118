import React from 'react';


// import sections
import HeroMessaging from '../../../components/sections/HeroMessaging';
import FeaturesTilesManager from '../../../components/sections/FeaturesTilesManager';
import FeaturesSplitManager from '../../../components/sections/FeaturesSplitManager';
import CtaManager from '../../../components/sections/CtaManager';

function Messaging (){

return (

       <React.Fragment>
            <HeroMessaging />
        </React.Fragment>

  );
}

export default Messaging;