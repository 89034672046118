import React, {useState} from 'react';
import {RouteComponentProps} from "react-router";
import PartePanel from "./PartePanel";
import ApiCaller from "../../lib/ApiCaller";
import AssuranceApi from "../../api/AssuranceApi";
import {getStringFromBase64} from "../../utils/Base64Content";
import SendedKomunication from "../pisosadias/webforms/SendedKomunication";

interface ContainerProps extends RouteComponentProps<{ parteHash:string; }> {};

const FormParte: React.FC<ContainerProps> = ({match}) => {

    const [hash, setHash] = useState<string>('');
    const [parte, setParte] = useState<any>();
    const [expired, setExpired] = useState(false);

    React.useEffect( () =>  {
        const parteHash = match.params.parteHash;
        console.log(parteHash)
        setHash(getStringFromBase64(parteHash));
        var legalAPI = new AssuranceApi(new ApiCaller());
        legalAPI.getParteByPhoneNumber(getStringFromBase64(parteHash)).then((item) => {
            if (item.status) {
                setHash(item.sourceId);
                setExpired(true);
            }
            else setParte(item);
        }).catch(err => {
            console.error(err);
        });
    }, []);

    return (
        <>
            {parte && <PartePanel parte={parte}/>}
            {expired && <SendedKomunication message={'El parte se envió correctamente'} subTitle={'La compañía aseguradora se pondrá en contacto en los próximos días.'} pdfLink={hash} />}
        </>
    )
}

export default FormParte;
