export const IssueType = {
  TASK: 'task',
  BUG: 'bug',
  STORY: 'story',
  REQUEST_DOCUMENT: 'request-document',
  REQUEST_MEETING: 'request-meeting',
  REQUEST_SIGNATURE: 'request-signature',
};

export const IssueStatus = {
  BACKLOG: 'backlog',
  SELECTED: 'selected',
  INPROGRESS: 'inprogress',
  DONE: 'done',
};

export const IssuePriority = {
  HIGHEST: '5',
  HIGH: '4',
  MEDIUM: '3',
  LOW: '2',
  LOWEST: '1',
};

export const IssueTypeCopy = {
  [IssueType.TASK]: 'Generic Task',
  [IssueType.BUG]: 'Corrective Action',
  [IssueType.STORY]: 'Generic Action',
  [IssueType.REQUEST_DOCUMENT]: 'Ask for a document',
  [IssueType.REQUEST_MEETING]: 'Schedule a meeting',
  [IssueType.REQUEST_SIGNATURE]: 'Request a document signature',
};

export const IssueStatusCopy = {
  [IssueStatus.BACKLOG]: 'Prepare Actions',
  [IssueStatus.SELECTED]: 'Selected for execution',
  [IssueStatus.INPROGRESS]: 'In progress',
  [IssueStatus.DONE]: 'Done',
};

export const IssuePriorityCopy = {
  [IssuePriority.HIGHEST]: 'Highest',
  [IssuePriority.HIGH]: 'High',
  [IssuePriority.MEDIUM]: 'Medium',
  [IssuePriority.LOW]: 'Low',
  [IssuePriority.LOWEST]: 'Lowest',
};
