export const getBase64Content = (str?:string) => str ? Buffer.from(str, 'binary').toString('base64') : Buffer.from('nanai', 'binary').toString('base64')
export const str2Slug = (str:string) => {
    str = String(str).toString();
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"

    const newText = str.split('').map(
        (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, '-y-')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');
};

export const getStringFromBase64 = (str?:string) => str ? new Buffer(str, 'base64').toString('ascii') : ''

