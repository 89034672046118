import React, { useEffect } from 'react';
import { Switch, Route, useLocation} from 'react-router-dom';
import configureStore from "./store/store";
import { withRouter } from "react-router";
import {Provider} from "react-redux";
import Toast from './shared/components/Toast';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';



import './charts/ChartjsConfig';

// Import pages

import Dashboard from './pages/Dashboard';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';

// Import pages
import Home from './pages/public/Home';

import TeamTabs from './pages/team/TeamTabs';
import TeamTiles from './pages/team/TeamTiles';
import Profile from './pages/team/Profile';

import Changelog from './pages/utility/Changelog';
import Roadmap from './pages/utility/Roadmap';
import Faqs from './pages/utility/Faqs';
import EmptyState from './pages/utility/EmptyState';
import PageNotFound from './pages/utility/PageNotFound';

import Onboarding01 from './pages/Onboarding01';
import Onboarding02 from './pages/Onboarding02';
import Onboarding03 from './pages/Onboarding03';
import Onboarding04 from './pages/Onboarding04';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import AppRoute from './utils/AppRoute';

import Price from "./pages/public/Price";
import TeamPage from "./pages/public/Team";
import Soporte from "./pages/public/Soporte";
import FAQ from "./pages/public/faq";
import JenkinsBuilds from "./pages/jenkins/JenkinsBuild";
import TestProjects from "./pages/jenkins/TestProjects";

import {TestingProjectDetail, TestingProjectExecutions, TestingProjectTestingPlan,TestingProjectCrowd } from "./pages/jenkins/TestingProjectFactory";
import {SettingsAccount, SettingsApps, SettingsBilling,SettingsFeedback,SettingsNotifications,SettingsPlans } from "./pages/settings/SettingsFactory";
import FormParte from "./pages/parte/FormParte";
import LayoutParte from "./layouts/LayoutParte";
import SendedParte from "./pages/parte/SendedParte";
import SucceedParte from "./pages/parte/SuceedParte";
import PisosADiasContactos from "./pages/pisosadias/PisosADiasContactos";
import Contacts from "./pages/pisosadias/Contacts";
import VCardsContacts from "./pages/pisosadias/VCards-Contacts";
import FormDocumentEntrada from "./pages/pisosadias/webforms/FormDocumentEntrada";
import SendedKomunication from "./pages/pisosadias/webforms/SendedKomunication";
import JenkinsBuild from "./pages/jenkins/JenkinsBuild";
import Komunication from "./pages/pisosadias/Komunication";
import AllComponents from "./pages/public/AllComponents";
import Registered from "./pages/utility/Registered";
import Verify from "./pages/utility/Verify";
import TemplateBuilder from "./pages/sitebuilder/SiteBuilder";

// Layouts Landing
import Manager from "./pages/public/Manager";
import Messaging from "./pages/public/manager/Messaging";
import InteractiveVoiceResponse from "./pages/public/manager/InteractiveVoiceResponse";
import Voice from "./pages/public/manager/Voice";
import BusinessAutomation from "./pages/public/BusinessAutomation";
import IAHospitality from "./pages/public/IAHospitality";
import Contact from  "./pages/public/Contact";
import Clients from  "./pages/public/Clients";
import FormDocumentROAT from "./pages/pisosadias/webforms/FormDocumentROAT";
import ROAT from "./pages/pisosadias/ROAT";
import PdfSample from "./pages/pisosadias/pdf/PdfSample";
import Instrucciones from "./pages/pisosadias/pdf/Instrucciones";



function App() {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Provider store={store}>
        <Switch>
        <AppRoute exact path="/" component={Home} layout={LayoutDefault}/>
          {/*<Route exact path="/"><Signin /></Route>*/}

        <AppRoute exact path="/parte-seguros/:parteHash" component={FormParte} layout={LayoutParte}/>
        <AppRoute exact path="/parte-enviado/:parteHash" component={SucceedParte} layout={LayoutParte}/>

        <AppRoute exact path="/pisosadias/datos-reserva-enviado/:parteHash" component={SendedKomunication} layout={LayoutParte}/>
        <AppRoute exact path="/pisosadias-documentos-entrada/:parteHash" component={FormDocumentEntrada} layout={LayoutParte}/>
        <AppRoute exact path="/pisosadias-roat/:parteHash" component={FormDocumentROAT} layout={LayoutParte}/>


          <Route exact path="/notifications"><Komunication /></Route>

          <AppRoute exact path="/manager" component={Manager} layout={LayoutDefault}/>
          <AppRoute exact path="/manager/Messaging" component={Messaging} layout={LayoutDefault}/>
          <AppRoute exact path="/manager/interactivevoiceresponse" component={InteractiveVoiceResponse} layout={LayoutDefault}/>
          <AppRoute exact path="/manager/voice" component={Voice} layout={LayoutDefault}/>


          <AppRoute exact path="/automation" component={BusinessAutomation} layout={LayoutDefault}/>
          <AppRoute exact path="/iahospitality" component={IAHospitality} layout={LayoutDefault}/>
          <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault}/>
          <AppRoute exact path="/clients" component={Clients} layout={LayoutDefault}/>
          <AppRoute exact path="/amenity" component={FAQ} layout={LayoutDefault}/>

          <AppRoute exact path="/faq" component={FAQ} layout={LayoutDefault}/>

          <AppRoute exact path="/contrata" component={Price} layout={LayoutDefault}/>
          <AppRoute exact path="/equipo" component={TeamPage} layout={LayoutDefault}/>
          <AppRoute exact path="/soporte" component={AllComponents} layout={LayoutDefault}/>


          <Route exact path="/contacts/:projectId"><Contacts /></Route>
          <Route exact path="/roat/:projectId"><ROAT /></Route>
          <Route exact path="/pisosadias-vcards"><VCardsContacts /></Route>
          <Route exact path="/pisosadias-vcards-only"><PisosADiasContactos /></Route>
          {/*<AppRoute exact path="/pisosadias-vcards" component={PisosADiasContactos} layout={LayoutParte}/>*/}

          <Route exact path="/iwaqi"><JenkinsBuild /></Route>
          <Route exact path="/nextest"><JenkinsBuild /></Route>
          <Route exact path="/padawan"><JenkinsBuild /></Route>
          <Route exact path="/pisosadias"><Dashboard /></Route>
          <Route exact path="/template-builder"><TemplateBuilder /></Route>
          <Route exact path="/pdf-sample"><Instrucciones/></Route>
          <Route exact path="/pdf-sample/edit"><PdfSample/></Route>

        <Route exact path="/signin"><Signin /></Route>
        <Route exact path="/dashboard"><Dashboard /></Route>
        <Route exact path="/apiumuser"><TestProjects /></Route>


        <Route exact path="/team/team-tabs"> <TeamTabs /> </Route> <Route exact path="/team/team-tiles"> <TeamTiles /> </Route> <Route exact path="/team/profile"> <Profile /> </Route>
        <Route exact path="/settings/account"> <SettingsAccount /> </Route>
        <Route exact path="/settings/notifications"> <SettingsNotifications /> </Route>
        <Route exact path="/settings/apps"> <SettingsApps /> </Route>
        <Route exact path="/settings/plans"> <SettingsPlans /> </Route>
        <Route exact path="/settings/billing"> <SettingsBilling /> </Route>
        <Route exact path="/settings/feedback"> <SettingsFeedback /> </Route>
        <Route exact path="/utility/changelog"> <Changelog /> </Route> <Route exact path="/utility/roadmap"> <Roadmap /> </Route> <Route exact path="/utility/faqs"> <Faqs /> </Route> <Route exact path="/utility/empty-state"> <EmptyState /> </Route> <Route exact path="/utility/404"> <PageNotFound /> </Route>

        <Route exact path="/signin"> <Signin /> </Route>
        <Route exact path="/signup"> <Signup /> </Route>
        <Route exact path="/reset-password"> <ResetPassword /> </Route>
        <AppRoute exact path="/registered" component={Registered} layout={LayoutDefault}/>
        <AppRoute exact path="/verify/:email/:token" component={Verify} layout={LayoutDefault} />

        <Route exact path="/onboarding-01"> <Onboarding01 /> </Route>
        <Route exact path="/onboarding-02"> <Onboarding02 /> </Route>
        <Route exact path="/onboarding-03"> <Onboarding03 /> </Route>
        <Route exact path="/onboarding-04"> <Onboarding04 /> </Route>

        <Route exact path="/testing-builds/:projectId/details"> <JenkinsBuilds /> </Route>

        <Route exact path="/testing-projects"> <TestProjects /> </Route>
        <Route exact path="/testing-projects/:projectId/details"> <TestingProjectDetail /> </Route>
        <Route exact path="/testing-projects/:projectId/executions"> <TestingProjectExecutions /> </Route>
        <Route exact path="/testing-projects/:projectId/testplan"> <TestingProjectTestingPlan /> </Route>
        <Route exact path="/testing-projects/:projectId/crowdtesting"> <TestingProjectCrowd /> </Route>


          <AppRoute path="*" component={PageNotFound} layout={LayoutDefault}/>

        </Switch>
        <Toast/>
      </Provider>
    </>
  );
}

const store = configureStore();
export default withRouter(props => <App store={store} {...props} />);
