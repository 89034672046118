import React from 'react';
import DraftDelete from "../../components/tables/DeleteDraft";
import LegalApi from "../../api/TestProjectApi";
import ApiCaller from "../../lib/ApiCaller";
import {setCurrentDrafts,setCurrentDoc} from "../../store/docsStore";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';
import {sendToWriteDoc} from "../../utils/ApiCommands";
import {getBase64Content} from "../../utils/Base64Content";
import TestProject from '../../lib/common/models/testProject';
import {formatDateTimeConversational} from "../../shared/utils/dateTime";

function JenkinsBuildTableItem(props) {

  const totalColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'text-green-500';
      case 'IN_PROGRESS':
        return 'text-yellow-500';
      case 'FAILED':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  const statusColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'bg-green-100 text-green-600';
      case 'IN_PROGRESS':
        return 'bg-yellow-100 text-yellow-600';
      case 'FAILED':
        return 'bg-red-100 text-red-500';
      default:
        return 'bg-gray-100 text-gray-500';
    }
  };

  const typeIcon = (type) => {
    switch (type) {
      case 'VOICE':
        return (
          <svg className="w-4 h-4 fill-current text-gray-400 flex-shrink-0 mr-2" viewBox="0 0 16 16">
            <path d="M4.3 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.1.4 5.7.4 2.9 3.1L.7.9 0 7.3l6.4-.7-2.1-2.1zM15.6 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2 1.8 0 3.6-.7 4.9-2l2.2 2.2.8-6.4z" />
          </svg>
        );
      default:
        return (
          <svg className="w-4 h-4 fill-current text-gray-400 flex-shrink-0 mr-2" viewBox="0 0 32 32">
              <path d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"></path>
              <path d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"></path>
          </svg>
        );
    }
  };

    const currentUser = useSelector((state) => state.session.userInfo);
    const dispatch = useDispatch();
    const history = useHistory();

    const loadData = () => {
        var legalAPI = new LegalApi(new ApiCaller(currentUser.token));
        legalAPI.getDraftRaw().then((item) => {
            dispatch(setCurrentDrafts(item));
        }).catch(err => {
            console.error(err);
        });
    }

    const selectProject = (row) => {
        dispatch(setCurrentDoc(new TestProject(row)));
        history.push(`/testing-projects/${row._id}/details`);
    }
    const selectDashboard = (row) => {
        dispatch(setCurrentDoc(new TestProject(row)));
        history.push(`/testing-builds/${row._id}/details`);
    }

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
          </label>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <a className="font-medium text-light-blue-500"  onClick={()=>{if (props.status=='ACTIVE') selectDashboard(props.obj)}} href="#">{props.customName}</a>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>{props.status}</div>
      </td >
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{formatDateTimeConversational(props.issueddate)}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          {typeIcon(props.type)}
          <div>{props.type}</div>
        </div>
      </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div>{props.platform}</div>
        </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="space-x-1">
            {props.status=='ACTIVE' && <button className="text-gray-400 hover:text-gray-500 rounded-full" onClick={()=>{selectDashboard(props.obj)}} >
            <span className="sr-only">Edit</span>
              <svg className="fill-current h-6 w-6" viewBox="0 0 24 24">
                  <path className={`fill-current text-gray-600 hover:text-green-600`} d="M0 20h24v2H0z" />
                  <path className={`fill-current text-gray-400 hover:text-green-400`} d="M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z" />
              </svg>
          </button>}
          <button className="text-gray-400 hover:text-gray-500 rounded-full" onClick={()=>{selectProject(props.obj)}} >
            <span className="sr-only">Edit</span>
            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z" />
            </svg>
          </button>

            <DraftDelete issue={{id:props.id}} fetch={loadData} modalClose={()=>{}} ></DraftDelete>

        </div>
      </td>
    </tr>
  );
}

export default JenkinsBuildTableItem;
