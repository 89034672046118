import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import RoatTableItem from './ROATTableItem';
import ApiCaller from "../../lib/ApiCaller";
import {setCurrentBuilds} from "../../store/jenkinsStore";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/store";
import {useHistory} from "react-router";
import PadApi from "../../api/PadApi";
import * as hutsArray from "../../utils/huts";
import moment from "moment/moment";
import {getCSV, stringToDate} from "../../utils/csv";
import { CSVLink } from "react-csv";
interface ContainerProps {
  selectedItems: any;
  showCustomerReport:Function;
}
const ROATTable:React.FC<ContainerProps>  = ({selectedItems,showCustomerReport}) => {


  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [list, setList] = useState<any>([]);

  const loggedIn = useSelector((state: AppState) => state.session.loggedIn);
  const user = useSelector((state: AppState) => state.session.userInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const [builds, setBuilds] = useState<any[]>([]);

  useEffect(() => {
    const padApi = new PadApi(new ApiCaller(user.token));
    loggedIn ? padApi.getAllROATparte().then(items => {
      setBuilds(items);
    }) : history.push('/');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // @ts-ignore
    focusHandling('outline');
  }, [list]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li:any) => li._id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e:any) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line
  }, [isCheck]);


  const showReport = (contact:any) => {
    showCustomerReport(contact);
  }

  const getCSV = () => {
    const huts:any = hutsArray.huts;
    //const parseddate = stringToDate(date,"yyyy-MM-dd","-");
    //const parseddateString = moment(parseddate).format('DD/MM/yyyy');
    const csv:any = [];
    builds.map((i:any)=>{
      const hut = huts.find((h: { place: any; })=>h.place==i.place||'')
          ? huts.find((h:any)=>h.place==i.place).hut==''
              ? i.place:huts.find((h:any)=>h.place==i.place).hut.substring(6,11) : `EMPTY`;

      const line = `${i.gName};${i.gSurname};${i.gNationality};${i.gDOB};${i.gPOB};${i.gCountry};;;;;;${i.gDocumentNumber};${i.date};;${i.dateExit};;${hut};;;;${i.gPostalCode}`;
      csv.push(line.split(';'));
    })
    return csv;
  }



  return (
      <>
        <CSVLink
            filename={`pisos_a_dias__roat_todo.csv`}
            className="btn bg-white border-gray-200 hover:border-gray-300 text-nexoGreen-500 mx-1"
            data={getCSV()}>csv</CSVLink>

        <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
          <header className="px-5 py-4">
            <h2 className="font-semibold text-gray-800">
              Roat entries <span className="text-gray-400 font-medium">
              <span className="text-yellow-600">{builds.length}</span>
            </span>

            </h2>
          </header>
          <div>
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="flex items-center">
                        <label className="inline-flex">
                          <span className="sr-only">Select all</span>
                          <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                        </label>
                      </div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Nombre</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Apellidos</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Nacionalidad</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Pais</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Ciudad</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">C. Postal</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">ID</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Entrada</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Salida</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm divide-y divide-gray-200">
                  {
                    builds.map((build:any) => {
                      return (
                        <RoatTableItem
                            key={build._id}
                            id={build._id}
                            obj={build}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(build._id)}
                            showReport={showReport}

                        />
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </>
  );
}

export default ROATTable;
