import PropTypes from "prop-types";
import React, {useRef, useLayoutEffect, useState, useEffect, useReducer} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import UploadImagePresenter from '../UploadImagePresenter';
import {useSelector} from "react-redux";
import { toast } from 'react-toastify';
import Settings from '../../lib/settings';

const propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    getEditor: PropTypes.func,
    isDisabled: PropTypes.bool,
    preserveWhitespace: PropTypes.bool,
    autoresize: PropTypes.bool
};

const defaultProps = {
    className: undefined,
    placeholder: undefined,
    defaultValue: undefined,
    value: undefined,
    onChange: () => {},
    getEditor: () => {},
    isDisabled: false,
    preserveWhitespace: true,
    autoresize:true
};

const TinyMCETextEditor = ({
        className,
        placeholder,
        value ,
        onChange,
        getEditor,
        isDisabled,
        preserveWhitespace,
        autoresize
    }) => {

    const editorRef = useRef(null);

    const [currentValue, setCurrentValue] = useState( value);
    const currentUser = useSelector((state) => state.session.userInfo);
    let view = {
        setUploadState: () => {},
        alertSuccess: toast.success,
        alertError: toast.error,
    };
    const presenter = new UploadImagePresenter(currentUser, view);

    useEffect(() => {
        setCurrentValue(value);
    }, []);


    /**
     * Callback function to save the image in the server
     * @param input
     * @param cb
     */

    const saveImageInServer = (input, cb) => {
        let file = input.files[0];
        file.uid = `tinyMCE_${Date.now()}`;
        const uploadOptions = {
            file: file,
            onSuccess: (data) => {
                cb(`${Settings.getApiURL()}/v1/documents/images/${data.fileHash}`, { title: file.name });
            },
            onError: () => {},
        };
        presenter.upload(uploadOptions, 'quill_editor_image');
    }

    /**
     * Custom handler for uploading images instead of the default tinyMCE
     * @param blobInfo
     * @param progress
     * @returns {Promise<unknown>}
     */

/*
    const iamiUploadImageHanlder = (blobInfo, progress) => new Promise((resolve, reject) => {

        let file = blobInfo.blob();
        file.uid = `tinyMCE_${Date.now()}`;
        const uploadOptions = {
            file: file,
            onSuccess: (data) => {
                //cb(`${Settings.getApiURL()}/v1/documents/images/${data.fileHash}`, { title: file.name });
                resolve(`${Settings.getApiURL()}/v1/documents/images/${data.fileHash}`);
            },
            onError: () => {},
        };
        presenter.upload(uploadOptions, 'quill_editor_image');

    });*/

    /**
     * Callback function to save the image in TinyMCE blob registry
     * @param input
     * @param cb
     */
    /*
    const saveImageAsBlob = (input, cb) => {

            const file = input.files[0];

            const reader = new FileReader();
            reader.onload = function () {
                var id = 'blobid' + (new Date()).getTime();
                var blobCache =  editorRef.current.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
    };*/
    /*
    const filepickerHandler = (cb, value, meta) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function(ev) {
            //saveImageAsBlob(ev.target, cb);
            saveImageInServer(ev.target, cb);
        }

        input.click();
    };*/

    const customFilepicker = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function(ev) {
            //saveImageAsBlob(ev.target, cb);
            saveImageInServer(ev.target, (location, props) => {
                //editorRef.current.execCommand("mceInsertContent", false, "<img src='" + location + "' />");
                editorRef.current.insertContent(editorRef.current.dom.createHTML('img', { src: location }));
                onChange(editorRef.current.getContent());
            });
        }
        input.click();
    }

    const handleUpdate = (value, editor) => {
        setCurrentValue(value);
        onChange(value);
    };

    const initEditor = (evt, editor) => {
        editorRef.current = editor;
        getEditor(editor);
    }

    /**
     * Gets the plugins to apply to the editor
     * @returns {string[]}
     */
     const getPlugins = () => {
        let base_plugins=  [
            'advlist', 'autolink', 'lists', 'image',
            'anchor', 'searchreplace', 'visualblocks',
            'media', 'table', 'wordcount'
        ];
        if (autoresize){
            base_plugins.push('autoresize');
        }
        return base_plugins;
    }

    /**
     * ISSUES:
     * <h2> elements has always <p> elements inside for the content. TinyMCE ignores the <h2> and only outputs the <p>
     *     Change Tiny Options to allow them and avoid HTML cleanup
     *     TODO: Change API to create h2 elements directly without <p> children
     */
    const getValidChildren = () => {
        return "+h1[p|#text]," +
                "+h2[p|#text]," +
                "+h3[p|#text]," +
                "+h4[p|#text]," +
                "+h6[p|#text]," +
                "+h6[p|#text]";
    }


    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

    return (
        <div >
        <Editor
            value={currentValue}
            tinymceScriptSrc="/assets/tinymce/tinymce.min.js"
            onInit={initEditor}
            onEditorChange = {handleUpdate}
            disabled = {isDisabled}
            init={{
                height: 600,
                menubar: false,
                content_style: ".ql-align-right { text-align: right} " +
                    ".ql-align-center { text-align: center} " +
                    ".ql-align-left { text-align: left} " +
                    " body { margin: 50px; } ",
                plugins: getPlugins(),
                valid_children : getValidChildren(),
                verify_html: false,
                toolbar: ' undo redo | bold italic underline forecolor backcolor |' +
                    ' numlist bullist | alignment_group |' +
                    '  table customImage | blocks strikethrough  blockquote' ,
                toolbar_groups: {
                    alignment_group: {
                        icon: 'align-left',
                        tooltip: 'alignment options',
                        items: 'alignleft aligncenter alignright alignjustify'
                    },
                    font_style_group: {
                        icon: 'format',
                        tooltip: 'font styles',
                        items: 'strikethrough blockquote'
                    }
                },
                setup: (editor) => {
                    editor.ui.registry.addButton('customImage', {
                        icon: 'image',
                        onAction: () => customFilepicker()
                    })},
                image_title: false,
                image_dimensions: false,
                image_caption: false,
                image_description: false,
               // image_uploadtab: true,
               // images_upload_handler: iamiUploadImageHanlder,
                //automatic_uploads: true,
                file_picker_types:'image',
            }}
        />
        </div>
    );
};

TinyMCETextEditor.propTypes = propTypes;
TinyMCETextEditor.defaultProps = defaultProps;

export default TinyMCETextEditor;
