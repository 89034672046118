import React, { useState } from 'react';

import {AppState} from "../../store/store";
import {useSelector} from "react-redux";

function TestingExecutions() {
  const [toggle1, setToggle1] = useState(true);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);

  const [sync, setSync] = useState(false);
  const project = useSelector((state: AppState) => state.current.doc);

  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">Project execution</h2>
        <input type="checkbox" className="sr-only" checked={toggle1} onChange={() => setToggle1(!toggle1)} />
        <section>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="flex items-center">
              <label className="flex items-center">
                <input type="checkbox" className="form-checkbox" checked={toggle1} onChange={() => setToggle1(true)} />
                <span className="text ml-2">Send PDF</span>
              </label>
              <div className="text text-gray-400 italic ml-2">{toggle1 ? 'Send a PDF to the customer ♻' : 'Off'}</div>
            </div>
          </div>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="flex items-center">
              <label className="flex items-center">
                <input type="checkbox" className="form-checkbox" checked={toggle2} onChange={() => setToggle2(!toggle2)} />
                <span className="text ml-2">Record audio</span>
              </label>
              <div className="text text-gray-400 italic ml-2">{toggle2 ? 'Audio recordings for conversations ⚙' : 'Off'}</div>
            </div>
          </div>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="flex items-center">
              <label className="flex items-center">
                <input type="checkbox" className="form-checkbox" checked={toggle3} onChange={() => setToggle3(!toggle3)} />
                <span className="text ml-2">Transcribe </span>
              </label>
              <div className="text text-gray-400 italic ml-2">{toggle3 ? 'Transcribe audio to text ❤' : 'Off'}</div>
            </div>
          </div>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="flex items-center">
              <label className="flex items-center">
                <input type="checkbox" className="form-checkbox" checked={toggle4} onChange={() => setToggle4(!toggle4)} />
                <span className="text ml-2">Ask for email </span>
              </label>
              <div className="text text-gray-400 italic ml-2">{toggle4 ? 'Ask for email to send back notifications ⚔' : 'Off'}</div>
            </div>
          </div>

        </section>

      </div>
      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn border-gray-200 hover:border-gray-300 text-gray-600">Cancel</button>
            <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white ml-3">Save Changes</button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default TestingExecutions;
