import User from '../lib/common/models/user';
import defaultAvatar from "../images/placeholder.jpg";

//------------  state definition
export interface SessionState {
    loggedIn: boolean,
    userInfo: User,
    layout: string,
    ready: boolean,
    type: string,
    avatar: string|undefined
}

//------------ actions
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_AVATAR = 'UPDATE_AVATAR'

interface UpdateSessionAction {
  type: typeof UPDATE_SESSION,
  payload: SessionState
}

export function updateSession(newSession: SessionState): SessionActionTypes {
    return {
      type: UPDATE_SESSION,
      payload: newSession
    }
}

interface UpdateAvatarAction {
    type: typeof UPDATE_AVATAR,
    payload: string
}

export function updateAvatar(newAvatar: string): SessionActionTypes {
    return {
        type: UPDATE_AVATAR,
        payload: newAvatar
    }
}

export type SessionActionTypes = UpdateSessionAction | UpdateAvatarAction

//------------ reducer
const initialState: SessionState = {
    loggedIn: false,
    userInfo: new User({ name: "Guest", email: "", token: "GUEST_TOKEN", type: "guest", }),
    layout: 'nexoqa',
    ready: false,
    type: "guest",
    avatar: defaultAvatar
}

export function sessionReducer( state = initialState, action: SessionActionTypes): SessionState {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
