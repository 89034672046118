import React, {useEffect, useState} from 'react';
import ModalBasic from "../../components/ModalBasic";
import Tooltip from "../../components/Tooltip";
import {useDispatch, useSelector} from "react-redux";
import TestProjectApi from "../../api/TestProjectApi";
import ApiCaller from "../../lib/ApiCaller";
import {setCurrentDoc, setCurrentDrafts} from "../../store/docsStore";
import toast from "../../shared/utils/toast";
import {AppState} from "../../store/store";
import {str2Slug} from "../../utils/Base64Content";
import {generateErrors, is} from "../../shared/utils/validation";
import {useHistory} from "react-router-dom";
import TestProject from "../../lib/common/models/testProject";

interface props {
    feedbackModalOpen:boolean;
    setFeedbackModalOpen: Function;
}

const NewProjectForm: React.FC<props> = ({feedbackModalOpen,setFeedbackModalOpen}) => {

    const currentUser:any = useSelector((state: AppState) => state.session.userInfo);
    const [name,setName] = useState('');
    const [slug,setSlug] = useState('');
    const [type,setType] = useState('VOICE');
    const [description,setDescription] = useState('');
    const [errors,setErrors] = useState<any>({});
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=>{
        setErrors({});
    },[]);

    const createProject = async () => {
        setErrors(generateErrors({ name, description }, { name: [is.required()], description: [is.required()] }));
        const legalAPI = new TestProjectApi(new ApiCaller(currentUser.token));
        const userId = currentUser.id ? currentUser.id : 'GUEST_USER'
        name.length>3 && description.length>3  && legalAPI.createTestProject(slug, userId,name,description, ['TWILIO'], type).then((project:any) => {
            setFeedbackModalOpen(false);
            legalAPI.getDraftRaw().then((item) => dispatch(setCurrentDrafts(item)));
            toast.success('new Project has been created');
            dispatch(setCurrentDoc(new TestProject(project)));
            history.push(`/testing-projects/${project.id}/details`);
        }).catch(err => {
            console.error(err);
        });
    }

    const changeName = (value:string) => {
        setErrors({});
        setName(value);
        setSlug(`${str2Slug(value)}_iwaqi`);
    }

    return (
            <div className="m-1.5">
        {/* Start */}
        <ModalBasic id="feedback-modal" modalOpen={feedbackModalOpen} setModalOpen={setFeedbackModalOpen} title="Create new communication project">
            {/* Modal content */}
            <div className="px-5 py-4">
                <div className="space-y-3">
                    <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="name">Project Name <span className="text-red-500">*</span></label>
                        <input id="name" className={`form-input w-full px-2 py-1 ${errors.name && 'border-red-300'}`} type="text" required value={name} onChange={(e)=> changeName(e.target.value)}/>
                        {errors.name && <div className="text-xs mt-1 text-red-500">This field is required!</div>}
                    </div>
                    <div>
                        <div className="flex items-center space-x-2">
                            <label className="block text-sm font-medium mb-1" htmlFor="code">Project Code</label>
                            <Tooltip size="" bg="dark" position="right">
                                <div className="text-xs text-gray-200 whitespace-nowrap">Autogenerated and unique for call job</div>
                            </Tooltip>
                        </div>
                        <input id="code" className="form-input w-full px-2 py-1 " type="text" value={slug} disabled/>
                    </div>
                    <div>
                        <div className="flex items-center space-x-2">
                            <label className="block text-sm font-medium mb-1" htmlFor="type">Project Type</label>
                            <Tooltip size="" bg="dark" position="right">
                                <div className="text-xs text-gray-200 whitespace-nowrap">The tests are automated or executed by humans ? </div>
                            </Tooltip>
                        </div>
                        <select id="type" className={`form-input w-full px-2 py-1 `} onChange={(e)=> setType(e.target.value)}>
                            <option value="VOICE">VOICE</option>
                            <option value="SMS">SMS</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="feedback">Description<span className="text-red-500">*</span></label>
                        <textarea id="feedback" className={`form-textarea w-full px-2 py-1 ${errors.description && 'border-red-300'}`} rows={4} required  value={description} onChange={(e)=> setDescription(e.target.value)}></textarea>
                        {errors.description && <div className="text-xs mt-1 text-red-500">This field is required!</div>}
                    </div>
                </div>
            </div>
            {/* Modal footer */}
            <div className="px-5 py-4 border-t border-gray-200">
                <div className="flex flex-wrap justify-end space-x-2">
                    {name.length>3 && <button className="btn-sm bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" onClick={createProject}>Send</button>}
                </div>
            </div>
        </ModalBasic>
        {/* End */}
    </div>
    )
}

export default NewProjectForm;
