import React, {useState} from "react";
import Sidebar from "../../../partials/Sidebar";
import Header from "../../../partials/Header";

export const BackofficeBase = ({ children, actions, titleHeader, navigation }: { children: any,actions: any, titleHeader: string, navigation?:any}) => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-4 py-4 w-full mx-auto">
                        {/* Page header */}
                        <div className="sm:flex sm:justify-between sm:items-center mb-5">
                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{titleHeader} ✨ {navigation}</h1>
                            </div>
                            {/* Right: Actions */}
                            {actions}
                        </div>
                        {/* Table */}
                        {children}
                    </div>
                </main>
            </div>
        </div>
    );
}

export default BackofficeBase;
