import React from 'react';
import ApiCaller from "../../lib/ApiCaller";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from 'react-router-dom';
import {formatDateTimeConversational} from "../../shared/utils/dateTime";
import ContactDelete from "../../components/tables/DeleteContact";
import PadApi from "../../api/PadApi";
import {setContacts,setCurrentDoc} from "../../store/padStore";
import EditMenu from "../../components/DropdownEditMenu";
import toast from "../../shared/utils/toast";

function ContactTableItem(props) {

  const statusColor = (status) => {
    switch (status) {
      case 'FAILED':
        return 'bg-red-100 text-red-500';
      case 'PENDING':
        return 'bg-yellow-100 text-yellow-600';
      case 'CANCELED':
        return 'bg-red-100 text-red-600';
      default:
        return 'bg-green-100 text-green-600';
    }
  };
    const currentUser = useSelector((state) => state.session.userInfo);
    const dispatch = useDispatch();
    const history = useHistory();

    const loadData = () => {
        const legalAPI = new PadApi(new ApiCaller());
        legalAPI.getAllContacts().then(contacts => {
            dispatch(setContacts(contacts));
        })
    }

    const selectDashboard = (row) => {
        dispatch(setCurrentDoc(row));
        getReport();
        //history.push(`/testing-builds/${row._id}/details`);
    }
    const launchSendEmail = (row, formId) => {
        const legalAPI = new PadApi(new ApiCaller(currentUser.token));
        legalAPI.launch(row._id,formId).then(msg => {
            toast.show(JSON.stringify(msg));
        })
    }

    const getReport = () =>  {
        props.showReport(props.obj);
    }
  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input id={props.obj._id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
          </label>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <a className="font-medium text-light-blue-500"  onClick={(e) => { e.stopPropagation();selectDashboard(props.obj.obj)}} href="#">
            {props.obj.place} / {props.obj.date}
        </a>
      </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 text-2xl">
            <div>
                {props.obj.idioma==1&&'🇦🇩'}
                {props.obj.idioma==2&&'🇬🇧'}
                {props.obj.idioma==3&&'🇪🇸'}
                {props.obj.idioma==4&&'🇫🇷'}
            </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div>{props.obj.name}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div>{props.obj.phone}</div>
        </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.obj.status)}`}>{props.obj.status}</div>
      </td >
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{formatDateTimeConversational(props.obj.createdAt)}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{props.obj.nights}</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="space-x-1">

          {/*<button className="text-gray-400 hover:text-gray-500 rounded-full" >*/}
          {/*      <span className="sr-only">Edit</span>*/}
          {/*      <svg className="fill-current h-6 w-6" viewBox="0 0 24 24">*/}
          {/*        <path className={`fill-current text-gray-600 hover:text-green-600`} d="M0 20h24v2H0z" />*/}
          {/*        <path className={`fill-current text-gray-400 hover:text-green-400`} d="M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z" />*/}
          {/*      </svg>*/}
          {/*</button>*/}


        <button className="text-gray-400 hover:text-gray-600 rounded-full" onClick={(e)=>{launchSendEmail(props.obj,'ASK_ENTRADA_WHATSAPP')}}>
            <span className="text-xxs">IN?</span>
        </button>

        <button className="text-gray-400 hover:text-gray-600 rounded-full" onClick={(e)=>{launchSendEmail(props.obj,'ASK_SALIDA_WHATSAPP')}}>
            <span className="text-xxs">OUT?</span>
        </button>

          <button className="text-gray-400 hover:text-gray-600 rounded-full" onClick={(e)=>{launchSendEmail(props.obj,'WHATSAPP_PDF')}}>
            <span className="text-xxs">PDF</span>
          </button>

          <button className="text-gray-400 hover:text-gray-600 rounded-full" onClick={(e)=>{launchSendEmail(props.obj,'PAYLINK-SEND')}}>
            <span className="text-xxs">PAYLINK</span>
          </button>

          <button className="text-gray-400 hover:text-gray-600 rounded-full" onClick={(e)=>{launchSendEmail(props.obj,'ONBOARD_WHATSAPP')}}>
            <span className="text-xxs">Welcome</span>
          </button>

          <button className="text-gray-400 hover:text-gray-600 rounded-full" onClick={(e)=>{launchSendEmail(props.obj,'ROAT-WARNING')}}>
            <span className="text-xxs">W-ROAT</span>
          </button>

          <button className="text-gray-400 hover:text-gray-600 rounded-full" onClick={(e)=>{launchSendEmail(props.obj,'PAYLINK-WARNING')}}>
            <span className="text-xxs">W-PAY</span>
          </button>

            <button className="text-gray-400 hover:text-gray-500 rounded-full" onClick={(e) => { e.stopPropagation(); getReport(); }}>
                <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 hover:text-green-500`} d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z" />
                    <path className={`fill-current text-gray-600 hover:text-green-500`} d="M1 1h22v23H1z" />
                    <path className={`fill-current text-gray-400 hover:text-green-300`} d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z" />
                </svg>
            </button>

            <EditMenu align="right" className="relative inline-flex flex-shrink-0">
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <a href={`https://gero.icnea.net/HosOrdReserva.aspx?res=${props.obj.reservaId}`} target="_blank" rel="noopener noreferrer">
                            ICNEA Page
                        </a>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <a href={`/pisosadias-roat/${Buffer.from(props.obj.phone).toString('base64')}`} target="_blank" rel="noopener noreferrer">
                            ROAT Page
                        </a>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'ASK_ENTRADA')}}>Request ENTRADA</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'ASK_SALIDA')}}>Request SALIDA</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'ONBOARD_WHATSAPP')}}>Send Onboard Whatsapp</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'FAREWELL_WHATSAPP')}}>Send Farewell Whatsapp</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'WHATSAPP_PDF')}}>Send PDF Whatsapp</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'ONBOARD_EMAIL')}}>Send Welcome Email</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'FAREWELL_EMAIL')}}>Send Farewell Email</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'REQUEST_INFO_SMS')}}>Send Request Data By SMS</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'ASK_ENTRADA_WHATSAPP')}}>Ask Entrada</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <button onClick={(e)=>{launchSendEmail(props.obj,'ASK_SALIDA_WHATSAPP')}}>Ask Salida</button>
                    </span>
                </li>
                <li>
                    <span className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        <ContactDelete issue={{id:props.obj._id}} fetch={loadData} modalClose={()=>{}} ></ContactDelete>
                    </span>
                </li>
            </EditMenu>
        </div>
      </td>
    </tr>
  );
}

export default ContactTableItem;
