import User from "./user";

export enum AssignableObjects {
    Module = "Module",
    Syllabus = "Syllabus"
}

interface UserRoleInfo {
    userId: User;
    objectIds: Array<any>;
    objectName: string;
    timestamp: number|undefined;
}

class UserRole {
    userId: User;
    objectIds: Array<any>;
    objectName: string;
    timestamp: number|undefined;

    constructor(userRoleInfo: UserRoleInfo) {
        this.userId = userRoleInfo.userId;
        this.objectIds = userRoleInfo.objectIds;
        this.objectName = userRoleInfo.objectName;
        this.timestamp = userRoleInfo.timestamp;
    }

    load(data: any): UserRole {
        return new UserRole(data);
    }

    toObject(): any {
        return {
            userId: this.userId,
            objectIds: this.objectIds,
            objectName: this.objectName,
            timestamp: this.timestamp,
        }
    }
}

export default UserRole;
