import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import Modal from "../elements/Modal";

import imgHero from "../../images/hero-media.png"
import videoPlaceHolder from "../../images/video-placeholder.svg"

const propTypes = {...SectionProps.types}
const defaultProps = {...SectionProps.defaults}

class HeroVideo extends React.Component {

  state = {videoModalActive: false}


  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {
    const {className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, ...props} = this.props;
    const outerClasses = classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);
    const innerClasses = classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              <div className="container-xs">
                <h1 className="title-smart text-6xl md:text-6xl text-smartCountry-50 font-bold mb-1" data-reveal-delay="300">
                  Manager de comunicación
                </h1>
              </div>
            </div>
            <div className="hero-figure illustration-element-01 reveal-from-top" data-reveal-delay="500">
              {/*<Image className="has-shadow" src={imgHero} width={896} height={504} alt="Hero" />*/}
              <div className="container-sm">
                <a data-video="https://player.vimeo.com/video/174002812" href="#0" aria-controls="video-modal" onClick={this.openVideoModal}>
                  <Image className="has-shadow" src={videoPlaceHolder} alt="Video" width={896} height={504} />
                </a>
                <Modal id="video-modal" show={this.state.videoModalActive} handleClose={this.closeVideoModal} video="https://player.vimeo.com/video/174002812" videoTag="iframe" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: 'absolute',
  left: '-5.9%',
  right: '5.9%',
  top: '-12.74%'
}

HeroVideo.propTypes = propTypes;
HeroVideo.defaultProps = defaultProps;

export default HeroVideo;
