import pubsub from 'sweet-pubsub';
import { get } from 'lodash';

const show = toast => pubsub.emit('toast', toast);

const success = title => show({ title });

const error = err => {
  show({
    type: 'danger',
    message: get(err, 'message', err),
    duration: 3,
  });
};

const whatsapp = (title,message, recipient) => show({ title, message, type:'whatsapp', recipient, duration: 6 });

export default { show, error, success, whatsapp };
