import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import ApiCaller from "../../../lib/ApiCaller";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/store";
import {useHistory} from "react-router";
import PDFTableItem from "./PDFTableItem";
import InstruccionesApi from "../../../api/InstruccionesApi";

interface ContainerProps {
  selectedItems: any;
  showCustomerReport:Function;
}
const PDFTable:React.FC<ContainerProps>  = ({selectedItems,showCustomerReport}) => {


  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [list, setList] = useState<any>([]);

  const loggedIn = useSelector((state: AppState) => state.session.loggedIn);
  const user = useSelector((state: AppState) => state.session.userInfo);
  const history = useHistory();
  const [builds, setBuilds] = useState<any[]>([]);

  useEffect(() => {
    const api = new InstruccionesApi(new ApiCaller(user.token));
    loggedIn ? api.getInstrucciones().then((items:any) => {
      setBuilds(items);
    }) : history.push('/');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // @ts-ignore
    focusHandling('outline');
  }, [list]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li:any) => li._id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e:any) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line
  }, [isCheck]);


  const showReport = (contact:any) => {
    showCustomerReport(contact);
  }

  return (
      <>
        <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
          <header className="px-5 py-4">
            <h2 className="font-semibold text-gray-800">
              Instrucciones <span className="text-gray-400 font-medium">
              <span className="text-yellow-600">{builds.length}</span>
            </span>

            </h2>
          </header>
          <div>
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="flex items-center">
                        <label className="inline-flex">
                          <span className="sr-only">Select all</span>
                          <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                        </label>
                      </div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Nombre</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Carpeta</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left text-xl">🇦🇩🇬🇧🇪🇸🇫🇷</div>
                    </th>

                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Actions</div>
                    </th>

                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm divide-y divide-gray-200">
                  {
                    builds.map((build:any) => {
                      return (
                        <PDFTableItem
                            key={build._id}
                            id={build._id}
                            obj={build}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(build._id)}
                            showReport={showReport}

                        />
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </>
  );
}

export default PDFTable;
