export const huts = [
  { "place": "agols", "hut": "HUT1-007581" },
  { "place": "areny.", "hut": "HUT4-007909" },
  { "place": "areny-arinsal", "hut": "HUT4-007909" },
  { "place": "avet-a-2.2", "hut": "HUT1-008125" },
  { "place": "borda-pascol", "hut": "HUT1-007401" },
  { "place": "bosquet-canillo", "hut": "HUT1-007467" },
  { "place": "c3", "hut": "HUT4-007461" },
  { "place": "c5", "hut": "HUT4-007710" },
  { "place": "cabirol-3.1", "hut": "HUT1-008218" },
  { "place": "cabirol-2.2", "hut": "HUT1-007654" },
  { "place": "cabirol-3.2", "hut": "HUT1-007468" },
  { "place": "cabirol-4.5", "hut": "HUT1-007400" },
  { "place": "callaueta", "hut": "HUT5-005052" },
  { "place": "carlemany-5.1", "hut": "HUT7-007634" },
  { "place": "d2", "hut": "HUT4-007462" },
  { "place": "deu-sol-f-21", "hut": "HUT1-007190" },
  { "place": "ed-carles-7c", "hut": "HUT4-034891" },
  { "place": "ensagents", "hut": "HUT1-007671" },
  { "place": "genciana-2,3", "hut": "HUT1-007505" },
  { "place": "llorts", "hut": "HUT3-007389" },
  { "place": "malva-3.2", "hut": "HUT1-007289" },
  { "place": "narcis", "hut": "HUT1-007402" },
  { "place": "novell", "hut": "HUT1-007274" },
  { "place": "orquidea-7,3", "hut": "HUT1-007485" },
  { "place": "peretol-2c", "hut": "HUT1-112204" },
  { "place": "peretol-atico", "hut": "HUT1-111717" },
  { "place": "pleta-de-l'aldosa", "hut": "HUT4-034795" },
  { "place": "pleta-escaldes-6.1", "hut": "HUT7-006731" },
  { "place": "pleta-ordino-18a", "hut": "HUT7-006731" },
  { "place": "pleta-ordino-45", "hut": "HUT3-007772" },
  { "place": "pleta-ordino-51", "hut": "HUT3-007460" },
  { "place": "pleta-soldeu-225", "hut": "HUT1-006247" },
  { "place": "pleta-soldeu-226", "hut": "HUT1-006246" },
  { "place": "pleta-soldeu-244", "hut": "HUT1-006252" },
  { "place": "poblat-d'andorra", "hut": "HUT2-007637" },
  { "place": "prat-del-tirader", "hut": "HUT2-007639" },
  { "place": "principat-park", "hut": "HUT1-007560" },
  { "place": "ransol-1.1", "hut": "HUT1-006423" },
  { "place": "ransol-1.3", "hut": "HUT1-006422" },
  { "place": "ransol-1.4", "hut": "HUT1-006421" },
  { "place": "ransol-1.6", "hut": "HUT1-006420" },
  { "place": "ransol-2.1", "hut": "HUT1-006686" },
  { "place": "ransol-2.2", "hut": "HUT1-006425" },
  { "place": "ransol-2.3", "hut": "HUT1-006424" },
  { "place": "ransol-2.6", "hut": "HUT1-006688" },
  { "place": "ransol-3.1", "hut": "HUT1-006470" },
  { "place": "ransol-3-3", "hut": "HUT1-006685" },
  { "place": "ransol-3.4", "hut": "HUT1-006687" },
  { "place": "ransol-3.5", "hut": "HUT1-006427" },
  { "place": "ransol-4.1", "hut": "HUT1-007207" },
  { "place": "ransol-4.2", "hut": "HUT1-006428" },
  { "place": "ransol-4.3", "hut": "HUT1-006689" },
  { "place": "ransol-4.4", "hut": "HUT1-006690" },
  { "place": "ransol-5.1", "hut": "HUT1-006447" },
  { "place": "ransol-5.2", "hut": "HUT1-006672" },
  { "place": "ransol-5.3", "hut": "HUT1-007409" },
  { "place": "ransol-6.1", "hut": "HUT1-006479" },
  { "place": "ransol-6.2", "hut": "HUT1-006668" },
  { "place": "ransol-6.3", "hut": "HUT1-006480" },
  { "place": "salze", "hut": "HUT1-112207" },
  { "place": "sol-ski-v-atic", "hut": "HUT1-007874" },
  { "place": "ribasol-422", "hut": "HUT4-007797" },
  { "place": "ribasol-645", "hut": "HUT4-007764" },
  { "place": "tucamp-2,6", "hut": "HUT2-007636" },
  { "place": "anyó", "hut": "HUT2-008148" },
  { "place": "avet", "hut": "HUT1-006396" },
  { "place": "avet-a-3.4", "hut": "HUT1-008113" },
  { "place": "esquiadors", "hut": "HUT3-008035" },
  { "place": "h4", "hut": "HUT4-007984" },
  { "place": "isards", "hut": "HUT1-007423" },
  { "place": "julia", "hut": "HUT1-007689" },
  { "place": "khi", "hut": "HUT2-008047" },
  { "place": "pleta-peu-del-riu", "hut": "HUT1-008178" },
  { "place": "pleta-ordino-55a", "hut": "HUT3-008149" },
  { "place": "pleta soldeu-11-4.3", "hut": "HUT1-008059" },
  { "place": "pleta soldeu-345", "hut": "HUT1-006252" },
  { "place": "pleta-tarter-47g", "hut": "HUT1-008107" },
  { "place": "ransol-3.2", "hut": "HUT1-006426" },
  { "place": "solana-333", "hut": "HUT1-007763" },
  { "place": "tucamp-2,2", "hut": "HUT2-008048" },
  { "place": "tucamp-2,3", "hut": "HUT2-008099" },
  { "place": "tucamp-2,8", "hut": "HUT2-008049" },
  { "place": "tucamp-2,6", "hut": "HUT2-008103" },
  { "place": "tucamp-2,7", "hut": "HUT2-008109" },
  { "place": "tucamp-2,8", "hut": "HUT2-008050" },
  { "place": "tucamp-2,2", "hut": "HUT2-008100" },
  { "place": "xalet-cortals", "hut": "HUT2-008010" },
  { "place": "pleta-del-peu-del-riu", "hut": "HUT1-008178" },
  { "place": "font-de-ferro", "hut": "HUT1-007956" },
]
