import React, {useEffect, useRef, useState} from 'react';

import PadApi from "../../api/PadApi";
import ApiCaller from "../../lib/ApiCaller";
import ROATPanel from "./webforms/ROATPanel";

function TransactionPanel({
  transactionPanelOpen,
  setTransactionPanelOpen,
  phone
                          }) {

    const panelContent = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!transactionPanelOpen || panelContent.current.contains(target)) return;
            setTransactionPanelOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!transactionPanelOpen || keyCode !== 27) return;
            setTransactionPanelOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const [parte, setParte] = useState();

    React.useEffect( () =>  {
        var padAPI = new PadApi(new ApiCaller());
        padAPI.getLogStateFromPhone(phone).then((roats) => {
            padAPI.getCustomerEntradaByPhoneNumber(phone).then((item) => {
                setParte({...item.contact, roats:roats.map((r)=>r.payload)});
            });
        }).catch(err => {
            console.error(err);
        });
    }, [phone]);

    return (
        <div
            ref={panelContent}
            className={`absolute inset-0 sm:left-auto z-20 shadow-xl transition-transform duration-200 ease-in-out ${
                transactionPanelOpen ? 'translate-x-' : 'translate-x-full'
            }`}
        >
            <div className="sticky top-16 bg-nexoGreen-50 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-nexoGreen-200 w-full sm:w-[390px] h-[calc(100vh-64px)]">

                <div className="py-0 px-0 lg:px-0">
                        {parte && <ROATPanel parte={parte}/>}
                        {!parte && <p>espera marica...</p>}
                </div>
            </div>
        </div>
    );
}

export default TransactionPanel;
