import ApiCaller from '../lib/ApiCaller';

class InstruccionesApi {

    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    removeContact(id:string) : Promise<void | any | undefined> {
        return this.apiCaller.call(`/v1/instrucciones/${id}`, 'DELETE')
    }

    getInstrucciones():Promise<any> {
        const url = '/v1/instrucciones';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    create(name:string):Promise<any> {
        const url = '/v1/instrucciones';
        return this.apiCaller.call(url, 'POST', {name}).then((doc: any) => {
            return doc
        });
    }
    update(obj:any):Promise<any> {
        const url = '/v1/instrucciones';
        return this.apiCaller.call(url, 'PUT', obj).then((doc: any) => {
            return doc
        });
    }
}

export default InstruccionesApi;
