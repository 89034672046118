import React from 'react';

// import sections
import HeroAutomation from '../../components/sections/HeroAutomation';
import Clients from '../../components/sections/Clients';
import FeaturesTilesAutomation from '../../components/sections/FeaturesTilesAutomation';
import FeaturesSplitAutomation from '../../components/sections/FeaturesSplitAutomation';


// import elements


function BusinessAutomation() {


  return (
      <React.Fragment>
            <HeroAutomation className="illustration-section-01"/>
            <Clients topDivider bottomDivider />
            <FeaturesTilesAutomation />
            <FeaturesSplitAutomation />
        
      </React.Fragment>
   );
  }

export default BusinessAutomation;
