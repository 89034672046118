import React, {useState} from "react";
import ImageGallery from "react-image-gallery";
import ApiCaller from "../../lib/ApiCaller";
import DocumentsApi from "../../api/DocumentsApi";

import 'react-image-gallery/styles/css/image-gallery.css';

import './image.css';
import ModalBasic from "../ModalBasic";

interface gallery {
    dispachClick:Function,
    feedbackModalOpen:boolean;
    setFeedbackModalOpen: Function;
    instructionId: string;
}

const ImageGalleryComp:React.FC<gallery> = ({dispachClick,feedbackModalOpen,setFeedbackModalOpen,instructionId}) =>  {

    const [images, setImages] = useState<any[]>([]);

    React.useEffect(() => {
        new DocumentsApi(new ApiCaller()).getAllImagesByinstruction(instructionId).then((img:any) => {
            setImages(img)
        });
    }, [feedbackModalOpen]);


    return (
        <div className="m-1.5">
            {/* Start */}
            <ModalBasic id="feedback-modal-gallery" modalOpen={feedbackModalOpen} setModalOpen={setFeedbackModalOpen} title="Crea una instruccion">
                {/* Modal content */}

                        <ImageGallery
                            items={images}
                            showBullets={true}
                            showFullscreenButton={true}
                            showPlayButton={false}
                            showThumbnails={true}
                            lazyLoad={true}
                            onClick={(e:any)=>dispachClick(e)}
                        />

                {/* Modal footer */}
                <div className="px-5 py-4 border-t border-gray-200">
                    <div className="flex flex-wrap justify-end space-x-2">
                        <p>Elige una imagen para añadir al texto</p>
                    </div>
                </div>

            </ModalBasic>
        </div>

    )
}
export default ImageGalleryComp;
