import React, {useEffect, useState} from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import DashboardCard04a from "../partials/dashboard/DashboardCard04a";
import ApiCaller from "../lib/ApiCaller";
import PadApi from "../api/PadApi";
import {useDispatch, useSelector} from "react-redux";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import DashboardCard07roats from "../partials/dashboard/DashboardCard07roats";
import Info from "../partials/Info";
import ROATstatusDetail from "../partials/dashboard/ROATstatusDetail";


function Dashboard() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [builds, setBuilds] = useState();
  const [roats, setRoats] = useState();
  const [koms, setKoms] = useState();
  const [pay, setPay] = useState();
  const [ack, setAck] = useState();
  const loggedIn = useSelector((state) => state.session.loggedIn);
  const user = useSelector((state) => state.session.userInfo);


  useEffect(() => {
    let api = new PadApi(new ApiCaller());
    loggedIn ? api.getPisosStruct().then(byWeek => {
      setBuilds(byWeek);
    }) : history.push('/');
    api.getAllCreatedParte().then(items => setKoms(items.items));
    api.getPayStatus().then(items => setPay(items));
    api.getAck().then(items => setAck(items));
    api = new PadApi(new ApiCaller(user.token));
    api.getAllROATparte().then(items => setRoats(items));

  }, []);

  const [inCustomer, sIn] = useState(0);
  const [outCustomer, sOut] = useState(0);
  const [intersection, sIntersection] = useState([]);
  useEffect(() => {
    let api = new PadApi(new ApiCaller());
    api.collide().then(items => {
      sIn(items.in.length);
      sOut(items.out.length);
      sIntersection(items.in.map(element => items.out.filter( o => o.place===element.place)).flat(1))
    });
  }, []);

  const [rowStatus, setRowStatus] = useState();

  const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
          playing ? audio.play() : audio.pause();
        },
        [playing]
    );

    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);

    return [playing, toggle];
  };

  const [playing, toggle] = useAudio('https://smartcountry.io/julito.mp3');

  const setDetailedRow = (rowstatus) => {
    setRowStatus(rowstatus);
  }

  const statusColor = (status) => {
    if (status>=3) return 'bg-red-100 text-red-500';
      return 'bg-green-100 text-green-600';
  };
  const statusText = (status) => {
    if (status>=3) return 'ALTA';
      return 'BAJA';
  };
  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-0 sm:px-0 lg:px-0 py-0 w-full max-w-9xl mx-auto">

            <div className="grid grid-cols-12 gap-6 ">

              <div className="col-span-full xl:col-span-12 bg-white rounded-sm border border-gray-200 pb-0 mb-0">
                <header className="px-0 py-0 border-b border-gray-100 flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    {inCustomer && <h2 className="font-semibold text-gray-800"><span className="font-bold">Hoy:</span>  {inCustomer} entradas y mañana {outCustomer} salidas.</h2>}

                    <span className={`font-bold font-Poppins text-xs ${statusColor(inCustomer)}`}>CARGA DE TRABAJO {statusText(inCustomer)}</span>
                    {intersection.length>0 && intersection.map(i=><span className={`font-bold font-Poppins text-xs bg-red-100 text-red-500`}>{i.place}</span>)}
                    {intersection.length==0 && intersection.map(i=><span className={`font-Poppins text-xs bg-green-100 text-green-600`}>No hay entrada/salida en el mismo apartamento</span>)}


                    {/*<img className="w-12 h-12 rounded-full" src={'/api/v1/image/user/c1.png'} alt="User" />*/}
                    {/*<img className="w-12 h-12 rounded-full" src={'/api/v1/image/user/c2.png'} alt="User" />*/}
                    {/*<img className="w-12 h-12 rounded-full" src={'/api/v1/image/user/c3.png'} alt="User" />*/}
                    {/*<img className="w-12 h-12 rounded-full" src={'/api/v1/image/user/c4.png'} alt="User" />*/}
                    {/*<img className="w-12 h-12 rounded-full" src={'/api/v1/image/user/c5.png'} alt="User" />*/}
                    {/*<img className="w-12 h-12 rounded-full" src={'/api/v1/image/user/c6.png'} alt="User" />*/}
                    {/*<img className="w-12 h-12 rounded-full" src={'/api/v1/image/user/c7.png'} alt="User" />*/}
                    {/*<img className="w-12 h-12 rounded-full" src={'/api/v1/image/user/c8.png'} alt="User" />*/}

                  </div>
                </header>
              </div>

              { builds && roats && pay && ack && <DashboardCard07roats
                  builds={builds}
                  roats={roats}
                  pay={pay}
                  ack={ack}
                  setDetailedRow={setDetailedRow}/> }
              { rowStatus && <>
                  <div className="col-span-full xl:col-span-6 bg-white rounded-sm border border-gray-200 pb-6 mb-6">
                    <header className="px-5 py-4 border-b border-gray-100 flex justify-between items-center">
                      <div className="flex items-center">
                        <h2 className="font-semibold text-gray-800">Estado del ROAT : [{rowStatus[0].date}] </h2>
                        <button onClick={toggle}>
                        <Info className="ml-2" containerClassName="min-w-80" >
                          <img src={'https://media.revistagq.com/photos/5ca6025cb65710f52ccab5e2/master/w_1600,c_limit/memes_y_cunados_6890.jpg'} />
                          <div className="text-xl">Estas flipando y lo sabes</div>
                        </Info>
                        </button>
                      </div>
                      <button className="text-gray-400 hover:text-gray-500" onClick={(e) => { e.stopPropagation(); setRowStatus(null); }}>
                        <div className="sr-only">Close</div>
                        <svg className="w-4 h-4 fill-current">
                          <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                        </svg>
                      </button>
                    </header>
                    <div className="p-3">
                      <div>
                        <ROATstatusDetail rowstatus={rowStatus} />
                      </div>
                    </div>
                  </div>
                </>
              }

                  <div className={`flex flex-col col-span-full bg-transparent sm:col-span-${rowStatus?'12':'6'} xl:col-span-${rowStatus?'12':'6'}`}>
                    <DashboardCard11/>
                    {koms && <DashboardCard10  koms={koms}/> }
                  </div>

                    {/* builds && <DashboardCard04 builds={builds}/> */}
                    {/* builds && <DashboardCard04a builds={builds}/> */}
                    {/*roats && <DashboardCard06 roats={roats}/> */}


              {/*<DashboardCard08/>*/}
              {/*<DashboardCard09/>*/}



            </div>
          </div>
        </main>

      </div>

    </div>
  );
}

export default Dashboard;
