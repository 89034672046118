import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import {useSelector} from "react-redux";
import {AppState} from "../../../store/store";
interface props {
  className?:string,
  props?:any
}

import imgw from "./../../../images/logow.png";
import img from "./../../../images/logo.png";

const Logo:React.FC<props> = ({className, ...props}) => {

  const classes = classNames(
    'brand',
    className
  );
  const loggedIn = useSelector((state: AppState) => state.session.loggedIn);
  const userInfo = useSelector((state: AppState) => state.session.userInfo);

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="">
        <Link to={loggedIn?`/${userInfo.type}`:'/'}>
          <Image
            src={loggedIn?imgw:img}
            alt="smartcountry.io"
            width={395}
            height={50} />
        </Link>
      </h1>
    </div>
  );
}

export default Logo;
