import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {useSelector} from "react-redux";
import {AppState} from "../../store/store";

function TestingSidebar() {

  const location = useLocation();
  const { pathname } = location;
  const projectCurrent = useSelector((state) => state.current.doc);

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 min-w-60 md:space-y-3">
      {/* Group 1 */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3">Project settings</div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink exact to="/testing-projects/61a632c9876ebe00517c3e8d/details" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/account') && 'bg-nexoGreen-50'}`}>
              <svg className={`w-4 h-4 flex-shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('details') && 'text-nexoGreen-400'}`} viewBox="0 0 16 16">
                <path d="M15 4c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h7c.6 0 1 .4 1 1v3h4zM2 3v1h7V2H3c-.6 0-1 .4-1 1zm12 11V6H2v7c0 .6.4 1 1 1h11zm-3-5h2v2h-2V9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('details') ? 'text-nexoGreen-500' : 'hover:text-gray-700'}`}>Details</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink exact to="/testing-projects/61a632c9876ebe00517c3e8d/executions" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('executions') && 'bg-nexoGreen-50'}`}>
              <svg className={`w-4 h-4 flex-shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('executions') && 'text-nexoGreen-400'}`} viewBox="0 0 16 16">
                <path d="M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8zM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('executions') ? 'text-nexoGreen-500' : 'hover:text-gray-700'}`}>Executions</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink exact to="/testing-projects/61a632c9876ebe00517c3e8d/testplan" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('testplan') && 'bg-nexoGreen-50'}`}>
              <svg className={`w-4 h-4 flex-shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('testplan') && 'text-nexoGreen-400'}`} viewBox="0 0 16 16">
                <path d="M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('tesplan') ? 'text-nexoGreen-500' : 'hover:text-gray-700'}`}>Voice Questions</span>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Group 2 */}
      { projectCurrent.type=='SMS' &&
        <div>
          <div className="text-xs font-semibold text-gray-400 uppercase mb-3">SMS</div>
          <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink exact to="/testing-projects/61a632c9876ebe00517c3e8d/crowdtesting"
                       className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/crowdtesting') && 'bg-nexoGreen-50'}`}>
                <svg
                    className={`w-6 h-6 flex-shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('/crowdtesting') && 'text-nexoGreen-400'}`}
                    viewBox="0 0 32 32">
                  <path
                      d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"></path>
                  <path
                      d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"></path>
                </svg>
                <span
                    className={`text-sm font-medium ${pathname.includes('feedback') ? 'text-nexoGreen-500' : 'hover:text-gray-700'}`}>
                  Crowd Details
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      }
    </div>
  );
}

export default TestingSidebar;
