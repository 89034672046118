import React from 'react';
import Clients from '../../components/sections/Clients';
import Cta from '../../components/sections/Cta';
import HeroVideo from "../../components/sections/HeroVideo";
import FeaturesSplit from "../../components/sections/FeaturesSplit";

class Soporte extends React.Component {

  render() {

    return (
      <React.Fragment>
        <HeroVideo className="illustration-section-01" />
        <Clients topDivider bottomDivider />
        <FeaturesSplit topDivider invertMobile imageFill />
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default Soporte;
