import React from 'react';
// import section header
import SectionHeader from '../../components/sections/partials/SectionHeader';
// import sections
import GenericSection from '../../components/sections/GenericSection';
import Accordion from '../../components/elements/Accordion';
import AccordionItem from '../../components/elements/AccordionItem';

class FAQ extends React.Component {


  render() {

    const genericSection02Header = {
      title: 'FAQ - Preguntas frequentes'
    }

    return (
      <React.Fragment>

        <GenericSection topDivider>
          <div className="container-xs?">
            <SectionHeader data={genericSection02Header} className="center-content" />
            <Accordion>
              <AccordionItem title="¿Qué es la inteligencia artificial (IA)?">
                En términos simples, la Inteligencia Artificial es una tecnología que nos permite programar máquinas para realizar tareas similares a las humanas que normalmente implican aprendizaje, resolución de problemas, percepción, predicción o toma de decisiones.

                La IA es una ciencia interdisciplinaria que involucra múltiples enfoques y tecnologías. La IA puede ser tan simple como una aplicación de resolución de problemas que toma decisiones basadas en la lógica si / entonces y tan compleja como los autos autónomos. El término IA a menudo se usa indistintamente con términos como aprendizaje automático, redes neuronales y deep learning. Sin embargo, el aprendizaje automático es más bien un componente o subcampo de la IA, las redes neuronales un componente del aprendizaje automático y el deep learning un componente de las redes neuronales.

                A medida que la IA ha progresado, se ha aplicado a todas las industrias, desde la atención médica hasta las finanzas y los deportes profesionales. Incluso se ha utilizado para ayudar a proteger especies en peligro de extinción.

                La IA tiene un inmenso potencial para transformar por completo la forma en que hace negocios. Durante años, la plataforma de inteligencia artificial de Smartcountry ha ayudado a los hoteles a mejorar el servicio al huésped y la eficiencia con la comunicación y la toma de decisiones automatizadas. Un examen amplio de nuestros servicios llevó a la conclusión de que cualquier empresa puede beneficiarse de la comunicación automatizada y las recomendaciones comerciales impulsadas por la inteligencia artificial, especialmente en el formato intuitivo de Smartcountry. Las grandes corporaciones ya están utilizando la inteligencia artificial para mantenerse a la vanguardia, especialmente cuando se trata de satisfacer las necesidades de servicio al cliente y los objetivos comerciales. Nuestra misión es brindar las mismas herramientas para cualquier empresa que desee seguir siendo competitiva y brindar un servicio excelente, al tiempo que reduce costos.

              </AccordionItem><AccordionItem title="¿Cuáles son algunos ejemplos de Inteligencia Artificial en la vida diaria?">
              Interactúas con la IA más de lo que piensas. La inteligencia artificial personaliza sus feeds de redes sociales y sus resultados de búsqueda de Google, recomienda qué ver a continuación en Netflix, escuchar en Spotify o comprar en Amazon. Siri de Apple, Alexa de Amazon y Cortana de Microsoft son todos asistentes virtuales impulsados ​​por IA. ¡La IA incluso funciona para mantener segura su banca en línea! Con tantas empresas grandes que adoptan la IA, es probable que la encuentre muchas veces al día. A.I está revolucionando nuestras vidas y negocios; no se quede atrás: implemente la inteligencia artificial en su negocio hoy mismo.

            </AccordionItem><AccordionItem title="¿Cómo funciona la IA?">
              La IA difiere ligeramente de una aplicación a otra y abarca varias disciplinas científicas y tecnologías. En general, sin embargo, funciona mediante la recopilación de grandes cantidades de datos y su procesamiento mediante algoritmos rápidos. La tecnología de IA puede aprender automáticamente de patrones y atributos en los datos que recibe y tomar decisiones y juicios independientes.

            </AccordionItem><AccordionItem title="¿Qué es el aprendizaje automático?">
              El aprendizaje automático (ML) es un componente o subconjunto de la inteligencia artificial. El aprendizaje automático implica un conjunto de algoritmos que tiene la capacidad de aprender y mejorar automáticamente a partir de la experiencia (o entradas de datos) sin estar programado específicamente para hacerlo. Lo hace mediante el uso de técnicas estadísticas, y cuantas más entradas se reciban, más precisa será la salida. El aprendizaje automático permite el análisis de cantidades masivas de datos y elimina la necesidad de escribir millones de líneas de código.

              Los algoritmos de aprendizaje automático incorporan tanto el aprendizaje supervisado (utilizando conjuntos de datos etiquetados) como el aprendizaje no supervisado (utilizando conjuntos de datos no etiquetados). Los algoritmos de aprendizaje automático supervisados ​​aplican lo que se ha aprendido en el pasado a nuevos conjuntos de datos etiquetados para predecir el futuro (un ejemplo de esto serían los sistemas de gestión de ingresos en la industria de viajes y hotelería). Los algoritmos de aprendizaje automático no supervisados, por otro lado, buscan estructuras ocultas en conjuntos de datos sin etiquetar (un ejemplo de esto podría ser agrupar a los clientes por comportamiento de compra).

            </AccordionItem><AccordionItem title="¿Qué es el procesamiento del lenguaje natural?">
              El procesamiento del lenguaje natural (PNL) es un subconjunto de la inteligencia artificial que utiliza computadoras para comprender e interpretar el lenguaje humano. En pocas palabras, es el uso del aprendizaje automático para permitir que una computadora obtenga significado del lenguaje. La PNL permite que las computadoras "aprendan" identificando patrones de habla naturales y extrayéndoles significado. Probablemente te hayas encontrado con la PNL a través de las opciones de conversación a texto, Google Translate y asistentes de voz como Amazon Alexa. Incluso se utiliza para detectar errores gramaticales en programas de procesamiento de texto. La PNL es un componente clave de la comunicación automatizada impulsada por IA, que se utiliza para garantizar resultados precisos e imitar los patrones del habla humana.

            </AccordionItem><AccordionItem title="¿Qué es el deep learning?">
              El deep learning (DL) es una rama del aprendizaje automático y el tipo de inteligencia artificial que mejor imita al cerebro humano. Utiliza algoritmos basados ​​en la inteligencia humana, en otras palabras, la forma en que el cerebro humano funciona de forma natural. Estos algoritmos, conocidos como redes neuronales, son capaces de "aprender" de grandes cantidades de entradas de datos. Sin embargo, en lugar de identificar características de datos predeterminadas como lo hace la tecnología general de Machine Learning, Deep Learning analiza e identifica de forma independiente las características de datos más importantes. La tecnología DL puede formar representaciones avanzadas y

              d crear su propio enfoque para la resolución de problemas.

            </AccordionItem><AccordionItem title="¿Es costosa la automatización empresarial?">
              ¡No con los servicios adecuados! De hecho, si se realiza correctamente, la automatización debería ahorrarle dinero a su empresa. Por ejemplo:

              Las respuestas automáticas a las preguntas recurrentes de los clientes reducen los costos laborales, creando una carga más liviana para los empleados y los centros de llamadas externos.

              Los servicios de Business Intelligence utilizan el análisis de datos para recomendar formas de ahorrar dinero en función de sus métricas diarias.

              Los flujos de trabajo inteligentes, creados con sus datos, asignan tareas y realizan un seguimiento del progreso automáticamente. Esto significa menos tiempo y esfuerzo en la gestión del personal y sus responsabilidades.

            </AccordionItem><AccordionItem title="¿Cómo integro la IA en mi negocio?">
              La IA puede respaldar principalmente tres necesidades comerciales importantes: la automatización de los procesos comerciales, la mejora de la información de inteligencia comercial y la mejora del compromiso de los clientes y los empleados.

              Le sugerimos que comience priorizando los factores que crearán el mayor valor para su negocio (por ejemplo, ahorrar tiempo, reducir costos, mejorar la productividad, mejorar el servicio al cliente). Luego, seleccione soluciones simples, plug-and-play que sean escalables, fáciles de implementar y que tengan un impacto instantáneo comprobado. La gestión automatizada de las comunicaciones con el cliente, por ejemplo, es una solución que cualquier empresa puede aprovechar, independientemente de su tamaño. Más adelante, escale probando funciones de gestión de comunicaciones más avanzadas, emisión automática de tickets y soluciones de inteligencia empresarial.

              Según Research, la IA no siempre funciona mejor por sí sola. Aunque las tecnologías de inteligencia artificial son excelentes para automatizar tareas repetitivas, las empresas a menudo logran las mayores mejoras de rendimiento cuando los humanos y las máquinas trabajan juntos. Por lo tanto, debe considerar la inteligencia artificial como un medio para aumentar, en lugar de reemplazar, las capacidades humanas para capturar la promesa completa de esta poderosa tecnología.

            </AccordionItem><AccordionItem title="¿Qué es la IA conversacional?">
              La IA conversacional es exactamente lo que parece: tecnología que hace posible que un humano tenga una conversación natural con una computadora. La IA conversacional es una tecnología extensa y matizada que toma en consideración factores como el contexto, la intención, el sentimiento y los patrones de lenguaje. Su aplicación más común es la creación de chatbots y asistentes de voz, que Smartcountry utiliza como base para sus servicios de comunicación.

              La IA conversacional utiliza grandes cantidades de datos, aprendizaje automático y procesamiento del lenguaje natural para imitar las interacciones humanas y puede reconocer el habla y el texto en varios idiomas.

            </AccordionItem><AccordionItem title="¿Cuáles son los beneficios comerciales de la IA?">
              Al implementar la tecnología de inteligencia artificial adecuada, su empresa puede experimentar muchos beneficios, que incluyen: ahorro de tiempo y dinero mediante la reducción de la carga de trabajo y las mejoras del flujo de trabajo; mejor servicio al cliente y satisfacción de los empleados; ventas incrementadas; menos error humano; y una toma de decisiones más rápida basada en análisis de datos en tiempo real. La IA está en camino de transformar e interrumpir todas las industrias.

              Adoptar la IA lo ayudará a mantenerse a la vanguardia, brindándole a su negocio una ventaja competitiva.

              La IA admite principalmente tres funciones comerciales principales: automatización de procesos y tareas de rutina, análisis de datos avanzado y mejora del compromiso de los clientes y empleados.

              Por ejemplo:

              El alto volumen de llamadas significa enormes costos, estrés de los empleados y la percepción de que falta servicio si las llamadas no se responden y resuelven de manera oportuna. Las soluciones de IVR e IA conversacional equipan a su empresa para manejar fácilmente grandes volúmenes de llamadas. Esta tecnología permite a las personas que llaman resolver interacciones sencillas con el servicio al cliente las 24 horas del día, los 7 días de la semana, por sí mismos por teléfono, sin tener que hablar con un agente en vivo.

              La implementación de una herramienta de gestión de comunicación con el cliente (CCM) le permite interactuar con sus clientes en cualquiera de sus canales de comunicación digital preferidos, ya sea SMS, correo electrónico, aplicaciones de mensajería social, chat web o teléfono, desde una única plataforma. Puede ver todas las comunicaciones en todos los canales en tiempo real. Esto optimiza las operaciones y los flujos de trabajo y le permite responder más rápidamente a las necesidades del cliente.

              Aprovechar la automatización de CCM e IVR le permite brindar respuestas instantáneas y precisas a la mayoría de las consultas, lo que libera a sus empleados para que se concentren en actividades más complejas o especializadas, y en aquellas interacciones que requieren la participación humana. Cuando las respuestas correctas están fácilmente disponibles, los clientes prefieren la independencia y el autoservicio automatizado: es conveniente, más rápido y más preciso y, por lo tanto, ofrece una experiencia de cliente mejorada.

              Con la recopilación de datos en tiempo real y el análisis de factores comerciales importantes, puede tomar decisiones comerciales basadas en datos más rápidas e inteligentes, identificar patrones y tendencias clave en su organización, obtener nuevos conocimientos sobre los clientes y mantener un mejor control sobre sus procesos y procedimientos operativos.

            </AccordionItem><AccordionItem title="¿Qué puede hacer la IA por mi negocio?">
              Las soluciones impulsadas por IA pueden tener una amplia gama de beneficios para cualquier negocio, desde pequeñas empresas familiares hasta grandes corporaciones. Dependiendo de su industria, la IA también puede tener ventajas especiales. Solo algunos ejemplos incluyen:

              Venta minorista:

              Recomendaciones de compra personalizadas para los clientes, facilitación de la recogida en la acera, compras en línea, control de existencias y tendencias de compra.

              Fabricación: Control de calidad y controles de seguridad automatizados.

              Carga / Transporte: Análisis de tráfico para seleccionar las mejores rutas para los conductores.

              Agricultura: maximización de cultivos con análisis predictivo y pronóstico de factores ambientales.

              Hospitalidad: reservas directas automáticas, check-in / out y habitación sin contacto, toma de pedidos, emisión automática de boletos.

            </AccordionItem><AccordionItem title="¿Cómo puede la IA ahorrar dinero a mi empresa?">
              La reducción de costos es probablemente el área en la que la inteligencia artificial funcionará primero. Al automatizar procesos y tareas, la IA ayuda a aumentar la productividad y minimizar los errores, lo que genera una mayor producción en menos tiempo y menor costo. Un área en la que la implementación de la Inteligencia Artificial puede tener un ROI particularmente alto y ahorrar costos es en el servicio al cliente.

              AI le permite brindar servicio al cliente las 24 horas del día, los 7 días de la semana, los 365 días del año, en diferentes idiomas, sin necesidad de contratar a todo un equipo para que trabaje para usted.

              Dependiendo de su industria, procesos y necesidades, existen muchas otras oportunidades para ahorrar costos con IA, tales como:

              Control automatizado de IoT de entornos de edificios para ahorrar en costos de energía

              Recomendaciones basadas en datos para la reducción de ingresos y costos

              Gestión de personal impulsada por IA, incluida la asignación automática de tareas y notificaciones

              Recopilación de datos en tiempo real y análisis de factores comerciales importantes: una instantánea completa que ayudará a identificar áreas de mejora y respaldará las decisiones del día a día.

              Con la automatización de procesos, la reducción de errores y la capacidad de la inteligencia artificial para impulsar el servicio al cliente y las ventas, no solo ahorrará dinero en costosos procesos comerciales, sino que también aumentará los ingresos.

            </AccordionItem><AccordionItem title="¿Cómo puede la IA impulsar la productividad?">
              La IA puede tener un impacto masivo en la productividad al automatizar el trabajo repetitivo y las tareas de bajo nivel, liberando a los empleados para que se concentren en tareas más especializadas y complejas. La IA también minimiza los errores y los errores, que pueden ser costosos y consumir mucho tiempo. El mayor impacto en la productividad, quizás, es la capacidad de la IA para procesar y analizar grandes cantidades de datos complejos en tiempo real, una tarea que llevaría horas de trabajo laborioso por parte de un ser humano, si es que es posible realizarla. Los datos son un recurso invaluable en el mundo digital actual, pero son inútiles si no se les da sentido o si se obtienen demasiado tarde. La IA puede proporcionar información empresarial más significativa y procesable en tiempo real e incluso proporcionar información predictiva sobre el futuro.

              Aqui hay algunos ejemplos mas:

              La emisión automática de boletos le brinda al personal toda la información que necesita, sin que un gerente tenga que supervisarlo. También responsabiliza a los miembros del personal recordándoles con recordatorios y registros.

              El servicio al cliente automatizado permite que el personal se concentre en problemas más complejos en lugar de responder las mismas preguntas una y otra vez. También mejora la resolución del primer contacto, lo que significa que no se requieren seguimientos ni acciones adicionales.

              El seguimiento y el análisis ayudan a la administración a medir el rendimiento, de modo que la productividad se pueda optimizar aún más.

              Liberar a las personas para que se concentren en un trabajo más valioso aumenta la moral del personal, lo que conduce a una mayor productividad.

            </AccordionItem><AccordionItem title="¿Cómo mejora la IA el servicio al cliente?">
              Los clientes ya no se mantienen fieles a una empresa debido a los productos o los precios. Se mantienen leales porque creen que su empresa ofrece un mejor servicio y mayor calidad que cualquier otra persona. </AccordionItem><AccordionItem title="¿Y el elemento más importante de un buen servicio al cliente?"> Tiempos de respuesta rápidos.

              Los clientes de hoy quieren ocuparse de sus necesidades ahora, no mañana o pasado mañana, ya sea la respuesta a una simple pregunta o al realizar un pedido. Están conectados, son conocedores de la tecnología y es más probable que encuentren respuestas a preguntas y soluciones a los problemas por sí mismos, en lugar de ponerse en contacto con el servicio de atención al cliente para obtener ayuda. Y en realidad les encanta ayudarse a sí mismos. Según el informe de Salesforce “Estado del cliente conectado”, el 59% de los consumidores y el 71% de los compradores comerciales dicen que la disponibilidad del autoservicio afecta su lealtad.

              Otros elementos importantes de un buen servicio al cliente incluyen un servicio amable, resolución en el primer contacto, transparencia, servicio personalizado y múltiples puntos de contacto. Entonces, </AccordionItem><AccordionItem title="¿cómo puede cumplir con todas estas expectativas y mantener contentos a sus clientes, al mismo tiempo que brinda una respuesta rápida?"> La solución es el autoservicio del cliente impulsado por IA.

              Las aplicaciones de autoservicio impulsadas por IA mejoran la experiencia del cliente a través de la conveniencia, la facilidad de uso, las respuestas instantáneas y la disponibilidad 24 horas al día, 7 días a la semana en todos los canales. La plataforma impulsada por IA de Smartcountry también permite a los clientes contactar a sus agentes automatizados a través de texto y voz, y ofrece integración entre canales (voz a mensaje o correo electrónico a texto, por ejemplo), con agentes en vivo en espera para aquellas ocasiones en las que se requiere inteligencia humana.

              Smartcountry mide la resolución de primer contacto por el porcentaje de clientes cuyos problemas se resuelven solo con AI y no requieren una transferencia a un agente en vivo. Estamos orgullosos de informar que esta tasa es de hasta un 70% entre nuestros clientes.

              nts. El resultado: mayor satisfacción de los huéspedes y reducción de costos del 85%.

            </AccordionItem><AccordionItem title="¿Qué es IVR?">
              IVR, o Interactive Voice Response, es lo que muchos describirían como un "recepcionista automatizado": es, de hecho, una tecnología de sistema telefónico automatizado para manejar las llamadas entrantes. Las personas que llaman preguntan por lo que necesitan, interactuando con un sistema de reconocimiento de voz computarizado que proporciona la información requerida o conecta automáticamente a la persona que llama con el agente, departamento o persona correspondiente.

            </AccordionItem><AccordionItem title="¿Cómo funciona el IVR y para qué se utiliza?">
              La tecnología IVR integra computadoras y teléfonos y puede variar desde muy básica hasta muy avanzada. Un sencillo sistema IVR le permite pregrabar saludos y opciones de menú que la persona que llama puede seleccionar con el teclado de su teléfono. Un sistema IVR más avanzado aprovecha el reconocimiento de voz para permitir que la persona que llama use comandos de voz simples en lugar de "presionar botones". Los sistemas IVR muy avanzados utilizan IA y pueden proporcionar respuestas completas a las preguntas de la persona que llama. Este tipo de IVR comprenderá lo que preguntan las personas que llaman y responderá a sus preguntas directamente o proporcionará una transferencia a una persona real que pueda ayudar con un problema específico.

              Los sistemas IVR tienen muchos usos, por ejemplo:

              El enrutamiento inteligente de llamadas puede precalificar a las personas que llaman y enrutarlas al agente o departamento que esté en mejores condiciones para ayudarlas, eliminando la necesidad de operadores en vivo y reduciendo los tiempos de espera.

              Manejo eficiente de llamadas para centros de llamadas con grandes volúmenes de llamadas, mejorando la resolución del primer contacto.

              Proporcionar a las personas que llaman un autoservicio para las preguntas y los problemas más frecuentes, incluso fuera del horario de atención habitual.

              Servicio personalizado y priorización de clientes de alto valor por parte de las personas que llaman previamente.

              Promocionar ofertas especiales o notificar a las personas que llaman sobre anuncios como cambios en los horarios o servicios de apertura.

              Toma de pedidos.

              Programación de citas y recordatorios.

              Encuestas de retroalimentación.

            </AccordionItem><AccordionItem title="¿Cuáles son los beneficios de IVR?">
              Según la investigación, casi el 70% de los clientes prefieren el autoservicio para preguntas y problemas simples. Y casi el 80% espera poder resolver un problema complejo hablando con una sola persona. En estos días, los clientes quieren ser más autosuficientes y las empresas buscan mejorar la experiencia del cliente sin aumentar los costos.

              Los altos volúmenes de llamadas significan altos costos, mayor estrés de los empleados y la percepción de que falta servicio si las llamadas no se responden y resuelven de manera oportuna. El IVR es simple y efectivo, y reducirá significativamente los costos y aumentará la eficiencia y el servicio al cliente dentro de cualquier empresa:

              IVR funciona 24 horas al día, 7 días a la semana y maneja llamadas simultáneas ilimitadas. Esto significa que no se hace que las personas que llaman esperen en espera durante la etapa inicial de una llamada.

              El enrutamiento inteligente de llamadas elimina la necesidad de agentes activos cuyo único trabajo es responder y transferir llamadas, y también elimina la necesidad de que las personas que llaman repitan su problema a varios agentes.

              Con IVR, puede aumentar significativamente la resolución del primer contacto porque las personas que llaman siempre reciben la información que necesitan al instante o se enrutan al agente o departamento más calificado para satisfacer sus necesidades. Cuando el agente más calificado recibe una llamada, es menos probable que la persona que llama sea transferida a otro agente o departamento. En muchos casos, las preguntas de las personas que llaman se responden sin siquiera hablar con un agente en vivo.

              Brindar autoservicio a las personas que llaman para las preguntas y problemas más frecuentes libera a los miembros de su equipo para que se concentren en preguntas más complejas y responsabilidades especializadas. Los empleados que se liberan de tareas estresantes, monótonas o repetitivas están más motivados para participar, son más productivos y, por lo tanto, más valiosos para su negocio.

              Dado que un sistema de autoservicio IVR puede manejar llamadas a cualquier hora, puede extender su horario comercial.

              IVR le permite priorizar las llamadas de clientes de alto valor. Esto puede significar enrutarlos automáticamente al agente más apropiado o colocarlos al principio de la cola si todos los agentes están ocupados.

              IVR puede ayudar a que su negocio parezca más grande de lo que realmente es. El IVR se puede configurar para permitir que las personas que llaman hablen con ventas, soporte, contabilidad o marketing, por ejemplo, y la persona o personas de su equipo asignadas para responder a las llamadas sabrán qué departamento solicitó el cliente.

              Vea un ejemplo de los beneficios de IVR en acción en este estudio de caso.

            </AccordionItem><AccordionItem title="¿Qué es la resolución del primer contacto?">
              Tradicionalmente, la resolución del primer contacto es una métrica de servicio al cliente que rastrea el porcentaje de clientes que reciben ayuda exitosa la primera vez que se comunican con un centro de servicio al cliente. Esto significa que no se requieren seguimientos ni acciones adicionales. Sin embargo, esto parecía demasiado fácil, por lo que hemos desarrollado nuestra propia definición.

              En Smartcountry, la resolución del primer contacto se mide por el porcentaje de clientes cuyos problemas se resuelven solo con IA y no requieren una transferencia a un agente humano. Estamos orgullosos de informar que esta tasa es de hasta un 70% entre nuestros clientes.

            </AccordionItem><AccordionItem title="¿Cómo funciona el servicio al cliente automatizado?">
              El servicio al cliente automatizado utiliza tecnología impulsada por IA para ofrecer al cliente s opciones de autoservicio para tareas simples, por ejemplo, proporcionar información, resolver problemas básicos, reservar citas y realizar pedidos.

              Alertas Smartcountry en una sola sesión de entrenamiento. Pero, como el servicio al cliente es nuestra especialidad, no nos detenemos ahí. Brindamos soporte 24 horas al día, 7 días a la semana a todos nuestros clientes, haciendo que el soporte continuo sea tan fácil como la configuración inicial.

            </AccordionItem><AccordionItem title="¿Cómo puedo aprender más?">
              Si tiene preguntas más específicas sobre los servicios de Smartcountry, estaremos encantados de ayudarle. Envíenos cualquier pregunta aquí o configure una demostración para ver si Smartcountry es una buena opción para su negocio.                                                                                                                                                                                                                                                      Si tiene preguntas más específicas sobre los servicios de Smartcountry, estaremos encantados de ayudarle. Envíenos cualquier pregunta aquí o configure una demostración para ver si Smartcountry es una buena opción para su negocio.
            </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}


export default FAQ;
