import React, {useEffect, useState} from 'react';

import SearchForm from '../../partials/actions/SearchForm';
import ContactosTable from "../../partials/pisosadias/ContactosTable";
import NewContactForm from "./NewContactForm";
import PadApi from "../../api/PadApi";
import ApiCaller from "../../lib/ApiCaller";
import {setContacts, setSearchTerm} from "../../store/padStore";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/store";
import {useHistory} from "react-router";
import toast from "../../shared/utils/toast";
import BackofficeBase from "./pagefactory/BackofficeBase";
import {currentYearWeekKey} from "../../shared/utils/dateTime";
import {StyledSpinner} from "../../components/added/Button/Styles";
import moment from "moment";
import DetailContactForm from "./DetailContactForm";

const Contacts = () => {


    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [allContacts, setAllContacts] = useState<any[]>([]);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const handleSelectedItems = (selectedItems:any[]) => {setSelectedItems([...selectedItems]);};

    const currentUser = useSelector((state: AppState) => state.session.userInfo);
    const loggedIn = useSelector((state: AppState) => state.session.loggedIn);
    const dispatch = useDispatch();
    const history = useHistory();
    const [we, SetWe] = useState<any>({})
    const [currentWeek, setCurrentWeek] = useState<string>('')

    useEffect(() => {
        const legalAPI = new PadApi(new ApiCaller());
        loggedIn ? legalAPI.getPisosStruct().then(byWeek => {
            const key:any = currentYearWeekKey();
            setCurrentWeek(key);
            SetWe(byWeek);
            dispatch(setContacts(byWeek[key]||[]));
            setAllContacts(byWeek[key]||[]);

        }) : history.push('/');
    }, []);

    const openSelectedsInIcnea = () => {
        const api = new PadApi(new ApiCaller(currentUser.token));
        selectedItems.map(id=>{
            api.launch(id,'ONBOARD_WHATSAPP').then(msg => {
                toast.show(`Whatsapp enviado a ${msg.komRow.payload.name}`);

            })
        })
    }

    const sendWarnings = () => {
        const api = new PadApi(new ApiCaller(currentUser.token));
        selectedItems.map(id=>{
            api.launch(id,'ROAT-WARNING').then(msg => {
                toast.show(`Whatsapp enviado a ${msg.komRow.payload.name}`);

            })
        })
    }

    const searchFunction = (e:string) =>{
        let result = [];
        if (e.length==0) {
            result = allContacts;
        } else if (e.startsWith('+')) {
            const numberFilter:any[] = (e.length>0) ? allContacts.filter(c=>c.phone.startsWith(e)) : allContacts;
            result = numberFilter;
        } else {
            const placeFilter:any[] = (e.length>0) ? allContacts.filter(c=>c.place.startsWith(e)) : allContacts;
            const nameFilter:any[] = (e.length>0) ? allContacts.filter(c=>c.name.startsWith(e)) : allContacts;
            result = placeFilter.concat(nameFilter);
        }
        dispatch(setContacts(result))
        dispatch(setSearchTerm(e));
    }

    const nextWeek = () => {
        const idx = Object.keys(we).indexOf(currentWeek);
        const jumpy = idx<0?2:idx;
        jumpWeek(jumpy +1);

    }
    const prevWeek = () => {
        const idx = Object.keys(we).indexOf(currentWeek);
        const jumpy = idx<0?2:idx;
        jumpWeek(jumpy -1);
    }
    const jumpWeek = (nextIndex:number) => {
        let nextItem = Object.keys(we)[nextIndex];
        if (nextItem===undefined) {
            toast.error('Esa semana está fuera de rango')
            return;
        }
        setCurrentWeek(nextItem);
        dispatch(setContacts(we[nextItem]||[]));
        setAllContacts(we[nextItem]||[]);

    }

    const Actions = () => <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        {/* Search form */}
        <SearchForm placeholder="Search " searchFunction={searchFunction}/>
        {/* Add member button */}
        <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" aria-controls="feedback-modal" onClick={(e) => { e.stopPropagation(); setFeedbackModalOpen(true); }}>
            <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Create new contact</span>
        </button>
        {/* Add member button */}
        { selectedItems.length>0 && <>

            <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" aria-controls="feedback-modal" onClick={(e) => { sendWarnings(); }}>
                <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2"> Envia ({selectedItems.length}) Warning</span>
            </button>

            <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" aria-controls="feedback-modal" onClick={(e) => { openSelectedsInIcnea(); }}>
                <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">Open ({selectedItems.length}) Envia OnBoarding Wass</span>
            </button>

        </>}
    </div>

    const Navigation = () => <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        {/* Add member button */}
        <button className="btn bg-light-blue-500 hover:bg-light-blue-600 text-white btn-xs" aria-controls="feedback-modal" onClick={(e) => { e.stopPropagation(); prevWeek(); }}>
            <span className="hidden xs:block ml-2">Semana Pasada</span>
        </button>
        <button className="btn bg-light-blue-500 hover:bg-light-blue-600 text-white btn-xs" aria-controls="feedback-modal" onClick={(e) => { e.stopPropagation(); nextWeek(); }}>
            <span className="hidden xs:block ml-2">Siguiente Semana</span>
        </button>
        <img className="w-8 h-8 rounded-full" src={'/api/v1/image/user/635c33bff84c7d003f67b889'} width="64" height="64" alt="User" />

    </div>

    const [scrollbarModalOpen, setScrollbarModalOpen] = useState(false);
    const [contact, setContact] = useState(false);
    const handleCustomerReport = (contact:any) => {
        setContact(contact);
        setScrollbarModalOpen(true)
    }
    return (
        <>
            <BackofficeBase actions={<Actions/>} titleHeader={`Reservas ${moment(new Date(currentWeek)).add(5, 'days').format('D/M/YYYY')} al ${moment(new Date(currentWeek)).add(12, 'days').format('D/M/YYYY')}`} navigation={<Navigation/>}>
                <ContactosTable selectedItems={handleSelectedItems} showCustomerReport={handleCustomerReport}/>
            </BackofficeBase>
            <NewContactForm feedbackModalOpen={feedbackModalOpen} setFeedbackModalOpen={setFeedbackModalOpen} />
            <DetailContactForm feedbackModalOpen={scrollbarModalOpen} setFeedbackModalOpen={setScrollbarModalOpen} contact={contact}/>
        </>
    );
}

export default Contacts;
