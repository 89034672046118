
//------------  state definition
export interface PadState {
    current: any,
    searchTerm: string,
    contacts: any[],
    events: any[],
    ready: boolean
}

//------------ actions
export const SET_CURRENT_DOC = 'SET_CURRENT_DOC'
export const GET_DRAFTS_LIST = 'GET_DRAFTS_LIST'
export const GET_SIGNED_LIST = 'GET_SIGNED_LIST'
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SET_LOADING = 'SET_LOADING'
export const SET_LOADED = 'SET_LOADED'

interface SetContactAction {
  type: typeof SET_CURRENT_DOC,
  payload: any
}

interface GetContactsAction {
  type: typeof GET_DRAFTS_LIST,
  payload: any[]
}

interface GetEventsAction {
  type: typeof GET_SIGNED_LIST,
  payload: any[]
}
interface GetSearchTermAction {
  type: typeof SET_SEARCH_TERM,
  payload: string
}
interface loadingAction {
  type: typeof SET_LOADING,
  payload: boolean
}
interface loadedAction {
  type: typeof SET_LOADED,
  payload: boolean
}


export function setLoading(): PadActionTypes {
    return {
        type: SET_LOADING,
        payload: true
    }
}
export function setLoaded(): PadActionTypes {
    return {
        type: SET_LOADED,
        payload: false
    }
}

export function setCurrentDoc(doc: any): PadActionTypes {
    return {
      type: SET_CURRENT_DOC,
      payload: doc
    }
}
export function setContacts(contacts: any[]) : PadActionTypes  {
    return {
      type: GET_DRAFTS_LIST,
      payload: contacts
    }
}
export function setSearchTerm(term: string) : PadActionTypes  {
    return {
      type: SET_SEARCH_TERM,
      payload: term
    }
}

export function setEvents(events: any[]) : PadActionTypes  {
    return {
      type: GET_SIGNED_LIST,
      payload: events
    }
}

export type PadActionTypes = SetContactAction | GetContactsAction | GetEventsAction | GetSearchTermAction | loadingAction | loadedAction ;

//------------ reducer
const initialState: PadState = {
    current: {},
    ready: false,
    contacts: [],
    events: [],
    searchTerm:''

}

export function padReducer( state = initialState, action: PadActionTypes): PadState {
  switch (action.type) {
    case SET_CURRENT_DOC: {
      return {
        ...state,
          current: action.payload
      }
    }
    case GET_DRAFTS_LIST: {
      return {
        ...state,
          contacts: action.payload
      }
    }
    case GET_SIGNED_LIST: {
      return {
        ...state,
          events: action.payload
      }
    }
    case SET_SEARCH_TERM: {
      return {
        ...state,
          searchTerm: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
          ready: action.payload
      }
    }
    case SET_LOADED: {
      return {
        ...state,
          ready: action.payload
      }
    }
    default:
      return state
  }
}
