import ApiCaller from '../lib/ApiCaller';

class NotificationApi {
    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    deleteMessage(messageId: string): Promise<void> {
        return this.apiCaller.call('/v1/notify/' + messageId, 'DELETE', {
            id: messageId
        }).catch((err) => {
            console.error(err);
            throw Error(err);
        });
    }


}

export default NotificationApi;
