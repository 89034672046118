import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import SectionSubHeader from './partials/SectionSubHeader';
import Image from '../elements/Image';

import featuresSplitImage1 from './../../images/features/features-split-image-01.png';
 {/* import featuresSplitImageTop1 from './../../images/features-split-top-01.png'; */}
import featuresSplitImage2 from './../../images/features/features-split-image-02.png';
 {/* import featuresSplitImageTop2 from './../../images/features-split-top-02.png'; */}
import featuresSplitImage3 from './../../images/features/features-split-image-03.png';
import {Link} from "react-router-dom";
 {/* import featuresSplitImageTop3 from './../../images/features-split-top-03.png'; */}

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplitHome extends React.Component {

  render() {

    const {className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, invertMobile, invertDesktop, alignTop, imageFill, ...props} = this.props;

    const outerClasses = classNames('features-split section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);
    const innerClasses = classNames('features-split-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');
    const splitClasses = classNames('split-wrap', invertMobile && 'invert-mobile', invertDesktop && 'invert-desktop', alignTop && 'align-top');

    const sectionHeader = {
      title: 'Relaciones con los clientes para la era de la gamificación',
      paragraph: 'Soluciones integrales e inmediatas sin necesidad de conocimientos técnicos. Elija las aplicaciones que agreguen más valor a su negocio y escale en cualquier momento.'

    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

         {/* start split 01 */}

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50" data-reveal-delay="200">
                  Interconexión en todos los canales
                  </h3>
                  <p className="text-smart text-4xl md:text-lg text-smartCountry-51 " data-reveal-delay="400">
                    Respuestas instantáneas, automatizadas y participación proactiva.
                    <ul className='mt-5 text-samrt mb-9 '>
                      <li>• Los clientes utilizan su canal de comunicación favorito</li>
                      <li>• SMS, correo electrónico, aplicaciones de mensajería social, asistentes de voz y teléfono. </li>
                      <li>• Los canales se combinan para la experiencia perfecta según el contexto de cada conversación.</li>
                    </ul>
                    <Link to={`/manager/`} className="btn bg-red-500 hover:bg-red-600 text-white" >Manager de Comunicación</Link>
                  </p>
                </div>
                <div className={
                   classNames('split-item-image center-content-mobile illustration-element-02 reveal-from-top', imageFill && 'split-item-image-fill')}>
                  <Image
                  className="has-shadow"
                  src={featuresSplitImage1}
                  alt="Features split 01"
                  width={528}
                  height={396} />
                </div>
              </div>
        {/* start split 02 */}
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 reveal-from-right" data-reveal-delay="200">
                    Su negocio en piloto automático, con usted en control
                  </h3>
                  <p className="m-0 text-smart text-4xl md:text-lg text-smartCountry-51 mb-9 reveal-from-right" data-reveal-delay="400">
                    Ofrezca lo mejor en experiencia a los huéspedes con el servicio personalizado y eficiente que sus clientes esperan.
                    <ul className='mt-5 text-samrt'>
                    <li>• El servicio sin esfuerzo las 24 horas del día, los 7 días de la semana, sin esperas, independientemente de la ocupación o del personal disponible, proporciona ganancias y ahorros exponenciales.</li>
                    <li>• Combine y combine soluciones de la industria, incluida la reserva directa, el check-in sin contacto, </li>
                    <li>• las experiencias internas y las herramientas para cultivar las relaciones posteriores a la estadía.</li>
                  </ul>
                  </p>
                  <Link to={`/automation/`} className="btn bg-red-500 hover:bg-red-600 text-white" >Automatización empresarial</Link>
                </div>
                <div className={
                   classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                  className="has-shadow"
                  src={featuresSplitImage2}
                  alt="Features split 02"
                  width={528}
                  height={396} />
                </div>
              </div>
         {/* start split 03 */}
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 reveal-from-left" data-reveal-delay="200">
                    El mejor servicio para sus huéspedes y su hotel
                  </h3>
                  <p className="m-0 text-smart text-4xl md:text-lg text-smartCountry-51 mb-9 reveal-from-left" data-reveal-delay="400">
                  Solicite a los usuarios del asistente de voz manos libres una dirección de correo electrónico, donde puede enviar automáticamente información o promociones relevantes. Esto aumenta la finalización de la compra y crea su base de datos.
                  </p>
                  <Link to={`/iahospitality/`} className="btn bg-red-500 hover:bg-red-600 text-white" >IA para hosteleria</Link>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={featuresSplitImage3}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const img01Style = {
  position: 'absolute',
  bottom: '-12.4%'
}

const img02Style = {
  position: 'absolute',
  bottom: '-13.1%'
}

const img03Style = {
  position: 'absolute',
  bottom: '-16.1%',
  left: '-10.1%',
  right: '10.1%'
}

FeaturesSplitHome.propTypes = propTypes;
FeaturesSplitHome.defaultProps = defaultProps;

export default FeaturesSplitHome;
