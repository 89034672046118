import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';


import feature1 from '../../images/features/feature-tile-icon-01.svg'
import feature2 from '../../images/features/feature-tile-icon-02.svg'
import feature3 from '../../images/features/feature-tile-icon-03.svg'



const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTilesManager extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );


    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
      );

    const sectionHeader = {
      title: 'Comunicarse con lo mejor de la inteligencia humana y artificial',
      paragraph: 'Quienes se comunican con su empresa esperan una respuesta instantánea en todos los canales - mensajes de texto, chat web, redes sociales, llamadas, correos electrónicos y asistentes virtuales'
    };

    
    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">

          <div className={innerClasses}>

          <div className="hero-content">
              <h1 className="title-smart text-6xl md:text-6xl text-smartCountry-50 font-bold mb-1" data-reveal-delay="300">
              Comunicaciones Simplificadas
              </h1>

            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

           {/* Features 01 */}
             <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={feature1}
                        alt="Features tile icon 01"
                        width={72}
                        height={72} />
                    </div>
                  </div>

                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 uppercase">
                    <Link to="manager/messaging">Mensajería</Link>
                    </h4>
                    <p className="m-0 text-s">
                    Respuestas instantáneas a las consultas de los clientes realizadas a través de aplicaciones de mensajería social, correo electrónico y SMS.
                    </p>
                  </div>
                </div>
             </div>

             {/* Features 02 */}
              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="100">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={feature2}
                        alt="Features tile icon 02"
                        width={72}
                        height={72} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 uppercase">
                    <Link to="manager/interactivevoiceresponse">IVR</Link>
                    </h4>

                    <p className="m-0 text-s">
                    La respuesta amigable y automatizada significa ahorros adicionales y sin tiempos de espera para las personas que llaman.
                    </p>
                  </div>
                </div>
              </div>

              {/* Features 03 */}
              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={feature3}
                        alt="Features tile icon 03"
                        width={72}
                        height={72} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 mt-0 mb-8 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 uppercase">
                    <Link to="manager/voice">Llamadas de Voz</Link> 
                    </h4>
                    <p className="m-0 text-s">
                      La autenticación de usuario patentada de Smartcountry convierte a los asistentes de voz en un canal principal.
                    </p>
                  </div>
                </div>
              </div>
      {/* End Features */}


            </div>
          </div>
        </div>
      </div>
</section>
    );
  }
}

FeaturesTilesManager.propTypes = propTypes;
FeaturesTilesManager.defaultProps = defaultProps;

export default FeaturesTilesManager;
