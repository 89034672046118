import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

import c1 from '../../images/clientes/logoComa.png';
import c4 from '../../images/clientes/iwaqi.png';
import c2 from '../../images/clientes/logoCollada.png';
import c3 from '../../images/clientes/logoPisosadias.png';
import c5 from '../../images/clientes/logoAmbulancias.png';
import c6 from '../../images/clientes/tbg_lite.png';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Clients extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'clients section reveal-fade',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'clients-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div
            className={innerClasses}
          >
            <ul className="list-reset">
              {/*<li><Image src={c1} alt="Coma Cofee Roasters" width={150} height={80} /></li>*/}
              <li><Image src={c2} alt="Hotel la Collada" width={150} height={80} /></li>
              <li><Image src={c3} alt="Pisos a Dias" width={150} height={80} /></li>
              <li><Image src={c4} alt="Iwaqi" width={100} height={31} /></li>
              <li><Image src={c5} alt="Ambulancies Carlemany" width={150} height={80} /></li>
              <li><Image src={c6} alt="The Blue Guest" width={150} height={80} /></li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;
