import React, {useState} from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import TransactionPanel from "../../pages/pisosadias/TransactionPanel";
import charlilbo from "../../images/bilbogold.png"
import PadApi from "../../api/PadApi";
import ApiCaller from "../../lib/ApiCaller";
import {useSelector} from "react-redux";
import {AppState} from "../../store/store";
import toast from "../../shared/utils/toast";

interface props {
    rowstatus: any[];
}

const ROATstatusDetail: React.FC<props> = ({ rowstatus}) => {

    const roatColor = (status:string) => {
        switch (status) {
            case 'NO':
                return 'bg-red-100 text-red-500';
            default:
                return 'bg-green-100 text-green-600';
        }
    };
    const statusColor = (status:string) => {
        switch (status) {
            case 'FAILED':
                return 'bg-red-100 text-red-500';
            case 'PENDING':
                return 'bg-yellow-100 text-yellow-600';
            case 'EMPTY':
                return 'bg-red-100 text-red-600';
            default:
                return 'bg-green-100 text-green-600';
        }
    };


    const [payPayload, setPayPayload] = useState({});
    const [payDialog, setpPayDialog] = useState(false);
    const [phone, setPhone] = useState();
    const [transactionPanelOpen, setTransactionPanelOpen] = useState(false);
    const user = useSelector((state: AppState) => state.session.userInfo);

    const force = (status:string) => {
        let api = new PadApi(new ApiCaller(user.token));
        api.forcePay(payPayload, status ).then(byWeek => {
            toast.success(`Se ha cambiado el status a ${status}`);
            setpPayDialog(false);
        });
    }
    const close = () => {
        setpPayDialog(false);
    }
    const paylodDialog = (payPayload:any) => {
        setPayPayload({place: payPayload.place, date: payPayload.date});
        setpPayDialog(true);
    }

    const renderRow = (r:any,i:any) => {
        return <tr key={`rs_${i}`}>
            <td className="p-2"><div className="text-gray-800">{r.place}</div></td>
            <td className="p-2"><div className={`text-center ${roatColor(r.hasRoat?'ROAT':'NO')}`}>{r.hasRoat?'ROAT':'NO'}</div></td>
            <td className="p-2"><div className={`text-center ${statusColor(r.pay.status)}`}>{r.pay.status}</div></td>
            <td className="p-2"><div className={`text-center ${roatColor(r.ack?'READED':'NO')}`}>{r.ack?'READED':'NANAI'}</div></td>
            <td className="p-2">
                <div className="text-center text-indigo-500">
                    {/*<button className="btn bg-white border-gray-200 hover:border-gray-300 text-nexoGreen-500" onClick={(e) => { e.stopPropagation(); }}>PDF</button>*/}
                    <a className="btn bg-white border-gray-200 hover:border-gray-300 text-nexoGreen-500 text-xxs" href={`/pisosadias-roat/${Buffer.from(r.phone).toString('base64')}`} target={'_blank'}>ROAT</a>
                    <button className="btn bg-white border-gray-200 hover:border-gray-300 text-nexoGreen-500 text-xxs"
                            onClick={(e)=>{e.stopPropagation();paylodDialog(r);}}>PAY</button>
                    <button className="btn bg-white border-gray-200 hover:border-gray-300 text-nexoGreen-500 text-xxs" onClick={(e)=>{e.stopPropagation();setPhone(r.phone);setTransactionPanelOpen(true);}}> > </button>
                    {/*<button className="btn bg-white border-gray-200 hover:border-gray-300 text-nexoGreen-500" onClick={(e) => { e.stopPropagation(); }}>Pay</button>*/}
                </div>
            </td>
        </tr>
    }

    return (<>
        <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
            <tr>
                <th className="p-2"><div className="font-semibold text-left">Apartamento</div></th>
                <th className="p-2"><div className="font-semibold text-center">ROAT</div></th>
                <th className="p-2"><div className="font-semibold text-center">Pay</div></th>
                <th className="p-2"><div className="font-semibold text-center">ACK</div></th>
                <th className="p-2"><div className="font-semibold text-center">Accion</div></th>
            </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-gray-100">

            { rowstatus.filter(f=>f.pay.status=='EMPTY').map( (r,i) => renderRow(r,`E_${i}`)) }
            { rowstatus.filter(f=>f.pay.status=='PENDING').map( (r,i) => renderRow(r,`P_${i}`)) }
            { rowstatus.filter(f=>f.pay.status=='PAY').map( (r,i) => renderRow(r,`A_${i}`)) }

            </tbody>
        </table>
        {payDialog && (
            <ReactBSAlert
                custom
                style={{ display: 'block', marginTop: '100px' }}
                title="Modificar estado del Pago"
                onConfirm={() => {}}
                onCancel={() => close()}
                btnSize=""
                customButtons={
                    <div className="w-full">
                        <div className="grid grid-flow-col">
                            <button onClick={()=>{force('PENDING')}} className={`btn hover:border-gray-300 text-center ${statusColor('PENDING')}`}>PENDING</button>
                            <button onClick={()=>{force('PAY')}} className={`btn hover:border-gray-300 text-center ${statusColor('PAY')}`}>PAY</button>
                        </div>
                    </div>
                }
            >
                <img src={charlilbo}/>
            </ReactBSAlert>
        )}
            {transactionPanelOpen &&  <TransactionPanel phone={phone} transactionPanelOpen={transactionPanelOpen} setTransactionPanelOpen={setTransactionPanelOpen} /> }
        </>
    )
}

export default ROATstatusDetail;
