import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import Project from './ProjectTableItem';
import TestProject from '../../lib/common/models/testProject';
import TestProjectApi from "../../api/TestProjectApi";
import ApiCaller from "../../lib/ApiCaller";
import {setCurrentDrafts} from "../../store/docsStore";
import {AppState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";

interface ContainerProps {
  selectedItems: any;
}
const TestProjecTable:React.FC<ContainerProps>  = ({selectedItems}) => {

  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [list, setList] = useState<any[]>([]);

  const docs:TestProject[] = useSelector((state: AppState) => state.current.drafts);
  const currentUser = useSelector((state: AppState) => state.session.userInfo);
  const loggedIn = useSelector((state: AppState) => state.session.loggedIn);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const legalAPI = new TestProjectApi(new ApiCaller(currentUser.token));
    loggedIn ? legalAPI.getDraftRaw().then(items => {
      dispatch(setCurrentDrafts(items));
    }) : history.push('/');
  }, []);

  useEffect(() => {
    // @ts-ignore
    focusHandling('outline');
  }, [list]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(docs.map((li:any) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e:any) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line
  }, [isCheck]);

  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">Builds <span className="text-gray-400 font-medium">{list.length}</span></h2>
      </header>
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                    </label>
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Issued on</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Type</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Platform</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Actions</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {
                docs && docs.map((projt:any) => {
                  return (
                    <Project
                        obj={projt}
                      id={projt._id}
                      key={projt._id}
                      customName={projt.name}
                      status={projt.status}
                      issueddate={projt.createdAt}
                      type={projt.type}
                      platform={projt.platformIds.join(' ')}
                      handleClick={handleClick}
                      isChecked={isCheck.includes(projt._id)}
                    />
                  )
                })
              }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default TestProjecTable;
