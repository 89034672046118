import React, {useState} from 'react';
import {RouteComponentProps} from "react-router";
import DocumentEntradaPanel from "./DocumentEntradaPanel";
import ApiCaller from "../../../lib/ApiCaller";
import {getStringFromBase64} from "../../../utils/Base64Content";
import SendedKomunication from "./SendedKomunication";
import PadApi from "../../../api/PadApi";

interface ContainerProps extends RouteComponentProps<{ parteHash:string; }> {};

const FormDocumentEntrada: React.FC<ContainerProps> = ({match}) => {

    const [hash, setHash] = useState<string>('');
    const [parte, setParte] = useState<any>();
    const [expired, setExpired] = useState(false);

    React.useEffect( () =>  {
        const parteHash = match.params.parteHash;
        setHash(getStringFromBase64(parteHash));
        var padAPI = new PadApi(new ApiCaller());
        padAPI.getCustomerEntradaByPhoneNumber(getStringFromBase64(parteHash)).then((item:any) => {
            if (item.err) {
                setHash(item.sourceId);
                setExpired(false); // never expired because other guests can inform their data
            }
            else setParte(item.contact);
        }).catch(err => {
            console.error(err);
        });
    }, []);

    return (
        <>
            {parte && <DocumentEntradaPanel parte={parte}/>}
            {expired && <SendedKomunication message={'Los documentos se enviaron correctamente'} subTitle={'La compañía aseguradora se pondrá en contacto en los próximos días.'} pdfLink={hash} />}
        </>
    )
}

export default FormDocumentEntrada;
