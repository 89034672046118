import ApiCaller from '../lib/ApiCaller';

class TestProjectApi {
    apiCaller: ApiCaller;
    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    getCertificatesRaw(user:string) : Promise<void | any | undefined> {
        return this.apiCaller.call(`/v1/documents/document/${user}`, 'GET')
    }

    getDraftRaw() : Promise<void | any | undefined> {
        return this.apiCaller.call(`/v1/testproject/projects`, 'GET')
    }
    removeDraft(id:string) : Promise<void | any | undefined> {
        return this.apiCaller.call(`/v1/testproject/removedraft/${id}`, 'DELETE')
    }

    getDocsRaw(country:string) : Promise<void | any | undefined> {
        country = 'es';
        return this.apiCaller.call(`/v1/testproject/getAllModels/${country}`, 'GET')
            .then(data => {
                return {models: data.models.map((m:any)=>{return m;}) };
            })
    }

    search(term:string,country?:string) : Promise<void | any | undefined> {
        return this.apiCaller.call(`/v1/testproject/search/${term}/${country?'es':''}`, 'GET')
            .then(data => {
                return {models: data.models.map((m:any)=>{return m;}) };
            })
    }

    getModelBySlug(slug:string): Promise<any> {
        return this.apiCaller.call(`/v1/testproject/getModelBySlug/${slug}`, 'GET')
            .then(data => {
                data.legalContent.vars=data.legalContent.vars.map((v:string)=>{
                    return {key:v,value:''};
                })
                return data;
            })
    }

    sendToWriteDoc(content:string,slug:string): Promise<void | any | undefined> {
        return this.apiCaller.download('/v1/testproject/sendToWriteDoc', 'POST', {base:content, slug: slug}).then(file=> {
           return file;
        });
    }

    sendToWritePDF(content:string,slug:string): Promise<void | any | undefined> {
        return this.apiCaller.download('/v1/testproject/sendToWritePDF', 'POST', {base:content, slug: slug}).then(file=> {
           return file;
        });
    }

    createPDFWithCertificate(content:string,slug:string, userId:string): Promise<void | any | undefined> {
        return this.apiCaller.call('/v1/testproject/createPDFWithCertificate', 'POST', {base:content, slug: slug, userId: userId}).then(file=> {
           return file;
        });
    }

    saveDraft(content:string,slug:string, userId:string, customName:string,customDescription:string, vars:any[]): Promise<void | any | undefined> {
        let params = {base:content, slug: slug, userId: userId, customName: customName, customDescription: customDescription, vars};
        return this.apiCaller.call('/v1/testproject/createDraft', 'POST', params).then(file=> {
           return file;
        });
    }

    createTestProject(jobName:string, userId:string, customName:string, customDescription:string, platformIds:any[], type:string): Promise<void | any | undefined> {
        let params = {base:'',jobName: jobName, userId: userId, customName: customName, customDescription: customDescription,platformIds:platformIds, type:type};
        return this.apiCaller.call('/v1/testproject/createDraft', 'POST', params).then(file=> {
           return file;
        });
    }


    updateDraft(content:any, token: string): Promise<void | any | undefined> {
        return this.apiCaller.call('/v1/testproject/createDraft/'+content.id, 'POST', content, token).then(file=> {
           return file;
        });
    }

    publishDraft(draftId:string): Promise<void | any | undefined> {
        return this.apiCaller.call('/v1/testproject/publishDraft/', 'POST', {draftId:draftId}).then(file=> {
           return file;
        });
    }


}

export default TestProjectApi;
