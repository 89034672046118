import React, {useState} from 'react';
import {Link} from "react-router-dom";
import FileSaver from "file-saver";
import {getPartePDF} from "../../utils/ApiCommands";

const SendedParte = ({message='El parte se ha enviado', subTitle='Muchas gracias por confiar en nosotros', pdfLink=''}:{message?:string, subTitle?:string, pdfLink?:string}) => {

    React.useEffect( () =>  {
    }, []);


    return (
        <>
            <section className={'hero section center-content'}>
                <div className="container-sm">
                    <div className={'hero-inner section-inner'}>
                        <div className="hero-content">
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold mb-1" data-reveal-delay="300">
                                {message}
                            </h1>
                            <div className="container-xs">
                                <p className="mt-0 mb-32 reveal-from-top" data-reveal-delay="400">
                                    {subTitle}
                                </p>
                                <div className="reveal-from-top" data-reveal-delay="500">
                                    <Link to="/onboarding-01/" className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white text-2xl " >
                                        Prueba con iwaqi
                                    </Link>
                                </div>
                                <br/>
                                { pdfLink!='' && <div className="reveal-from-top" data-reveal-delay="500">
                                    <button onClick={()=>getPartePDF(pdfLink)} className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white text-2xl " >
                                        Descarga el pdf
                                    </button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SendedParte;
