import {updateNotification} from "../../../store/notificationStore";
import Notification from "../../../lib/common/models/notification";
import Notifications from "../../../lib/notifications";
import SocketConn from "../../../lib/SocketConn";
import NotificationApi from "../../../api/NotificationApi";
import ApiCaller from "../../../lib/ApiCaller";

export interface INotificationView {
    dispatch: Function;
    notify: Function;
}

export interface INotificationEvent {
    onMessage: Function;
    onUserIn: Function;
}

class NotificationPresenter implements INotificationEvent {

    loggedUser: any;
    socketConn: any;
    view: any;
    notifyApi:NotificationApi;

    constructor(profile: any, view:INotificationView) {
        this.loggedUser = profile;
        this.view = view;
        this.socketConn = null; //new SocketConn(profile, this);
        this.notifyApi = new NotificationApi(new ApiCaller(profile.token));
    }

    onMessage(message: any){
        this.view.notify(message);
        this.notify(message);
    };

    onUserIn(){

    };

    notify(msg: string) {
        let n = {id:new Date().getUTCDate(), code: 'sample', title: msg, createdAt: new Date().toTimeString(), createdBy: this.loggedUser.name };
        let newNotification = Notification.prototype.load(n);
        this.view.dispatch(updateNotification(newNotification));
        Notifications.addNotification(newNotification);
    }


}

export default NotificationPresenter;
