import axios from "axios";

export interface IImageView {
    setUploadState: Function;
    alertSuccess: Function;
    alertError: Function;
}

class UploadImagePresenter {

    loggedUser: any;
    view: any;

    constructor(profile: any, view:IImageView) {
        this.loggedUser = profile;
        this.view = view;
    }

    upload(uploadOptions:any, questionName: string) {
        const { onSuccess, onError, file } = uploadOptions;
        const data = new FormData()
        data.append('file', file);
        data.append('type','PARTE_IMAGE');
        data.append('uid',file.uid);
        data.append('questionName',questionName);

        axios.post("/api/v1/parte/add-resource", data, {
            onUploadProgress: (ProgressEvent:any) => {
                this.view.setUploadState({
                    selectedFile: [file.name],
                    loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                })
            },
        })
            .then((res:any) => { // then print response status
                this.view.alertSuccess(res.data.fileHash);
                onSuccess(res.data);
            })
            .catch((err:any) => { // then print response status
                this.view.alertError('upload fail')
                onError(err);
            });
    }


}

export default UploadImagePresenter;
