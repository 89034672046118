import React, {useEffect, useState} from 'react';
import PADTilesCard from "./PADTilesCard";
import AssuranceApi from "../../api/AssuranceApi";
import ApiCaller from "../../lib/ApiCaller";
import {formatDate} from "../../shared/utils/dateTime";
import moment from "moment";

const PisosADiasContactos = () =>{

    const [we, SetWe] = useState<any>({})

  useEffect(()=>{
      var legalAPI = new AssuranceApi(new ApiCaller());
      legalAPI.getPisosStruct().then((item) => {
          SetWe(item);
      }).catch(err => {
          console.error(err);
      });
  },[]);

    const wer = (y:any,week:any) => {
        var d = new Date("Jan 01, "+y+" 01:00:00");
        var w = d.getTime() + 604800000 * (week-1);
        return formatDate(new Date(w));
    }
  return (
      <>
        <div className="flex-grow" id="to-print-pdf">
          <div className="p-6 space-y-6">
            <section>
                { Object.keys(we).map((w:any) => <><PADTilesCard
                    key={1}
                    id={50}
                    name={`Setmana ${moment(new Date(w)).week()} - ${moment(new Date(w)).format('D/M/YYYY')}`}
                    link={`https://smartcountry.io/api/v1/pad/${moment(new Date(w)).year()}/${moment(new Date(w)).week()}/${+moment(new Date(w)).week()+1}`}
                    location={we[w].map((i:any)=>i.place).join(", ")}
                    content={`${we[w].length} allotjaments`}
                /><br/></>)}
            </section>
          </div>
        </div>

      </>
      );
}

export default PisosADiasContactos;
