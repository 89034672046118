import React, {useEffect, useState} from 'react';

import {AppState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {generateErrors, is} from "../../shared/utils/validation";
import {useHistory} from "react-router";
import Tooltip from "../../components/Tooltip";

const TestingCrowd = () => {

  //const projectCurrent = useSelector((state: AppState) => state.current.doc);
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [crowdtestData, setCrowdtestData] = useState<any>({});
  const [errors,setErrors] = useState<any>({});
  const history = useHistory();

  useEffect(()=>{
    setCrowdtestData({testers: 10, agents:['google chrome', 'windows edge', 'safari'], testersCountry:'Spain', intro:''});
  },[]);

  const updatePCrowdtestData = (e: React.FormEvent) => {
    e.preventDefault();
    if (loggedUser) {
      const testers = crowdtestData.testers;
      const errors: any = generateErrors({ testers }, { testers: [is.required()] })
      history.push('/testing-projects');
    }
  }

  const latestAgents = [
    "Latest Chrome user agents",
    "Latest Firefox user agents",
    "Latest Safari user agents",
    "Latest Internet-Explorer user agents",
    "Latest Edge user agents",
    "Latest Opera user agents"
  ]
  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">CrowdTesting details</h2>
        {/* Business Profile */}
        <section>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/3">
              <div className="flex items-center space-x-2">
                <label className="block text-sm font-medium mb-1" htmlFor="testers">Number of testers per iteration</label>
                <Tooltip size="" bg="dark" position="right">
                  <div className="text-xs text-gray-200 whitespace-nowrap">Every report will have this number of tests executed</div>
                </Tooltip>
              </div>

              <input id="testers" className="form-input w-full" type="text" onChange={e => setCrowdtestData({...crowdtestData, testers:e.target.value})} value={crowdtestData.testers}/>
            </div>
            <div className="sm:w-2/3">
              <div className="flex items-center space-x-2">
                <label className="block text-sm font-medium mb-1" htmlFor="testersCountry">Country</label>
                <Tooltip size="" bg="dark" position="right">
                  <div className="text-xs text-gray-200 whitespace-nowrap">The native country or place for the testers</div>
                </Tooltip>
              </div>

              <input id="testersCountry" className="form-input w-full" type="text" onChange={e => setCrowdtestData({...crowdtestData, testersCountry:e.target.value})} value={crowdtestData.testersCountry}/>
            </div>
          </div>
        </section>
        {/* Email */}
        <section>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/2">
              <div className="flex items-center space-x-2">
                <label className="block text-sm font-medium mb-1" htmlFor="agents">Browser agents</label>
                <Tooltip size="" bg="dark" position="right">
                  <div className="text-xs text-gray-200 whitespace-nowrap">What browsers do you want to be tested more?</div>
                </Tooltip>
              </div>
              <select id="agents" className={`form-input w-full px-2 py-1 `} onChange={(e)=> setCrowdtestData({...crowdtestData, agents:e.target.value})}>
                { latestAgents.map(a=><option value={a.split(' ')[1].toUpperCase()}>{a}</option>)}
              </select>
            </div>
          </div>
        </section>
        <section>
          <div>
            <div className="flex items-center space-x-2">
              <label className="block text-sm font-medium mb-1" htmlFor="feedback">Intro</label>
              <Tooltip size="" bg="dark" position="right">
                <div className="text-xs text-gray-200 whitespace-nowrap">
                  The manual testers will see this message when the test starts<br/>
                  If you want to take into consideration any aspect to be tested,<br/>
                  that is the place to inform about it
                </div>
              </Tooltip>
            </div>
            <textarea id="feedback" className={`form-textarea w-full px-2 py-1 ${errors.intro && 'border-red-300'}`} rows={4} required
                      value={crowdtestData.intro} onChange={(e)=> setCrowdtestData({...crowdtestData, intro:e.target.value})}></textarea>
            {errors.description && <div className="text-xs mt-1 text-red-500">This field is required!</div>}
          </div>
        </section>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn border-gray-200 hover:border-gray-300 text-gray-600">Cancel</button>
            <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white ml-3" onClick={updatePCrowdtestData}>Save Changes</button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default TestingCrowd;
