import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import {Link} from "react-router-dom";

import heroimagetop from "./../../images/hero-image-top.png"
import heromedia from "./../../images/hero-media.png"

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroFull extends React.Component {

  render() {
    const {className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, ...props} = this.props;

    const outerClasses = classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);

    const innerClasses = classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              <h1 className="title-smart text-6xl md:text-6xl text-smartCountry-50 font-bold mb-1" data-reveal-delay="300">
              IA simplificada para su negocio.
              </h1>
              <div className="container-s">
                <p className="sub-title-smart text-4xl md:text-3xl text-smartCountry-51 mt-10 mb-10  reveal-from-top" data-reveal-delay="400">
                Gestione las comunicaciones de sus clientes,<br/>
                automatización e inteligencia empresarial en una única plataforma
                </p>
                <div className="reveal-from-top" data-reveal-delay="500">
                  <Link to="/contact/" tag="a" className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-nexoGreen-50 text-1xl " >
                  SOLICITE UNA DEMOSTRACIÓN
                  </Link>
                </div>
              </div>
            </div>
            <div className="hero-figure reveal-from-top" data-reveal-delay="500">
              <Image
                className="has-shadow"
                src={heromedia}
                width={1000}
                height={504}
                alt="Hero" />

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: 'absolute',
  left: '-5.9%',
  right: '5.9%',
  top: '-12.74%'
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
