import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import SectionSubHeader from './partials/SectionSubHeader';
import Image from '../elements/Image';

import featuresSplitImage1 from './../../images/features/features-split-image-01.png';
 {/* import featuresSplitImageTop1 from './../../images/features-split-top-01.png'; */}
import featuresSplitImage2 from './../../images/features/features-split-image-02.png';
 {/* import featuresSplitImageTop2 from './../../images/features-split-top-02.png'; */}
import featuresSplitImage3 from './../../images/features/features-split-image-03.png';
 {/* import featuresSplitImageTop3 from './../../images/features-split-top-03.png'; */}

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, invertMobile, invertDesktop, alignTop, imageFill, ...props} = this.props;

    const outerClasses = classNames('features-split section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);
    const innerClasses = classNames('features-split-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');
    const splitClasses = classNames('split-wrap', invertMobile && 'invert-mobile', invertDesktop && 'invert-desktop', alignTop && 'align-top');

    const sectionHeader = {
      title: 'La experiencia interconectada',
      paragraph: 'La integración de sus canales de comunicación aumenta la resolución del primer contacto, mejora la experiencia del cliente y reduce la necesidad de agentes activos.'

    };

    const sectionSubHeader = {
      subtitle: 'La nueva forma de hacer negocios',
      paragraph: 'Nuestro mundo se está volviendo cada vez más digital y es importante mantenerse al día con las expectativas actuales de los clientes.'

    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

         {/* start split 01 */}

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50" data-reveal-delay="200">
                  Llamar a mensajería
                  </h3>
                  <p className="m-0 text-4xl md:text-lg text-smartCountry-51 text-smart" data-reveal-delay="400">
                    Envíe automáticamente a las personas que llaman un SMS o un correo electrónico con información no adecuada para una llamada, que incluye:
                    <ul className='mt-5 '>
                    <li>• Verificación de direcció</li>
                    <li>• Números de seguimiento</li>
                    <li>• Recibos de pago</li>
                    </ul>
                  </p>
                </div>
                <div className={
                   classNames('split-item-image center-content-mobile illustration-element-02 reveal-from-top', imageFill && 'split-item-image-fill')}>
                  <Image
                  className="has-shadow"
                  src={featuresSplitImage1}
                  alt="Features split 01"
                  width={528}
                  height={396} />
                </div>
              </div>
        {/* start split 02 */}
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 reveal-from-right" data-reveal-delay="200">
                   IVR compatible con la Web
                  </h3>
                  <p className="m-0  text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-right" data-reveal-delay="400">
                    Muchas personas que llaman ya estaban en su sitio web cuando se comunicaron. Web Aware IVR conserva el contenido explorado, lo que significa:
                    <ul className='mt-5 '>
                    <li>• No es necesario repetir la información</li>
                    <li>• Resolución rápida de problemas y finalización de transacciones</li>
                    <li>• Llamadas más cortas y transferencias reducidas</li>
                  </ul>
                  </p>
                </div>
                <div className={
                   classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                  className="has-shadow"
                  src={featuresSplitImage2}
                  alt="Features split 02"
                  width={528}
                  height={396} />
                </div>
              </div>
         {/* start split 03 */}
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 reveal-from-left" data-reveal-delay="200">
                   Voz a SMS y correo electrónico
                  </h3>
                  <p className="m-0 text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                  Solicite a los usuarios del asistente de voz manos libres una dirección de correo electrónico, donde puede enviar automáticamente información o promociones relevantes. Esto aumenta la finalización de la compra y crea su base de datos.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={featuresSplitImage3}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>

       {/* end section split 01 */}

            < div className={innerClasses}>
               <SectionSubHeader data={sectionSubHeader} className="center-content" />
              <div className={splitClasses}>

       {/* start split 04 */}
           <div className="split-item mt-20">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 reveal-from-left" data-reveal-delay="200">
                  Comercio conversacional
                  </h3>
                  <p className="mt-6 text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                  Brinde a los clientes la posibilidad de realizar compras de manera conversacional a través del canal que elijan, ya sea que estén en casa o en movimiento.
                  </p>
                  <p className="mt-6  text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                  Los canales interconectados permiten el almacenamiento y la confirmación por escrito de las compras a través de SMS o correo electrónico.
                  </p>
                  <p className="mt-6 text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                  Toda nueva identificación de usuario, una situación única impulsada por VoiceAuth0, transforma a los asistentes de voz en un canal eficaz.
                </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={featuresSplitImage3}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>

         {/* start split 05 */}
             <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 reveal-from-right" data-reveal-delay="200">
                      Geolocalización sin aplicación
                     </h3>
                     <p className="mt-6  text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                     Solicitar automáticamente ubicaciones de clientes en determinadas situaciones predeterminadas.
                    </p>
                    <p className="mt-6   text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                    Esencial para coordinar entregas, solicitudes y más, así como para brindar servicios a medida.
                    </p>    
                  <p className="mt-6  text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-right" data-reveal-delay="400">
                  Integrations:
                    <ul className='mt-0 '>
                    <li>• Respuesta virtual a llamadas: los usuarios reciben una confirmación verbal que les permite acceder a la ubicación</li>
                    <li>• Servicios de texto: los usuarios reciben un enlace que permite el acceso a la ubicación</li>
                    <li>• BLE Beacons: para geolocalización en interiores</li>
                  </ul>
                  </p>
                </div>
                <div className={
                   classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                  className="has-shadow"
                  src={featuresSplitImage2}
                  alt="Features split 02"
                  width={528}
                  height={396} />
                </div>
              </div>

           { /* start split 06 */}
            <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-5 sub-title-smart text-4xl md:text-2xl text-smartCountry-50 reveal-from-left" data-reveal-delay="200">
                  Control de IoT conversacional desde cualquier lugar
                  </h3>
                  <p className="m-6  text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                  Los usuarios pueden administrar la configuración en la habitación a través de chatbot desde cualquier canal:
                  <ul className='mt-5 '>
                    <li>• Todos los canales de texto</li>
                    <li>• Contestación de llamadas virtual</li>
                    <li>• Búsqueda por voz</li>
                  </ul>
                  </p>
                  <p className="m-6  text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                  Control ambiental total de la configuración de la habitación, como termostatos, iluminación, persianas, entretenimiento y más.
                </p>
                  <p className="m-6  text-4xl md:text-lg text-smartCountry-50 text-smartCountry-51 text-smart reveal-from-left" data-reveal-delay="400">
                  La mejor manera de ser ecológico y mantenerse actualizado, al tiempo que brinda control y comodidad personalizados.
               </p> 
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={featuresSplitImage3}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const img01Style = {
  position: 'absolute',
  bottom: '-12.4%'
}

const img02Style = {
  position: 'absolute',
  bottom: '-13.1%'
}

const img03Style = {
  position: 'absolute',
  bottom: '-16.1%',
  left: '-10.1%',
  right: '10.1%'
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
