import React from 'react';

import Settings from '../../lib/settings';
import { RouteComponentProps, useHistory } from 'react-router';
import AuthApi from '../../api/AuthApi';
import ApiCaller from '../../lib/ApiCaller';
import User from '../../lib/common/models/user';
import { SessionState, updateSession } from '../../store/sessionStore';
import { useDispatch } from 'react-redux';
// @ts-ignore
import registered from "../../images/testing_usabilidad.svg";
import {Link} from "react-router-dom";
import UserApi from "../../api/UserApi";

interface ContainerProps extends RouteComponentProps<{
  email: string;
  token: string;
}> {};

const Verify: React.FC<ContainerProps> = ({match}) => {
  var authApi = new AuthApi(new ApiCaller());
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const token = match.params.token;
    const email = match.params.email;
    authApi.verify(email, token).then((data:any) => {
        let user = new User({
            name: data.name,
            email: data.email,
            token: data.token,
            type: data.type,
            status: data.status
        });

      Settings.setCurrentUser(user).then(() => {
          const userApi = new UserApi(new ApiCaller());
          const url = userApi.getAvatarUrl(user.id || '');
          const sessionData:SessionState = {
              loggedIn: user.email? true : false,
              ready: true,
              userInfo: user,
              type: user.type,
              layout: user.type === 'admin' ? 'nextest' : user.type,
              avatar: url
          };
            dispatch(updateSession(sessionData));
          history.push("/"+user.type);
      });
    }).catch (err => {
      alert(err);
    });
  }, []);

    const sectionHeader = {
        title: 'Cuenta verificada con éxito',
        paragraph: 'Ya puedes usar Smartcountry :) Nos pondremos en contacto contigo para hacerte un tour y enseñarte que podemos hacer por ti'
    };


    return (
      <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

              <div className="max-w-2xl m-auto mt-16">

                  <div className="text-center px-4">
                      <div className="inline-flex mb-8">
                          <img src={registered} width="300" height="176" alt="Registered" />
                      </div>
                      <p className="m-0 sub-title-smart text-4xl md:text-2xl text-smartCountry-50">{sectionHeader.paragraph}</p>
                      <Link to="/" className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white">Volver al inicio</Link>
                  </div>

              </div>

          </div>
      </main>
  );
};

export default Verify;
