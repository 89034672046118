import React, {useRef, useState} from 'react';

import Tooltip from '../Tooltip';

import {
    ActionButton,
    Actions,
    FormElement,
    FormHeading,
    SelectItem, SelectItemLabel
} from "./Styles";
import { Form, IssueTypeIcon} from '../../../components/added';
import AppointmentApi from "../../../api/AppointmentApi";
import ApiCaller from "../../../lib/ApiCaller";
import toast from "../../../shared/utils/toast";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/store";

interface myprops {
    placement:string;
    offset:any;
    renderLink:Function;
    onCreate: Function,
    onClose:Function;
}
const AskALaywerTooltip:React.FC<myprops> = ({placement,offset,renderLink, onCreate, onClose}) => {
    const tooltipProps:any={}
    tooltipProps.placement=placement;
    tooltipProps.offset=offset;
    tooltipProps.renderLink=renderLink;
    const currentUser = useSelector((state: AppState) => state.session.userInfo);
    const [modelOptions,sModelOptions] = useState<any[]>([
        {value: 'Funcionamiento-Servicio', label: 'Una duda sobre el funcionamiento del servicio'},
        {value: 'Demo-request', label: 'Agendar una demo'},
        {value: 'Chat-Bot', label: 'Información sobre las opciones en contestación automática'},
        {value: 'Pricing', label: 'Tarifas y subscripciones'},
        {value: 'Equipo', label: 'Te gustaría formar parte de nuestro equipo'},
    ]);
    const [isCreating,sIsCreating] = useState(false);

    const renderSelectModel = (type:any) => (
        <SelectItem key={type.label}>
            <IssueTypeIcon type={'task'} top={1} />
            <SelectItemLabel>{type.label}</SelectItemLabel>
        </SelectItem>
    );
    const renderSelectModelValue = (type:any) => {
        return <SelectItem key={type.value}>
            <IssueTypeIcon type={'task'} top={1}/>
            <SelectItemLabel>{type.value}</SelectItemLabel>
        </SelectItem>
    }

    return (<Tooltip
        width={600}
        {...tooltipProps}
        renderContent={() => (
                <Form enableReinitialize validate={() => {
                }} initialValues={{}} validations={{}} onSubmit={async (values: any, form: any) => {
                    try {
                        sIsCreating(true);
                        const api = new AppointmentApi(new ApiCaller());
                        const userId = currentUser && currentUser.id ? currentUser.id : 'anonymous'
                        await api.sendRequest({type:values.type, name:values.name, request:values.request, email:values.email},userId).then((resp:any) => {
                            sIsCreating(false);
                            onCreate(resp.ticket)
                        }).catch(err => {
                            console.error(err);
                        });
                    } catch (error) {
                        Form.handleAPIError(error, form);
                    }
                }}>
                    <FormElement>

                        <FormHeading className={'sub-title-smart text-4xl md:text-2xl text-smartCountry-50'}>Te respondemos en seguida</FormHeading>
                        <Form.Field.Select
                            name="type"
                            label="Categoría de la consulta"
                            tip="Cuéntanos que tipo de consulta quieres"
                            options={modelOptions}
                            renderOption={renderSelectModel}
                            renderValue={renderSelectModelValue}
                            onChange={(e: any) => {
                                console.log(e.target.value)
                            }}
                        />

                        <Form.Field.Input
                            name="name"
                            label="Nombre Completo"
                        />

                        <Form.Field.Input
                            name="email"
                            label="Tu email"
                            tip="Solamente lo vamos a usar para ponernos en contacto sobre esta consulta"
                        />

                        <Form.Field.Textarea
                            name="request"
                            label="Consulta"
                        />

                        <Actions>
                            {
                              // @ts-ignore
                              <ActionButton type="submit" variant="primary" isWorking={isCreating}>
                                Enviar Consulta
                              </ActionButton> }
                            {
                                // @ts-ignore
                                <ActionButton type="button" variant="empty" onClick={()=>{onClose()}}>
                                    Cancelar
                                </ActionButton>}

                        </Actions>
                    </FormElement>
                </Form>

        )}
    />)
};

export default AskALaywerTooltip;
