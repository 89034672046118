import React, { useState } from 'react';
import {AppState} from "../../store/store";
import {useSelector} from "react-redux";
import ReactGherkinEditor from '@smartbear/react-gherkin-editor'


function TestingPlan() {

  const [sync, setSync] = useState(false);
  const project = useSelector((state: AppState) => state.current.doc);

  const initialValue = `
    {
        text: 'Has tenido un accidente y necesitas hacer un parte ? ',
        type: 'boolean',
        var: 'hasAccidente'
    },
    {
        text: 'Cual es el nombre del conductor ?',
        type: 'text',
        var: 'conductorName'
    },
    {
        text: 'Podrias indicar la matrícula del vehículo ? ',
        type: 'text',
        var: 'conductorPlacainen'
    },
    {
        text: 'Explica brevemente lo que ha sucedido ',
        type: 'text',
        var: 'sucesoDescription'
    },
    {
        text: 'Vas a necesitar que te enviemos una grua ? ',
        type: 'boolean',
        var: 'hasAsistencia'
    }
        `

  const steps = [
      'Verify error message "message"',
      'I log out from the application',
      'Message "message" should be displayed',
      'Search for word "word"',
      'The browser is open',
  ]

  const autoCompleteFunction = async (_keyword:string, text:string) => {
    const matches = steps.filter(step => step.startsWith(text))

    const completions = matches.map(match => ({
      caption: match,
      value: match,
      score: Math.floor(Math.random() * Math.floor(100)),
      meta: 'Step'
    }))

    return completions
  }
  const onValueChange = (e:any) => {
    console.log(e)
  }

  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
          <h2 className="text-2xl text-gray-800 font-bold mb-5">Voice questions</h2>
        {/* Business Profile */}
        <section>
          <div className="text-sm">Language</div>
              <ReactGherkinEditor
                  // @ts-ignore
                  initialValue={initialValue}
                  onChange={onValueChange}
                  autoCompleteFunction={autoCompleteFunction}
                  language='en'
                  theme='cucumber'
              />
        </section>
      </div>
      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn border-gray-200 hover:border-gray-300 text-gray-600">Cancel</button>
            <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white ml-3">Save Changes</button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default TestingPlan;
