import React from 'react';
import SettingsBase from "./SettingsBase";
import AccountPanel from '../../../partials/settings/AccountPanel';
import AppsPanel from "../../../partials/settings/AppsPanel";
import BillingPanel from "../../../partials/settings/BillingPanel";
import FeedbackPanel from "../../../partials/settings/FeedbackPanel";
import NotificationsPanel from "../../../partials/settings/NotificationsPanel";
import PlansPanel from "../../../partials/settings/PlansPanel";

export const SettingsAccount = () => <SettingsBase><AccountPanel/></SettingsBase>
export const SettingsApps = () => <SettingsBase><AppsPanel/></SettingsBase>
export const SettingsBilling = () => <SettingsBase><BillingPanel/></SettingsBase>
export const SettingsFeedback = () => <SettingsBase><FeedbackPanel/></SettingsBase>
export const SettingsNotifications = () => <SettingsBase><NotificationsPanel/></SettingsBase>
export const SettingsPlans = () => <SettingsBase><PlansPanel/></SettingsBase>
