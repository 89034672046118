import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import 'react-toastify/dist/ReactToastify.css';
import {  ToastContainer } from 'react-toastify';

const LayoutDefault = ({ children }) => (
    <div>

        <div className="overflow-y-auto overflow-x-hidden">

        <main>

                <div className="">
                        <Header navPosition="right" />
                        {children}
                </div>
        </main>
        </div>
        <Footer />
        <ToastContainer/>
    </div>
);
export default LayoutDefault;
