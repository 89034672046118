import React from 'react';
import BarChart from '../../charts/BarChart04';
import {tailwindConfig, prepareContactTotalWeekWidget} from '../../utils/Utils';
import Info from "../Info";

function DashboardCard04a(props) {

  const {adults,kids,bebes,labels} = prepareContactTotalWeekWidget(props.builds);

  const chartData = {
    labels: labels,
    datasets: [
      // Light blue bars
      {
        label: 'Adultos',
        data: adults,
        backgroundColor: tailwindConfig().theme.colors.blue[400],
        hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
        barPercentage: 0.66, categoryPercentage: 0.66,
      },
      // Blue bars
      {
        label: '+16',
        data: kids,
        backgroundColor: tailwindConfig().theme.colors.red[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.red[600],
        barPercentage: 0.66, categoryPercentage: 0.66,
      },
      // Blue bars
      {
        label: 'Bebes',
        data: bebes,
        backgroundColor: tailwindConfig().theme.colors.pink[800],
        hoverBackgroundColor: tailwindConfig().theme.colors.pink[900],
        barPercentage: 0.66, categoryPercentage: 0.66,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200 mb-6">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Total de viajeros</h2>
        <Info className="ml-2" containerClassName="min-w-80">
          <div className="text-sm">Número de viajeros que entran por semana</div>
        </Info>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <BarChart data={chartData} width={595} height={248} />
    </div>
  );
}

export default DashboardCard04a;
