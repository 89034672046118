import React, { useState } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import SearchForm from '../../partials/actions/SearchForm';
import JenkinsTable from "../../partials/invoices/JenkinsTable";
import DashboardCard04 from "../../partials/dashboard/DashboardCard04";
import DashboardCard04a from "../../partials/dashboard/DashboardCard04a";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {AppState} from "../../store/store";
import DetailIWAQIForm from "./DetailIWAQIForm";

const JenkinsBuild = () => {
    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const history = useHistory();
    const project = useSelector((state: AppState) => state.current.doc);
    const handleSelectedItems = (selectedItems:any[]) => {
        setSelectedItems([...selectedItems]);
    };
    const builds:any[] = useSelector((state: AppState) => state.jenkins.builds);

    const launchBuild = () => {
        alert('not yet implemented');
    }
    const [contact, setContact] = useState(false)
    const [scrollbarModalOpen, setScrollbarModalOpen] = useState(false)

    const handleCustomerReport = (contact:any) => {
        setContact(contact);
        setScrollbarModalOpen(true)
    }
    return (
        <>
            <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="sm:flex sm:justify-between sm:items-center mb-5">

                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                                    <button className="text-gray-400 hover:text-gray-500 rounded-full" onClick={()=>{history.push(`/testing-projects`);}} >
                                        <svg className="flex-shrink-0 h-6 w-6 mr-4" viewBox="0 0 24 24">
                                            <path className={`fill-current text-gray-400 text-nexoGreen-300`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                                            <path className={`fill-current text-gray-700 !text-nexoGreen-600`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                                            <path className={`fill-current text-gray-600 text-nexoGreen-500`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
                                        </svg>
                                    </button>
                                    Processed Calls <i className="text text-gray-400 italic">[ {project.name} ]</i> ✨
                                </h1>
                            </div>

                            {/* Right: Actions */}
                            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                                {/* Search form */}
                                <SearchForm placeholder="Search by invoice ID…" />
                                {/* Add member button */}
                                <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" onClick={()=>launchBuild()}>
                                    <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                    </svg>
                                    <span className="hidden xs:block ml-2">Make a call</span>
                                </button>
                            </div>

                        </div>

                        <div className="grid grid-cols-12 gap-6">
                            { false && <DashboardCard04 builds={builds}/> }
                            { false && <DashboardCard04a builds={builds}/> }
                        </div>

                        {/* Table */}
                        <JenkinsTable selectedItems={handleSelectedItems} showCustomerReport={handleCustomerReport}/>
                    </div>
                </main>

            </div>

        </div>
            <DetailIWAQIForm feedbackModalOpen={scrollbarModalOpen} setFeedbackModalOpen={setScrollbarModalOpen} contact={contact}/>
        </>
    );
}

export default JenkinsBuild;
