import React from 'react';
import Roadmap from '../../components/sections/Roadmap';
import Cta from '../../components/sections/Cta';
// import some required elements
import HeroTeam from "../../components/sections/HeroTeam";

class TeamPage extends React.Component {

  state = {
    demoModalActive: false,
    videoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Buttons - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection02Header = {
      title: 'FAQ - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection03Header = {
      title: 'Input forms - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection04Header = {
      title: 'Modal - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection05Header = {
      title: 'Video - Lorem ipsum is placeholder text commonly used.'
    }

    return (
      <React.Fragment>
        <HeroTeam className="illustration-section-01" />
        <Roadmap topDivider />
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default TeamPage;
