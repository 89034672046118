import React, {useState} from 'react';
import {formatDateTimeConversational, getParsedDate} from "../../shared/utils/dateTime";
import WhatsappChatForm from "./WhatsappChatForm";
import Info from "../Info";

const iconBandeja=<svg className="w-9 h-9 fill-current text-red-50" viewBox="0 0 36 36"><path d="M25 24H11a1 1 0 01-1-1v-5h2v4h12v-4h2v5a1 1 0 01-1 1zM14 13h8v2h-8z" /></svg>
const iconMensaje=<svg className="w-9 h-9 fill-current text-nexoGreen-50" viewBox="0 0 36 36"><path d="M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L18.9 22H18c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" /></svg>
const iconFletxer=<svg className="w-9 h-9 fill-current text-nexoGreen-50" viewBox="0 0 36 36"><path d="M15 13v-3l-5 4 5 4v-3h8a1 1 0 000-2h-8zM21 21h-8a1 1 0 000 2h8v3l5-4-5-4v3z" /></svg>
const iconCruse=<svg className="w-9 h-9 fill-current text-light-blue-50" viewBox="0 0 36 36"><path d="M23 11v2.085c-2.841.401-4.41 2.462-5.8 4.315-1.449 1.932-2.7 3.6-5.2 3.6h-1v2h1c3.5 0 5.253-2.338 6.8-4.4 1.449-1.932 2.7-3.6 5.2-3.6h3l-4-4zM15.406 16.455c.066-.087.125-.162.194-.254.314-.419.656-.872 1.033-1.33C15.475 13.802 14.038 13 12 13h-1v2h1c1.471 0 2.505.586 3.406 1.455zM24 21c-1.471 0-2.505-.586-3.406-1.455-.066.087-.125.162-.194.254-.316.422-.656.873-1.028 1.328.959.878 2.108 1.573 3.628 1.788V25l4-4h-3z"/></svg>
export const backFletcher=(color)=><svg className={`w-9 h-9 fill-current ${color||'text-red-50'}`} viewBox="0 0 36 36"><path d="M17.7 24.7l1.4-1.4-4.3-4.3H25v-2H14.8l4.3-4.3-1.4-1.4L11 18z" /></svg>
export const forwardFletcher=(color)=><svg className={`w-9 h-9 fill-current ${color||'text-nexoGreen-50'}`} viewBox="0 0 36 36"><path d="M18.3 11.3l-1.4 1.4 4.3 4.3H11v2h10.2l-4.3 4.3 1.4 1.4L25 18z" /></svg>
const iconForbbiden=<svg className="w-9 h-9 fill-current text-red-100" viewBox="0 0 36 36"><path d="M21.477 22.89l-8.368-8.367a6 6 0 008.367 8.367zm1.414-1.413a6 6 0 00-8.367-8.367l8.367 8.367zM18 26a8 8 0 110-16 8 8 0 010 16z" /></svg>
const iconSpot=<svg className="w-9 h-9 fill-current text-indigo-50" viewBox="0 0 36 36"><circle cx="18" cy="18" r="3"></circle><path d="M13.05 24.363l-.707-.707a8 8 0 010-11.312l.707-.707 1.414 1.414-.707.707a6 6 0 000 8.484l.707.707-1.414 1.414zM22.95 24.363l-1.414-1.414.707-.707a6 6 0 000-8.484l-.707-.707 1.414-1.414.707.707a8 8 0 010 11.312l-.707.707z"/></svg>
function DashboardCard10(props) {

  const koms = props.koms.reduce((a, x) => {
    const date = x['updatedAt'].substring(0,10);
    (a[date] = a[date] || []).push(x);
    return a;
  }, {})

  const getIcon = (type) => {
    switch (type){
      case 'INFO_SMS_SENDED':return iconMensaje;
      case 'ONBOARD_WHATSAPP_SENDED':return iconMensaje;
      case 'ROAT-FORM-COMPLETE_SENDED':return iconCruse;
      case 'ROAT-FORM-COMPLETE':return iconFletxer;
      case 'FAREWELL_EMAIL_SENDED':return backFletcher;
      case 'ONBOARD_EMAIL_SENDED':return forwardFletcher;
      case 'ROAT-WARNING_SENDED':return iconForbbiden;
      case 'PAYLINK-WARNING_SENDED':return iconForbbiden;
      case 'PDF_WHATSAPP_SENDED':return iconSpot;
      case 'ASK_ENTRADA_SENDED':return forwardFletcher('text-indigo-50');
      case 'ASK_SALIDA_SENDED':return backFletcher('text-indigo-50');
      default:
        return iconBandeja;
    }
  }

  const getColor = (type) => {
    switch (type){
      case 'INFO_SMS_SENDED':return 'bg-light-blue-500';
      case 'ONBOARD_WHATSAPP_SENDED':return 'bg-green-500';
      case 'ROAT-FORM-COMPLETE_SENDED':return 'bg-indigo-500';
      case 'ROAT-FORM-COMPLETE':return 'bg-green-500';
      case 'FAREWELL_EMAIL_SENDED':return 'bg-blue-500';
      case 'ONBOARD_EMAIL_SENDED':return 'bg-green-500';
      case 'ROAT-WARNING_SENDED':return 'bg-red-900';
      case 'PAYLINK-WARNING_SENDED':return 'bg-red-900';
      case 'PDF_WHATSAPP_SENDED':return 'bg-red-500';
      case 'ASK_ENTRADA_SENDED':return 'bg-red-500';
      case 'ASK_SALIDA_SENDED':return 'bg-green-500';
      default:
        return 'bg-nexoGreen-500';
    }
  }
  const getPrettyType = (type) => {
    switch (type){
      case 'INFO_SMS_SENDED':return 'SMS';
      case 'ONBOARD_WHATSAPP_SENDED':return 'Onboard Whatsapp';
      case 'ROAT-FORM-COMPLETE_SENDED':return 'Paylink enviado';
      case 'ROAT-FORM-COMPLETE':return 'ROAT Completado';
      case 'FAREWELL_EMAIL_SENDED':return 'Email Despedida';
      case 'ONBOARD_EMAIL_SENDED':return 'Email Onboard';
      case 'ROAT-WARNING_SENDED':return '[AVISO] ROAT';
      case 'PAYLINK-WARNING_SENDED':return '[AVISO] PAYLINK';
      case 'PDF_WHATSAPP_SENDED':return 'PDF Instrucciones';
      case 'ASK_ENTRADA_SENDED':return 'Request Entrada';
      case 'ASK_SALIDA_SENDED':return 'Request Salida';
      default:
        return type.startsWith('ROAT') ? 'ROAT' : type.replace('_',' ');
    }
  }

  const item = (k,i) => {
    return <li key={`c10_${i}`} className="flex px-2">
      <div className={`w-9 h-9 rounded-full flex-shrink-0 ${getColor(k.type)} my-2 mr-3`}>
        {getIcon(k.type)}
      </div>
      <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2">
        <div className="flex-grow flex justify-between">
          <div className="self-center">
            <a className="font-medium text-gray-800 hover:text-gray-900" href="#0">{` ${getPrettyType(k.type)} `}</a>
            {`[ ${k.payload.place} ]`}
            <a className="text-sm text-gray-800 hover:text-gray-900" href="#0">{` ( ${k.payload.name} )`}</a></div>
          <div className="flex-shrink-0 self-end ml-2">
            <button className="font-medium text-nexoGreen-500 hover:text-nexoGreen-600" onClick={(e) => { e.stopPropagation();setFeedbackModalOpen(true)}}>
              <span className="hidden sm:inline"> -&gt;</span></button>
          </div>
        </div>
      </div>
    </li>
  }

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  return (
    <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Actividad reciente</h2>
        <Info className="ml-2" containerClassName="min-w-80">
          <div className="text-sm">Visiona aquí las comnicaciónes automáticas con los viajeros</div>
        </Info>
      </header>

      <div className="p-3">

        {/* Card content */}
        {/* "Today" group */}
        <div>
          <header className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2">
            { Object.keys(koms).length>0 && formatDateTimeConversational(Object.keys(koms)[0])}
          </header>
          <ul className="my-1">
            { Object.keys(koms).length>0 && koms[Object.keys(koms)[0]].map((k,i) =>item(k,`a_${i}`)) }
          </ul>
        </div>
        {/* "Yesterday" group */}
        <div>
          <header className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2">
            { Object.keys(koms).length>1 && formatDateTimeConversational(Object.keys(koms)[1])}
          </header>
          <ul className="my-1">
            { Object.keys(koms).length>1 && koms[Object.keys(koms)[1]].map((k,i) =>item(k,`b_${i}`)) }
          </ul>
        </div>

      </div>
      {/*<WhatsappChatForm feedbackModalOpen={feedbackModalOpen} setFeedbackModalOpen={setFeedbackModalOpen} msg={sampleMsg}/>*/}
    </div>
  );
}

export default DashboardCard10;
