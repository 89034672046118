import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class HeroFull extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-03 reveal-from-top mt-32',
      pushLeft && 'push-left'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="hero-content center-content">
              <div className="container-xs">
                <h1 className="m-0 reveal-from-top">
                  Seguro que hay una modalidad que se ajusta a tus necesidades
                </h1>
              </div>
            </div>

            <div className={tilesClasses}>

<div className="tiles-item reveal-from-top" data-reveal-delay="200">
  <div className="tiles-item-inner has-shadow">
    <div className="pricing-item-content">
      <div className="pricing-item-header center-content mb-24">
        <div className="pricing-item-title text-xxs fw-500 pb-12">
          Básico
        </div>
      </div>
      <div className="pricing-item-features mb-40">
        <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
          Top features
        </div>
        <ul className="pricing-item-features-list list-reset text-xs">
          <li className="is-checked">10 TESTERS</li>
          <li className="is-checked">INFORME DE RESULTADOS DE TEST </li>
          <li className="is-checked">INFORMES DE BUGS </li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
        </ul>
      </div>
    </div>
    <div className="pricing-item-cta mb-16">
      <Button tag="a" color="secondary" wide href="/es/login">CONSULTAR</Button>
    </div>
  </div>
</div>

<div className="tiles-item reveal-from-top" data-reveal-delay="300">
  <div className="tiles-item-inner has-shadow">
    <div className="pricing-item-content">
      <div className="pricing-item-header center-content mb-24">
        <div className="pricing-item-title text-xxs fw-500 pb-12">
          Profesional
        </div>
      </div>
      <div className="pricing-item-features mb-40">
        <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
          Top features
        </div>
        <ul className="pricing-item-features-list list-reset text-xs">
          <li className="is-checked">20 TESTERS</li>
          <li className="is-checked"> INFORME DE RESULTADOS DE TEST </li>
          <li className="is-checked"> INFORMES DE BUGS </li>
          <li className="is-checked">CONFIRMACIÓN BUG FIX (3 DISPOSITIVOS)</li>
          <li>&nbsp;</li>
        </ul>
      </div>
    </div>
    <div className="pricing-item-cta mb-16">
      <Button tag="a" color="primary" wide href="/es/login">CONSULTAR</Button>
    </div>
  </div>
</div>

<div className="tiles-item reveal-from-top" data-reveal-delay="300">
  <div className="tiles-item-inner has-shadow">
    <div className="pricing-item-content">
      <div className="pricing-item-header center-content mb-24">
        <div className="pricing-item-title text-xxs fw-500 pb-12">
          Enterprise
        </div>
      </div>
      <div className="pricing-item-features mb-40">
        <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
          Top features
        </div>
        <ul className="pricing-item-features-list list-reset text-xs">
          <li className="is-checked">CANTIDAD DE TESTERS PERSONALIZADO</li>
          <li className="is-checked">  NIVEL DE EXPERIENCIA DE TESTER PERSONALIZADO</li>
          <li className="is-checked">INFORME DE RESULTADOS DE TEST</li>
          <li className="is-checked">INFORMES DE BUGS </li>
          <li className="is-checked">CONFIRMACIÓN BUG FIX (PERSONALIZADO)</li>
        </ul>
      </div>
    </div>
    <div className="pricing-item-cta mb-16">
      <Button tag="a" color="secondary" wide href="/es/login">CONSULTAR</Button>
    </div>
  </div>
</div>

</div>

          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
