import React, { useState } from 'react';

import SearchForm from '../../../partials/actions/SearchForm';
import BackofficeBase from "../pagefactory/BackofficeBase";
import PDFTable from "./PDFTable";
import NewContactForm from "../NewContactForm";
import InstruccionesForm from "./InstruccionesForm";

const Instrucciones = () => {
    const [scrollbarModalOpen, setScrollbarModalOpen] = useState(false)
    const [contact, setContact] = useState(false)
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const handleSelectedItems = (selectedItems:any[]) => {
        setSelectedItems([...selectedItems]);
    };

    const handleCustomerReport = (contact:any) => {
        setContact(contact);
        setScrollbarModalOpen(true)
    }

    const Actions = () => <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        <SearchForm placeholder="Search by apartment name.…" />
        <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" aria-controls="feedback-modal" onClick={(e) => { e.stopPropagation(); setFeedbackModalOpen(true); }}>
            <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Nuevo PDF</span>
        </button>
    </div>

    return (<>
        <BackofficeBase actions={<Actions/>} titleHeader={'Instrucciones PDF'}>
            <PDFTable selectedItems={handleSelectedItems} showCustomerReport={handleCustomerReport}/>
        </BackofficeBase>
        <InstruccionesForm feedbackModalOpen={feedbackModalOpen} setFeedbackModalOpen={setFeedbackModalOpen} />
        </>
    );
}

export default Instrucciones;
