import moment from 'moment';

export const formatDate = (date, format = 'MMMM D, YYYY') =>
  date ? moment(date).format(format) : date;

export const formatHour = (date, format = 'h:mm A') =>
    date ? moment(date).format(format) : date;

export const formatDateTime = (date, format = 'MMMM D, YYYY, h:mm A') =>
  date ? moment(date).format(format) : date;

export const formatDateTimeForAPI = date =>
  date
    ? moment(date)
        .utc()
        .format()
    : date;

export const minutesAndSecs = seconds => {
    const format = val => `0${Math.floor(val)}`.slice(-2)
    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    return [hours, minutes, seconds % 60].map(format).join(':')
}
export const formatDateTimeConversational = date => (date ? moment(date).locale('es').fromNow() : date);

export const currentYearWeekKey = () => {
    const c = new Date();
    const fullYear = c.getFullYear();
    var oneJan = new Date(fullYear,0,1);
    var numberOfDays = Math.floor((c - oneJan) / (24 * 60 * 60 * 1000));
    var w = Math.ceil(( c.getDay() + 1 + numberOfDays) / 7);
    var simple = new Date(fullYear, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}
export const getParsedDate = (dateField) => {
    const parts = dateField.length > 9 ? dateField.split(' ')[0].split('/') : dateField.split('/');
    const dateTime = new Date(parts[2].trim(), parts[1].trim() - 1, parts[0].trim());
    return dateTime;
}
