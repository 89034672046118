

//------------  state definition
export interface JenkinsState {
    builds: any[],
    job: any,
    ready: boolean
}

//------------ actions
export const SET_CURRENT_JOB = 'SET_CURRENT_JOB'
export const SET_BUILDS = 'GET_BUILD_LIST'

interface SetJobAction {
  type: typeof SET_CURRENT_JOB,
  payload: any
}

interface GetBuildsAction {
  type: typeof SET_BUILDS,
  payload: any[]
}


export function setCurrentJob(job: any): DocsActionTypes {
    return {
      type: SET_CURRENT_JOB,
      payload: job
    }
}
export function setCurrentBuilds(builds: any[]) : DocsActionTypes  {
    return {
      type: SET_BUILDS,
      payload: builds
    }
}


export type DocsActionTypes = SetJobAction | GetBuildsAction;

//------------ reducer
const initialState: JenkinsState = {
    ready: false,
    job:{},
    builds: []
}

export function jenkinsReducer( state = initialState, action: DocsActionTypes): JenkinsState {
  switch (action.type) {
    case SET_CURRENT_JOB: {
      return {
        ...state,
          job: action.payload,
          ready: true
      }
    }
    case SET_BUILDS: {
      return {
        ...state,
          builds: action.payload,
          ready: true
      }
    }
    default:
      return state
  }
}
