export enum ProjectType {
  GENERAL_PURPOSE = 'GENERAL_PURPOSE',
  SMS = 'SMS',
  VOICE = 'VOICE',
}

interface TestProjectInfo {
  id: string;
  _id: string;
  createdBy: string;
  type: ProjectType;
  name: string;
  expirationDate: Date;
  status: TestProjectStatus;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  platformIds: Array<string>;
  jenkinsJob: string;
  emailContact: string;
  site: string;
}

export enum TestProjectStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

class TestProject {
  id: string;
  createdBy: string;
  type: ProjectType;
  name: string;
  description: string;
  expirationDate: Date;
  status: TestProjectStatus;
  createdAt: Date;
  updatedAt: Date;
  platformIds: Array<string>
  jenkinsJob: string;
  emailContact: string;
  site: string;

  constructor(file: TestProjectInfo|any) {
    this.id = file._id || file.id;
    this.name = file.name;
    this.description = file.description;

    this.createdBy = file.createdBy;
    this.type = file.type;
    this.expirationDate = file.expirationDate;
    this.status = file.status;
    this.createdAt = file.createdAt;
    this.updatedAt = file.updatedAt;
    this.platformIds = file.platformIds;

    this.jenkinsJob = file.jenkinsJob;
    this.emailContact = file.emailContact;
    this.site = file.site;
  }

  toObject(): any {
    return {
      id: this.id,
      name: this.name,
      description: this.description,

      type: this.type,
      createdBy: this.createdBy,

      expirationDate: this.expirationDate,
      status: this.status,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      platformIds: this.platformIds,

      jenkinsJob: this.jenkinsJob,
      emailContact: this.emailContact,
      site: this.site,
    };
  }
  load(data: any): TestProject {
    return new TestProject(data);
  }

}

export default TestProject;
