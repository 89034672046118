import React, { useState } from 'react';

import SearchForm from '../../partials/actions/SearchForm';
import BackofficeBase from "./pagefactory/BackofficeBase";
import ROATTable from "../../partials/pisosadias/ROATTable";

const ROAT = () => {
    const [scrollbarModalOpen, setScrollbarModalOpen] = useState(false)
    const [contact, setContact] = useState(false)
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const handleSelectedItems = (selectedItems:any[]) => {
        setSelectedItems([...selectedItems]);
    };

    const launchBuild = () => {
        alert('not yet implemented');
    }
    const handleCustomerReport = (contact:any) => {
        setContact(contact);
        setScrollbarModalOpen(true)
    }

    const Actions = () => <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        <SearchForm placeholder="Search by invoice ID…" />
    </div>

    return (
            <>
                <BackofficeBase actions={<Actions/>} titleHeader={'ROAT'}>
                    <ROATTable selectedItems={handleSelectedItems} showCustomerReport={handleCustomerReport}/>
                </BackofficeBase>
            </>
    );
}

export default ROAT;
