import React from 'react';
import HeroFull from '../../components/sections/HeroFull';
import Clients from '../../components/sections/Clients';
import CtaManager from "../../components/sections/CtaManager";
import SectionSubHeader from "../../components/sections/partials/SectionSubHeader";
import FeaturesSplitHome from "../../components/sections/FeaturesSplitHome";

function Home() {
    const sectionSubHeader = {
        subtitle: '',
        paragraph: 'Smartcountry aprovecha el poder de los datos para mejorar la participación del cliente, impulsar las ventas, aumentar la eficiencia operativa, tomar decisiones informadas y mantener su negocio en marcha.'

    };
  return (
      <React.Fragment>
          <HeroFull/>
          <Clients topDivider bottomDivider />
          <SectionSubHeader data={sectionSubHeader} className="center-content pt-16 pb-16" />
          <CtaManager hasBgColor invertColor split />
          <FeaturesSplitHome topDivider/>
      </React.Fragment>
  );
}

export default Home;
