import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import Builds from './JenkinsBuildTableItem';
import ApiCaller from "../../lib/ApiCaller";
import {setCurrentBuilds} from "../../store/jenkinsStore";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/store";
import {useHistory} from "react-router";
import ModalBlank from "../../components/ModalBlank";
import ModalImage from '../../images/modal-image.jpg';
import AssuranceApi from "../../api/AssuranceApi";

interface ContainerProps {
  selectedItems: any;
  showCustomerReport:Function;
}
const JenkinsTable:React.FC<ContainerProps>  = ({selectedItems, showCustomerReport}) => {


  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [list, setList] = useState<any>([]);

  const currentUser = useSelector((state: AppState) => state.session.userInfo);
  const loggedIn = useSelector((state: AppState) => state.session.loggedIn);
  const dispatch = useDispatch();
  const history = useHistory();

  const builds:any[] = useSelector((state: AppState) => state.jenkins.builds);
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const [reportHTML, setReportHTML] = useState('');

  useEffect(() => {
    const jenkinsApi = new AssuranceApi(new ApiCaller());
    loggedIn ? jenkinsApi.getParteRecords().then(items => {
      dispatch(setCurrentBuilds(items));
    }) : history.push('/');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // @ts-ignore
    focusHandling('outline');
  }, [list]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li:any) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e:any) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };
  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line
  }, [isCheck]);

  const showReport = (contact:any) => {
    showCustomerReport(contact);
  }

  return (
      <>
        <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
          <header className="px-5 py-4">
            <h2 className="font-semibold text-gray-800">Calls <span className="text-gray-400 font-medium">{builds.length}</span></h2>
          </header>
          <div>
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="flex items-center">
                        <label className="inline-flex">
                          <span className="sr-only">Select all</span>
                          <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                        </label>
                      </div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Caller Name</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Plack</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Phone</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Called on</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-left">Actions</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm divide-y divide-gray-200">
                  {
                    builds.map((build:any) => {
                      return (
                        <Builds
                            phone={build.phone}
                            key={build.id}
                            id={build.id}
                            obj={build}
                            build={build.conductorName}
                            status={build.conductorPlacainen}
                            duration={build.hasAccidente}
                            timestamp={build.date}
                            description={build.sucesoDescription}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(build.id)}
                            showReport={showReport}

                        />
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ModalBlank id="news-modal" modalOpen={newsModalOpen} setModalOpen={setNewsModalOpen}>
          <div className="relative">
            <img className="w-full" src={ModalImage} width="660" height="200" alt="New on Mosaic" />
            {/* Close button */}
            <button className="absolute top-0 right-0 mt-5 mr-5 text-gray-50 hover:text-white" onClick={(e) => { e.stopPropagation(); setNewsModalOpen(false); }}>
              <div className="sr-only">Close</div>
              <svg className="w-4 h-4 fill-current">
                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
              </svg>
            </button>
          </div>
          <div className="p-5">
            {/* Modal header */}
            <div className="mb-2">
              <div className="mb-3">
                <div className="text-xs inline-flex font-medium bg-nexoGreen-100 text-nexoGreen-600 rounded-full text-center px-2.5 py-1">Cucumber Test Report</div>
              </div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-5">
              <div className="space-y-2">
                <div>{reportHTML}</div>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end space-x-2">
              <button className="btn-sm bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" onClick={(e) => { e.stopPropagation(); setNewsModalOpen(false); }}>Cool, I Got it</button>
            </div>
          </div>
        </ModalBlank>
      </>
  );
}

export default JenkinsTable;
