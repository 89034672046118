import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

import featuresSplitImage1 from './../../images/features-split-image-1.svg';
import featuresSplitImageTop1 from './../../images/features-split-top-01.png';
import featuresSplitImage2 from './../../images/features-split-image-2.svg';
import featuresSplitImageTop2 from './../../images/features-split-top-02.png';
import featuresSplitImage3 from './../../images/features-split-image-4.svg';
import featuresSplitImageTop3 from './../../images/features-split-top-03.png';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesAutomation extends React.Component {

  render() {

    const {className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, invertMobile, invertDesktop, alignTop, imageFill, ...props} = this.props;

    const outerClasses = classNames('features-split section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);

    const innerClasses = classNames('features-split-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');

    const splitClasses = classNames('split-wrap', invertMobile && 'invert-mobile', invertDesktop && 'invert-desktop', alignTop && 'align-top');

    const sectionHeader = {
      title: 'Operaciones diseñadas para alcanzar sus objetivos',
      paragraph: 'Mezcle y combine nuestras mejores tecnologías de operaciones para un mejor servicio al cliente, reducción de la carga de trabajo, inteligencia empresarial, previsión basada en datos y más.'

    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-2 sub-title-smart text-4xl md:text-3xl text-smartCountry-50" data-reveal-delay="200">Automatic Ticketing</h3>
                  <h5 className="m-0 sub-title-smart text-4xl md:text-2xl text-smartCountry-50">Automatización de procesos comerciales</h5>
                  <p className="m-0 text-smart text-4xl md:text-lg text-smartCountry-50 text-justify" data-reveal-delay="400">
                  Las solicitudes de los clientes, realizadas a través de los chatbots de Smartcountry en todos los canales, se detectan y confirman sin la intervención del personal.
                  Los tickets se asignan por persona, departamento o tercero para mayor eficiencia, con escalamiento automático.
                  </p>
                </div>
                <div className={
                  classNames('split-item-image center-content-mobile illustration-element-02 reveal-from-top', imageFill && 'split-item-image-fill')}>
                  <Image className="has-shadow" src={featuresSplitImage1} alt="Features split 01" width={528} height={396} />
                  <div style={img01Style}>
                    <Image src={featuresSplitImageTop1} alt="Features split top 01" width={528} height={396} />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Para equipos de desarrollo y QA
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="400">
                    Aumentamos tu capacidad de testear tu producto haciendo que la plataforma reduzca drásticamente el
                    número de bugs, aumentando la velocidad de los sprints.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image className="has-shadow" src={featuresSplitImage2} alt="Features split 02" width={528} height={396} />
                  <div style={img02Style}><Image src={featuresSplitImageTop2} alt="Features split top 02" width={528} height={396} />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Seguridad para el equipo de ventas y negocio
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="400">
                    Generamos más valor para tu producto, haciendo que el tiempo para conseguir revenue se acorte.
                    Ayudamos a la retención del cliente y a su conversión.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={featuresSplitImage3}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  <div style={img03Style}>
                    <Image
                      src={featuresSplitImageTop3}
                      alt="Features split top 03"
                      width={528}
                      height={396} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const img01Style = {
  position: 'absolute',
  bottom: '-12.4%'
}

const img02Style = {
  position: 'absolute',
  bottom: '-13.1%'
}

const img03Style = {
  position: 'absolute',
  bottom: '-16.1%',
  left: '-10.1%',
  right: '10.1%'
}

FeaturesAutomation.propTypes = propTypes;
FeaturesAutomation.defaultProps = defaultProps;

export default FeaturesAutomation;
