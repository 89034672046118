import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

import featuresSplitImage1 from './../../images/features-split-image-1.svg';
import featuresSplitImageTop1 from './../../images/features-split-top-01.png';
import featuresSplitImage2 from './../../images/features-split-image-2.svg';
import featuresSplitImageTop2 from './../../images/features-split-top-02.png';
import featuresSplitImage3 from './../../images/features-split-image-4.svg';
import featuresSplitImageTop3 from './../../images/features-split-top-03.png';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, invertMobile, invertDesktop, alignTop, imageFill, ...props} = this.props;

    const outerClasses = classNames('features-split section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);

    const innerClasses = classNames('features-split-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');

    const splitClasses = classNames('split-wrap', invertMobile && 'invert-mobile', invertDesktop && 'invert-desktop', alignTop && 'align-top');

    const sectionHeader = {
      title: 'Hacemos fáciles las comunicaciones',
      paragraph: 'Conozca a sus clientes en sus canales favoritos desde una única plataforma impulsada por IA.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 sub-title-smart text-4xl md:text-2xl text-smartCountry-50" data-reveal-delay="200">
                    Nuestra propuesta de valor está muy clara
                  </h3>
                  <p className="m-0 sub-title-smart text-4xl md:text-2xl text-smartCountry-50" data-reveal-delay="400">
                    Solo iwaqi.com te brinda la velocidad y la flexibilidad para escalar las pruebas y escalar QA en base a tu necesidad.
                    Es por eso que somos la mejor opción para los innovadores digitales de todo el mundo.
                  </p>
                </div>
                <div className={
                  classNames('split-item-image center-content-mobile illustration-element-02 reveal-from-top', imageFill && 'split-item-image-fill')}>
                  <Image className="has-shadow" src={featuresSplitImage1} alt="Features split 01" width={528} height={396} />
                  <div style={img01Style}>
                    <Image src={featuresSplitImageTop1} alt="Features split top 01" width={528} height={396} />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Para equipos de desarrollo y QA
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="400">
                    Aumentamos tu capacidad de testear tu producto haciendo que la plataforma reduzca drásticamente el
                    número de bugs, aumentando la velocidad de los sprints.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image className="has-shadow" src={featuresSplitImage2} alt="Features split 02" width={528} height={396} />
                  <div style={img02Style}><Image src={featuresSplitImageTop2} alt="Features split top 02" width={528} height={396} />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Seguridad para el equipo de ventas y negocio
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="400">
                    Generamos más valor para tu producto, haciendo que el tiempo para conseguir revenue se acorte.
                    Ayudamos a la retención del cliente y a su conversión.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={featuresSplitImage3}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  <div style={img03Style}>
                    <Image
                      src={featuresSplitImageTop3}
                      alt="Features split top 03"
                      width={528}
                      height={396} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const img01Style = {
  position: 'absolute',
  bottom: '-12.4%'
}

const img02Style = {
  position: 'absolute',
  bottom: '-13.1%'
}

const img03Style = {
  position: 'absolute',
  bottom: '-16.1%',
  left: '-10.1%',
  right: '10.1%'
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
