//------------  state definition
import {SessionActionTypes, SessionState, UPDATE_SESSION} from "./sessionStore";
import Notifications from "../lib/notifications";

export interface NotificationState {
    options: Array<any>
}
//------------ actions
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

interface UpdateNotificationAction {
    type: typeof UPDATE_NOTIFICATION;
    payload: NotificationState;
}
interface UpdateNotificationsAction {
    type: typeof UPDATE_NOTIFICATIONS;
    payload: NotificationState;
}

export type NotificationActionTypes = UpdateNotificationAction | UpdateNotificationsAction;

export function updateNotification(newNotification: any): NotificationActionTypes {
    return {
        type: UPDATE_NOTIFICATION,
        payload: newNotification
    }
}
export function updateNotifications(notifications: any): NotificationActionTypes {
    return {
        type: UPDATE_NOTIFICATIONS,
        payload: notifications
    }
}
//------------ reducer
const initialState: NotificationState = { options: [ ]}; //Notifications.getCurrentNotifications()


export function notificationReducer( state = initialState, action: NotificationActionTypes): NotificationState {
    switch (action.type) {
        case UPDATE_NOTIFICATION: {
            return {
                ...state,
                options: [...state.options,action.payload]
            }
        }
        case UPDATE_NOTIFICATIONS: {
            return {
                ...state,
                options: action.payload.options
            }
        }
        default:
            return state;
    }
}
