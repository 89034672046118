import React from 'react';
import SearchModal from './header/SearchModal';
import Help from './header/Help';
import UserMenu from './header/UserMenu';
import {AppState} from "../store/store";
import NotificationPresenter from "../shared/components/NotificationPresenter";
import {useDispatch, useSelector} from "react-redux";
import toast from "../shared/utils/toast";
import {setLoaded} from "../store/padStore";
import DropDownNotifications from "./header/Notifications";
import multicharly from '../images/backgrounds/multicharly.jpeg';

export interface Props {
  sidebarOpen:boolean;
  setSidebarOpen:Function;
}

const Header = ({ sidebarOpen, setSidebarOpen }: Props) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: AppState) => state.session.userInfo);
  const notifyMessage = (msg:string) => {
    toast.success(msg);
    dispatch(setLoaded());
  }
  //const notificationPresenter = new NotificationPresenter(currentUser,{dispatch:useDispatch(), notify:notifyMessage});

  React.useEffect(() => {
    //return () => notificationPresenter.socketConn.close();
  }, [false]);

  return (
    <header className="sticky top-0 bg-white border-b border-gray-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">

          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button className="text-gray-500 hover:text-gray-600 lg:hidden" aria-controls="sidebar" aria-expanded={sidebarOpen} onClick={() => setSidebarOpen(!sidebarOpen)}>
              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>



          <div className="flex items-center">
            {/*<img src={multicharly} alt={'Multicharly'} className=""/>*/}
            {/*<SearchModal />*/}
            <DropDownNotifications />
            {/*<Help />*/}
            <hr className="w-px h-6 bg-gray-200 mx-3" />
            <UserMenu />

          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;
