import moment from "moment";
import * as hutsArray from './huts';

export const stringToDate = (_date: string, _format: string, _delimiter: string) =>
{
    var formatLowerCase=_format.toLowerCase();
    var formatItems=formatLowerCase.split(_delimiter);
    var dateItems=_date.split(_delimiter);
    var monthIndex=formatItems.indexOf("mm");
    var dayIndex=formatItems.indexOf("dd");
    var yearIndex=formatItems.indexOf("yyyy");
    var month=parseInt(dateItems[monthIndex]);
    month-=1;
    var formatedDate = new Date(Number(dateItems[yearIndex]),month,+dateItems[dayIndex]);
    return formatedDate;
}

export const getCSV = (date:string,roats:any) => {
    const huts:any = hutsArray.huts;
    const parseddate = stringToDate(date,"yyyy-MM-dd","-");
    const parseddateString = moment(parseddate).format('DD/MM/yyyy');
    const csv:any = [];
    roats.filter((r:any)=>r.date==parseddateString).map((i:any)=>{
        const hut = huts.find((h: { place: any; })=>h.place==i.place||'')
            ? huts.find((h:any)=>h.place==i.place).hut==''
                ?i.place:huts.find((h:any)=>h.place==i.place).hut.substring(6,11):i.place;
        const line = `${i.gName};${i.gSurname};${i.gNationality};${i.gDOB};${i.gPOB};${i.gCountry};;;;;;${i.gDocumentNumber};${i.date};;${i.dateExit};;${hut};;;;${i.gPostalCode}`;
        csv.push(line.split(';'));
    })
    return csv;
}


export const getStatus = (date:string,roats:any,ack:any) => {
    const parseddateString:string = moment(stringToDate(date,"yyyy-MM-dd","-")).format('DD/MM/yyyy');
    return roats.filter((r:any)=>r.date==parseddateString);
}

export const getEntradas = (date:string,contacts:any,roats:any,pay:any, ack:any) => {
    const parseddateString:string = moment(stringToDate(date,"yyyy-MM-dd","-")).format('DD/MM/yyyy');
    const we  = Object.keys(contacts).map(k => contacts[k].reduce((a:any, x:any) => {(a[x['date'].substring(0,10)] = a[x['date'].substring(0,10)] || []).push(x);return a;}, {}));
    var obj:any = {};
    we.forEach(o=>Object.assign(obj, o));
    let result:any = obj[parseddateString]||[];
    if (result.length>0) {
        const reservasWithRoat = roats.map((a:any)=>a.date+a.place);
        result.map((a:any)=> {
            const rrr = reservasWithRoat.find((r: any) => r == `${a.date || ''}${a.place || ''}`) || [];
            const ppp = pay.find((r: any) => `${r.date || ''}${r.place || ''}` == `${a.date || ''}${a.place || ''}`);
            a.hasRoat=rrr.length>0;
            a.pay=ppp ? ppp : {status:'EMPTY'};
            a.ack=ack.find((r: any) => r == `${a.date || ''}${a.place || ''}`);
            return a;
        });
    }
    return result;
}
