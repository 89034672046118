import React, {useEffect, useState} from 'react';

import {AppState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {generateErrors, is} from "../../shared/utils/validation";
import toast from "../../shared/utils/toast";
import ApiCaller from "../../lib/ApiCaller";
import TestProjectApi from "../../api/TestProjectApi";
import {setCurrentDoc} from "../../store/docsStore";
import {useHistory} from "react-router";

const TestingPanel = () => {

  const projectCurrent = useSelector((state: AppState) => state.current.doc);
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [toggle1, setToggle1] = useState(true);
  const [toggle2, setToggle2] = useState(false);
  const [project, setProject] = useState<any>(projectCurrent);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(()=>{
    setProject(projectCurrent);
  },[]);

  const updateProject = (e: React.FormEvent) => {
    e.preventDefault();
    if (loggedUser) {
      const emailContact = project.emailContact;
      const errors: any = generateErrors({ emailContact }, { emailContact: [is.required(), is.email()] })
      if (errors.emailContact) {toast.error(errors.emailContact);return;}

      const api = new TestProjectApi(new ApiCaller());
      console.log(JSON.stringify(project))
      api.updateDraft(project, loggedUser.token).then((item) => {
        toast.success('Project updated');
        dispatch(setCurrentDoc(item));
        history.push('/testing-projects');
      }).catch((err) => {
        toast.error('Error changing project');
      });
    }
  }

  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">Project details</h2>
        <section>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="code">Project unique code</label>
              <input id="code" className="form-input w-full" type="text" onChange={e => setProject({...project, jenkinsJob:e.target.value})} value={project.jenkinsJob} disabled/>
            </div>
          </div>
        </section>
        {/* Business Profile */}
        <section>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">Name</label>
              <input id="name" className="form-input w-full" type="text" onChange={e => setProject({...project, name:e.target.value})} value={project.name}/>
            </div>
            <div className="sm:w-2/3">
              <label className="block text-sm font-medium mb-1" htmlFor="description">Project Description</label>
              <input id="description" className="form-input w-full" type="text" onChange={e => setProject({...project, description:e.target.value})} value={project.description}/>
            </div>
          </div>
        </section>
        {/* Email */}
        <section>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/2">
              <label className="block text-sm font-medium mb-1" htmlFor="site">Site to test</label>
              <input id="site" className="form-input w-full" type="text" onChange={e => setProject({...project, site:e.target.value})} value={project.site}/>
            </div>
            <div className="sm:w-1/2">
              <label className="block text-sm font-medium mb-1" htmlFor="emailContact">Contact email for this project</label>
              <input id="emailContact" className="form-input w-full" type="email" onChange={e => setProject({...project, emailContact:e.target.value})} value={project.emailContact}/>
            </div>
          </div>
        </section>
        <h2 className="text-2xl text-gray-800 font-bold mb-5">Project communication channel</h2>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="flex items-center">
            <label className="flex items-center">
              <input type="checkbox" className="form-checkbox" checked={toggle1} onChange={() => setToggle1(true)} />
              <span className="text ml-2">Voice</span>
            </label>
          </div>
        </div>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="flex items-center">
            <label className="flex items-center">
              <input type="checkbox" className="form-checkbox" checked={toggle2} onChange={() => setToggle2(!toggle2)} />
              <span className="text ml-2">SMS</span>
            </label>
          </div>
        </div>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn border-gray-200 hover:border-gray-300 text-gray-600">Cancel</button>
            <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white ml-3" onClick={updateProject}>Save Changes</button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default TestingPanel;
