import React from 'react';

// import sections
import HeroManager from '../../components/sections/HeroManager';
import Clients from '../../components/sections/Clients';
import FeaturesTilesManager from '../../components/sections/FeaturesTilesManager';
import FeaturesSplitManager from '../../components/sections/FeaturesSplitManager';
import CtaManager from '../../components/sections/CtaManager';

function Manager() {

  return (
      <React.Fragment>
            <FeaturesTilesManager className="illustration-section-01"/>
            <Clients topDivider bottomDivider />
            <CtaManager hasBgColor invertColor split />
            <FeaturesSplitManager topDivider />

      </React.Fragment>
  );
}

export default Manager;
