import React from 'react';
import PropTypes from 'prop-types';
import toast from '../../shared/utils/toast';
import { ConfirmModal } from '../added/index';
import ApiCaller from "../../lib/ApiCaller";
import LegalApi from "../../api/TestProjectApi";
import {useSelector} from "react-redux";

const propTypes = {
  issue: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
};

const DraftDelete = ({ issue, fetch, modalClose }) => {

    const currentUser = useSelector((state) => state.session.userInfo);

    const handleIssueDelete = async () => {
        try {
          var api = new LegalApi(new ApiCaller());
          await api.removeDraft(issue.id);
          await fetch();
          modalClose();
        } catch (error) {
          toast.error(error);
        }
  };

  return (
    <ConfirmModal
      title="¿Confirmas el borrado del proyecto de test?"
      message="Una vez eliminado, no se puede recuperar"
      confirmText="Eliminar"
      onConfirm={handleIssueDelete}
      renderLink={modal => (
        <button className="text-red-500 hover:text-red-600 rounded-full" onClick={modal.open}>
          <span className="sr-only">Delete</span>
          <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
          <path d="M13 15h2v6h-2zM17 15h2v6h-2z" />
          <path d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
          </svg>
        </button>
      )}
    />
  );
};

DraftDelete.propTypes = propTypes;

export default DraftDelete;
