import React, { useState } from 'react';

import Sidebar from '../../../partials/Sidebar';
import Header from '../../../partials/Header';
import TestingSidebar from "../../../partials/testing/TestingSidebar";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/store";


  export const TestingProjectBase = ({ children }: { children: any }) => {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();
  const project = useSelector((state: AppState) => state.current.doc);
  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                <button className="text-gray-400 hover:text-gray-500 rounded-full" onClick={()=>{history.push(`/testing-projects`);}} >
                  <svg className="flex-shrink-0 h-6 w-6 mr-4" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-400 text-nexoGreen-300`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                    <path className={`fill-current text-gray-700 !text-nexoGreen-600`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                    <path className={`fill-current text-gray-600 text-nexoGreen-500`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
                  </svg>
                </button>
                Tests Project <i className="text text-gray-400 italic">[ {project.name} ]</i> ✨
              </h1>
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <TestingSidebar />
                {children}
              </div>
            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

export default TestingProjectBase;
