import ApiCaller from '../lib/ApiCaller';

class PadApi {

    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    removeContact(id:string) : Promise<void | any | undefined> {
        return this.apiCaller.call(`/v1/pad/removecontact/${id}`, 'DELETE')
    }

    getPisosContacts(hash:string): Promise<void | any | undefined> {
        return this.apiCaller.download(hash, 'GET').then(file=> {
            return file;
        });
    }

    getPisosStruct():Promise<any> {
        const url = '/v1/pad/byweek/all';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    getAllContacts():Promise<any> {
        const url = '/v1/pad';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    getCustomerEntradaByPhoneNumber(phone:string):Promise<any> {
        const url = '/v1/kom/' + phone
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    getLogStateFromPhone(phone:string):Promise<any> {
        const url = '/v1/kom/byphone/' + phone
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    completeForm(payload:any): Promise<void | any | undefined> {
        const topic = payload.gName ? `ROAT-${payload.gName||''}-${payload.gSurname}` : 'FORM-SENDED';
        return this.apiCaller.call(`/v1/kom/event/${topic}`, 'POST', {...payload}).then(file=> {
            return file;
        });
    }
    finishROATForm(payload:any): Promise<void | any | undefined> {
        const topic = `ROAT-FORM-COMPLETE`;
        return this.apiCaller.call(`/v1/kom/event/${topic}`, 'POST', {...payload}).then(file=> {
            return file;
        });
    }

    getInstruccionesPDF(place:string): Promise<void | any | undefined> {
        return this.apiCaller.download('/v1/pad/pdf/'+place, 'GET').then(file=> {
            return file;
        });
    }

    getInstruccionesPDFCustom(id:string, lang:number): Promise<void | any | undefined> {
        return this.apiCaller.download(`/v1/pad/pdf/wek/${id}/${lang}`, 'GET').then(file=> {
            return file;
        });
    }

    getAllCreatedParte():Promise<any> {
        const url = '/v1/parte/succeed/PISOSADIAS/project';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    getPayStatus():Promise<any> {
        const url = '/v1/pay';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }

    forcePay(payload:any, status: string): Promise<void | any | undefined> {
        return this.apiCaller.call(`/v1/pay/force/${status}`, 'PUT', {...payload}).then(file=> {
            return file;
        });
    }

    getAllROATparte():Promise<any> {
        const url = '/v1/kom/roat/pisosadias';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }

    getAck():Promise<any> {
        const url = '/v1/kom/wassdump/ack';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    collide():Promise<any> {
        const url = '/v1/pad/collide';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }

    launch(id:string,formId:string):Promise<any> {
        const url = `/v1/kom/launch/${formId}/${id}`
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }

    removeROAT(payload:any) : Promise<void | any | undefined> {
        const id = `ROAT-${payload.gName||''}-${payload.gSurname}`
        return this.apiCaller.call(`/v1/kom/removeroat/${id}`, 'DELETE')
    }
}

export default PadApi;
