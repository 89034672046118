import React, {ChangeEvent, useRef, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/store";
import toast from "../../shared/utils/toast";
import UserApi from "../../api/UserApi";
import ApiCaller from "../../lib/ApiCaller";
import {SessionState, updateAvatar, updateSession} from "../../store/sessionStore";
import Settings from "../../lib/settings";
import User,{UserTypes, UserTypesLabels} from "../../lib/common/models/user";
import UserDetail from "../../lib/common/models/userDetail";
import ModalBasic from "../../components/ModalBasic";
import {generateErrors, is} from "../../shared/utils/validation";

const AccountPanel = () => {

  const [passwordDialog, showPasswordDialog] = useState(false);

  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  let userDetail:any = {}
  const [name, setName] = useState(loggedUser.name);
  const [businessName, setBusinessName] = useState<string|undefined>();
  const [businessId, setBusinessId] = useState<string|undefined>();
  const [location, setLocation] = useState<string|undefined>();
  const dispatch = useDispatch();
  const [imageState, setState] = React.useState({file: null, imagePreviewUrl: useSelector((state: AppState) => state.session.avatar),});
  const [newPassword, setNewPassword] = React.useState<any>({
    password: '',
    passwordConfirm: ''
  });

  React.useEffect(() => {
    console.log('Load user');
    var userApi = new UserApi(new ApiCaller(loggedUser.token));
    userApi.getMyDetails(loggedUser.token).then((d:UserDetail) => {
      userDetail=d;
      setBusinessName(d.businessName);
      setBusinessId(d.businessId);
      setLocation(d.location);
    });
    }, []);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let reader = new FileReader();
    let files = e.target.files;

    if (files && files.length > 0) {
      if (files[0].size > 1024000) {
        toast.error('Image maximum size allowed is 1M.');
      } else {
        reader.onloadend = () => {
          setState({
            // @ts-ignore
            file: files[0],
            // @ts-ignore
            imagePreviewUrl: reader.result,
          });
        }
        reader.readAsDataURL(files[0]);
      }
    }
  }

  const updateUser = async(e: React.FormEvent) => {
    e.preventDefault();
    var userApi = new UserApi(new ApiCaller());
    userApi.updateUser(new User({...loggedUser,name: name}), loggedUser.token).then((user: any) => {
      if (user) {
        user.token = loggedUser.token;
        const sessionData:SessionState = {
          loggedIn: user.email? true : false,
          ready: true,
          userInfo: user,
          type: user.type,
          avatar: imageState.imagePreviewUrl,
          layout: user.type==='admin'?'navozyme':user.type
        };
        dispatch(updateSession(sessionData));
        if (imageState.file) {
          userApi.setAvatar(loggedUser._id, imageState.file, loggedUser?.token).then(() => {
            Settings.setCurrentUser(user);
            toast.success('Profile Updated')
            dispatch(updateAvatar(imageState.imagePreviewUrl || ''));
          })
        } else {
          Settings.setCurrentUser(user).then(() => {
            toast.success('Profile Updated')
          });
        }
      }
    })
        .catch(err => {
          console.log(err.message);
          toast.error('Error updating user, please try again');
        });
    //var userApi = new UserApi(new ApiCaller());
    await userApi.createOrUpdateUserDetails(UserDetail.prototype.load({...userDetail,images:[],businessName:businessName,businessId:businessId,location:location}), loggedUser.token)
  }
  const fileInput = useRef(null);
  const clickOnInput = () => {const node:any = fileInput.current;node.click();}

  const [fieldState, setFieldState] = React.useState({
    passwordState: "",
    passwordConfirmState: ""
  });

  const updatePassword = (e: React.FormEvent) => {
    e.preventDefault();

    if (loggedUser) {
      const password = newPassword.password;

      const errors: any = generateErrors(
          { password },
          { password: [is.required(), is.minLength(3), is.maxLength(30), is.securePassword()] }
      )

      if (errors.password) {
        toast.error(errors.password);
        return;
      }

      const userApi = new UserApi(new ApiCaller());
      const userId:string=loggedUser.id||'';
      userApi.updatePasswordAdmin(userId, newPassword.password, loggedUser.token).then(() => {
        showPasswordDialog(false);
        toast.success('Password updated');
        setNewPassword({ password: '', passwordConfirm: ''});
      }).catch((err) => {
        toast.error('Error changing password');
      });
    }
  }
  const passwordOnChange = (e: any, compareField: string) => {
    const password = e.target.value;

    const errors: any = generateErrors(
        { password },
        { password: [is.securePassword()] }
    )

    const newState = (e.target.value == newPassword[compareField] && !errors.password) ? 'has-success' : 'has-danger';
    const confirmNewState = e.target.value == newPassword[compareField] ? 'has-success' : 'has-danger';

    setFieldState({
      "passwordState": newState,
      "passwordConfirmState": confirmNewState,
    })

    setNewPassword({
      ...newPassword,
      [e.target.name]: e.target.value
    })
  }
  return (
      <>
        <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">My Account</h2>
        {/* Picture */}
        <section>
          <div className="flex items-center">
            <div className="mr-4">
              <img className="w-20 h-20 rounded-full" src={imageState.imagePreviewUrl} width="80" height="80" alt="User upload" />
            </div>
            <input ref={fileInput} id="fileUpload" type="file" onChange={(e) => handleImageChange(e)} style={{display:'none'}}/>
            <button className="btn-sm bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" onClick={()=>{clickOnInput()}}>Upload avatar</button>
          </div>
        </section>
        {/* User Profile */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">User Profile</h2>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">Name</label>
              <input defaultValue={name} onChange={e => setName(e.target.value)} placeholder="Name"  id="name" className="form-input w-full" type="text" />
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="role">Role</label>
              <input defaultValue={UserTypes[loggedUser.type]} placeholder="Role" id="role" className="form-input w-full" type="text" disabled/>
            </div>
          </div>
        </section>{/* Business Profile */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">Business Profile</h2>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="business-name">Business Name</label>
              <input id="business-name" defaultValue={businessName} onChange={e => setBusinessName(e.target.value)} className="form-input w-full" type="text" />
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="business-id">Business ID</label>
              <input id="business-id" defaultValue={businessId} onChange={e => setBusinessId(e.target.value)} className="form-input w-full" type="text" />
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="location">Location</label>
              <input id="location" defaultValue={location} onChange={e => setLocation(e.target.value)}  className="form-input w-full" type="text" />
            </div>
          </div>
        </section>
        {/* Email */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">Email</h2>
          <div className="flex flex-wrap mt-5">
            <div className="mr-2">
              <label className="sr-only" htmlFor="email">Business email</label>
              <input  defaultValue={loggedUser.email} id="email" placeholder="Email" className="form-input w-full" type="text" disabled/>
            </div>
            <button className="btn border-gray-200 hover:border-gray-300 shadow-sm text-nexoGreen-200" disabled>Change</button>
          </div>
        </section>
        {/* Password */}
        <section>
          <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">Password</h2>
          <div className="text-sm">You can set a new password</div>
          <div className="mt-5">
            <button className="btn border-gray-200 shadow-sm text-nexoGreen-500" aria-controls="feedback-modal" onClick={(e) => { e.stopPropagation(); showPasswordDialog(true); }}>Set New Password</button>
          </div>
        </section>
      </div>
      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn border-gray-200 hover:border-gray-300 text-gray-600">Cancel</button>
            <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white ml-3" onClick={updateUser}>Save Changes</button>
          </div>
        </div>
      </footer>
    </div>

        {/* Send Feedback */}
        <div className="m-1.5">
          {/* Start */}
          <ModalBasic id="feedback-modal" modalOpen={passwordDialog} setModalOpen={showPasswordDialog} title="Change password">
            {/* Modal content */}
            <div className="px-5 py-4">
              <div className="space-y-3">
                <div>
                  <label className="block text-sm font-medium mb-1" htmlFor="password">New Password</label>
                  <input id="password" className="form-input w-full px-2 py-1"
                         placeholder="New Password"
                         type="password"
                         name="password"
                         autoComplete="off"
                         value={newPassword.password}
                         onChange={(e) => passwordOnChange(e, "passwordConfirm")}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1" htmlFor="passwordConfirm">Confirm Password</label>
                  <input id="passwordConfirm" className="form-input w-full px-2 py-1"
                         placeholder="Confirm Password"
                         type="password"
                         name="passwordConfirm"
                         autoComplete="off"
                         value={newPassword.passwordConfirm}
                         onChange={(e) => passwordOnChange(e, "password")}
                  />
                </div>
              </div>
            </div>
            {/* Modal footer */}
            <div className="px-5 py-4 border-t border-gray-200">
              <div className="flex flex-wrap justify-end space-x-2">
                <button className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600" onClick={(e) => { e.stopPropagation(); showPasswordDialog(false); }}>Cancel</button>
                <button className="btn-sm bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" onClick={updatePassword} disabled={!newPassword.password || (newPassword.password !== newPassword.passwordConfirm)}>Send</button>
              </div>
            </div>
          </ModalBasic>
          {/* End */}
        </div>
      </>
  );
}

export default AccountPanel;
