import React, {useEffect, useReducer, useRef, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/store";
import BackofficeBase from "../pagefactory/BackofficeBase";
import TinyMCETextEditor from "../../../components/TinyMCETextEditor";
import InstruccionesApi from "../../../api/InstruccionesApi";
import ApiCaller from "../../../lib/ApiCaller";
import toast from "../../../shared/utils/toast";
import {setCurrentPDF} from "../../../store/pdfStore";
import PDFUploadPictures from "./pdfImageUpload";
import ImageGalleryComp from "../../../components/ImageGalleryComp";
import DocumentsApi from "../../../api/DocumentsApi";
import {UploadFile} from "antd/lib/upload/interface";
import {getInstruccionesPDF, getInstruccionesPDFCustom} from "../../../utils/ApiCommands";

const PdfSample = () => {

    const user = useSelector((state: AppState) => state.session.userInfo);
    const pdf = useSelector((state: AppState) => state.pdf.pdf);

    const dispatch = useDispatch();
    const [currentPDF, setPDF] = useState<any>(pdf);

    const publish = () => {
        const api = new InstruccionesApi(new ApiCaller(user.token));
        api.update(currentPDF).then((doc:any) => {
            dispatch(setCurrentPDF(doc));
            setPDF(doc);
            toast.success('Instrucción actualizada!')
        })
        console.log(JSON.stringify(currentPDF));
    }
    const getPdf = () => {
        publish();
        getInstruccionesPDFCustom(currentPDF._id, lang);
    }

    const Actions = () => <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        {/* Search form */}
        {/* Add member button */}
        <button className="btn bg-red-500 hover:bg-red-600 text-white" aria-controls="feedback-modal"
                onClick={(e) => { e.stopPropagation(); getPdf(); }}>
            <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">PDF</span>
        </button>
        <button className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" aria-controls="feedback-modal"
                onClick={(e) => { e.stopPropagation(); publish(); }}>
            <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Publish</span>
        </button>
    </div>

    const Navigation = () => <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        {/* Add member button */}
    </div>

    const editorContRef: any = useRef();
    const getEditor = (currentEditor: any) => {
        editorContRef.current = currentEditor;
    }

    const [errors,setErrors] = useState<any>({});
    const [lang,setLang] = useState<number>(3);
    const [modal, setModal] = useState<boolean>(false);

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const imageClick = async (event: any) => {

        const { src } = event.target;
        const src1= src.substring( src.indexOf('//')+2);
        //src1.substring( src1.indexOf('/'))


        const img = `<img src="${src}" width="317" height="238">`;

        setPDF({
            ...currentPDF,
            content_ca: `${img}${currentPDF.content_ca}`,
            content_en: `${img}${currentPDF.content_en}`,
            content_es: `${img}${currentPDF.content_es}`,
            content_fr: `${img}${currentPDF.content_fr}`
        });

        const hash=src.substring(src.lastIndexOf('/')+1);
        const documentsApi = new DocumentsApi(new ApiCaller());
        const imageDocument:any = await documentsApi.getByHash(hash);
        await documentsApi.addResourcefromGalleryToQuestion(imageDocument.id, currentPDF._id);

        setModal(false);
        forceUpdate();
    };

    return (
        <>
            <BackofficeBase actions={<Actions/>} titleHeader={`PDF de instrucciones`} navigation={<Navigation/>}>
                <div className="px-5 py-4">
                    <section>
                        <h2 className="text-xl text-gray-800 font-bold mb-1">Datos Generales</h2>
                        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                            <div className="sm:w-1/2">
                                <label className="block text-sm font-medium mb-1" htmlFor="name">Nombre</label>
                                <input id="name" value={currentPDF.name} onChange={(e)=>setPDF({...currentPDF, name:e.target.value})} className="form-input w-full" type="text" />
                            </div>
                            <div className="sm:w-1/2">
                                <label className="block text-sm font-medium mb-1" htmlFor="name">Carpeta</label>
                                <input id="name" value={currentPDF.folder} onChange={(e)=>setPDF({...currentPDF, folder:e.target.value})} className="form-input w-full" type="text" />
                            </div>
                        </div>
                    </section>
                    <section className="pt-6">
                        <h2 className="text-xl text-gray-800 font-bold mb-1">Geolocalización</h2>
                        <div className="text-sm">Utilizaremos las coordenadas GPS para ayudar a los clientes a encontrar el apartamento</div>
                        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                            <div className="sm:w-1/2">
                                <label className="block text-sm font-medium mb-1" htmlFor="lat">Latitud</label>
                                <input id="lat" className="form-input w-full" type="text" value={currentPDF.lat} onChange={(e)=>setPDF({...currentPDF, lat:e.target.value})}/>
                            </div>
                            <div className="sm:w-1/2">
                                <label className="block text-sm font-medium mb-1" htmlFor="lon">Longitud</label>
                                <input id="lon" className="form-input w-full" type="text" value={currentPDF.lon} onChange={(e)=>setPDF({...currentPDF, lon:e.target.value})}/>
                            </div>
                        </div>
                    </section>

                    <section className="pt-6">
                        <h2 className="text-xl leading-snug text-gray-800 font-bold mb-1">Galeria de imágenes <button className="btn bg-nexoGreen-500 text-nexoGreen-100 hover:bg-nexoGreen-700 btn-sm" onClick={(e) => { e.stopPropagation();setModal(true)}} >Añadir imágen de la galería</button></h2>
                        <div className="text-sm">Puede añadir imágenes al PDF</div>
                        <br/>
                        <PDFUploadPictures questionName={currentPDF._id} uploadFromGallery={false}/>

                    </section>

                    <section className="pt-6">
                        <h2 className="text-xl text-gray-800 font-bold mb-1">Contenido</h2>

                        <div>
                            <ul className="flex text-xl">
                                <li className="">
                                    <button onClick={() => setLang(1)} className={`btn bg-nexoGreen-200 hover:bg-nexoGreen-400 text-2xl ${lang==1?'bg-nexoGreen-600':'bg-nexoGreen-200'}`}>🇦🇩</button>
                                </li>
                                <li className="">
                                    <button onClick={() => setLang(2)} className={`btn bg-nexoGreen-200 hover:bg-nexoGreen-400 text-2xl ${lang==2?'bg-nexoGreen-600':'bg-nexoGreen-200'}`}>🇬🇧</button>
                                </li>
                                <li className="">
                                    <button onClick={() => setLang(3)} className={`btn bg-nexoGreen-200 hover:bg-nexoGreen-400 text-2xl ${lang==3?'bg-nexoGreen-600':'bg-nexoGreen-200'}`}>🇪🇸</button>
                                </li>
                                <li className="">
                                    <button onClick={() => setLang(4)} className={`btn bg-nexoGreen-200 hover:bg-nexoGreen-400 text-2xl ${lang==4?'bg-nexoGreen-600':'bg-nexoGreen-200'}`}>🇫🇷</button>
                                </li>
                            </ul>

                        </div>
                        <div className="text-sm">Selecciona el idioma para editar el contenido</div>
                        <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
                            {lang==1 && <TinyMCETextEditor value={currentPDF.content_ca} onChange={(e: any) => setPDF({...currentPDF, content_ca:e}) } getEditor={getEditor}/>}
                            {lang==2 && <TinyMCETextEditor value={currentPDF.content_en} onChange={(e: any) => setPDF({...currentPDF, content_en:e}) } getEditor={getEditor}/>}
                            {lang==3 && <TinyMCETextEditor value={currentPDF.content_es} onChange={(e: any) => setPDF({...currentPDF, content_es:e}) } getEditor={getEditor}/>}
                            {lang==4 && <TinyMCETextEditor value={currentPDF.content_fr} onChange={(e: any) => setPDF({...currentPDF, content_fr:e}) } getEditor={getEditor}/>}
                        </div>
                    </section>

                    <div className="space-y-3">
                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="feedback">Comentarios<span className="text-red-500">*</span></label>
                            <textarea id="feedback" className={`form-textarea w-full px-2 py-1 ${errors.description && 'border-red-300'}`} rows={4} required  value={currentPDF.description} onChange={(e)=>setPDF({...currentPDF, description:e.target.value})}></textarea>
                            {errors.description && <div className="text-xs mt-1 text-red-500">This field is required!</div>}
                        </div>
                    </div>
                </div>
            </BackofficeBase>
            <ImageGalleryComp instructionId={currentPDF._id} dispachClick={imageClick} feedbackModalOpen={modal} setFeedbackModalOpen={setModal}/>

        </>
    );
}

export default PdfSample;
