import ApiCaller from '../lib/ApiCaller';
import NZDocument from '../lib/common/models/nzDocument';

class DocumentsApi {

    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    getAllImages():Promise<void | any[]> {
        const url = '/v1/documents/images';
        return this.extracted(url);
    }

    getAllImagesByinstruction(instructionId:string):Promise<void | any[]> {
        const url = `/v1/documents/imagesinstructions/${instructionId}`;
        return this.extracted(url);
    }

    async getByHash(hash:string):Promise<NZDocument> {
        const url = '/v1/documents/hash/' + hash
        return await this.apiCaller.call(url, 'GET').then((doc: any) => {
            return new NZDocument(doc)
        });
    }

    async addResourcefromGalleryToQuestion(imageId:string, instruccionId: string):Promise<NZDocument> {
        let url = `/v1/instrucciones/${instruccionId}/addResourceToQuestionFromGallery/${imageId}`;
        return await this.apiCaller.call(url, 'POST').then((doc:any) => {
            return new NZDocument(doc)
        }).catch((err) => {
            console.error(err);
            throw Error(err);
        });
    }

    extracted(url: string) {
        return this.apiCaller.call(url, 'GET')
            .then(data => {
                var dArr = data.files.map((doc: any) => {
                    return {
                        original: `/api/v1/documents/images/${doc.fileHash}`
                    };
                });
                return dArr;
            })
    }


}

export default DocumentsApi;
