import React from 'react';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../store/store";
const { pathname } = location;

const Iwaqi = () => {
    const userInfo = useSelector((state:AppState) => state.session.userInfo);

    return (<div className="space-y-8">
        {/* More group */}
        <div>
            <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
                <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span>
                <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">{userInfo.type}</span>
            </h3>
            <ul className="mt-3">
                {/* dash */}

                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('dashboard') && 'bg-gray-900'}`}>
                    <NavLink exact to="/dashboard" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('dashboard') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-400 ${pathname.includes('dashboard') && '!text-nexoGreen-500'}`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('dashboard') && 'text-nexoGreen-600'}`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('dashboard') && 'text-nexoGreen-200'}`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Dashboard</span>
                        </div>
                    </NavLink>
                </li>

                {/* jects */}

                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('project') && 'bg-gray-900'}`}>
                    <NavLink exact to="/testing-projects" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('project') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-400 ${pathname.includes('project') && 'text-nexoGreen-300'}`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                                <path className={`fill-current text-gray-700 ${pathname.includes('project') && '!text-nexoGreen-600'}`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('project') && 'text-nexoGreen-500'}`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Projects</span>
                        </div>
                    </NavLink>
                </li>

                {/* Settings */}
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('settings') && 'bg-gray-900'}`}>
                    <NavLink exact to="/settings/account" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('settings') && 'hover:text-gray-200'}`}>
                        <div className="flex">
                            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-600 ${pathname.includes('settings') && 'text-nexoGreen-600'}`} d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('settings') && 'text-nexoGreen-400'}`} d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('settings') && 'text-nexoGreen-600'}`} d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('settings') && 'text-nexoGreen-400'}`} d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z" />
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Settings</span>
                        </div>
                    </NavLink>
                </li>


            </ul>
        </div>
    </div>)
}

export default Iwaqi;
