import ApiCaller from '../lib/ApiCaller';

class AssuranceApi {

    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }
    getParteByPhoneNumber(phone:string):Promise<any> {
        const url = '/v1/parte/phone/' + phone
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    getParteRecords():Promise<any> {
        const url = '/v1/parte'
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }
    completeForm(payload:any): Promise<void | any | undefined> {
        return this.apiCaller.call('/v1/parte/succeed', 'POST', {...payload}).then(file=> {
            return file;
        });
    }

    getPartePDF(hash:string): Promise<void | any | undefined> {
        return this.apiCaller.download('/v1/parte/pdf/'+hash, 'GET').then(file=> {
            return file;
        });
    }

    getPisosContacts(hash:string): Promise<void | any | undefined> {
        return this.apiCaller.download(hash, 'GET').then(file=> {
            return file;
        });
    }

    getPisosStruct():Promise<any> {
        const url = '/v1/pad/byweek/all';
        return this.apiCaller.call(url, 'GET').then((doc: any) => {
            return doc
        });
    }


}

export default AssuranceApi;
