import React from 'react';
import {setCurrentPDF} from "../../../store/pdfStore";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

function PDFTableItem(props) {

  const dispatch = useDispatch();
  const history = useHistory();

  const statusColor = (status) => {
    switch (status) {
      case 'ANSWERED':
        return 'bg-green-100 text-green-600';
      case 'REQUESTED':
        return 'bg-yellow-100 text-yellow-600';
      case 'FAILURE':
        return 'bg-red-100 text-red-500';
      default:
        return 'bg-gray-100 text-gray-500';
    }
  };


  const getLang = (lang) =>  {
      switch (lang){
        case '':return <svg className="w-4 h-4 fill-current">
          <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
        </svg>;
        default: return <svg className="w-4 h-4 fill-current">
          <path d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z"></path>
        </svg>
      }
  }

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
          </label>
        </div>
      </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{props.obj.name}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{props.obj.folder}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800 flex space-between">
              {getLang(props.obj.content_ca)}&nbsp;
              {getLang(props.obj.content_en)}&nbsp;
              {getLang(props.obj.content_es)}&nbsp;
              {getLang(props.obj.content_fr)}
            </div>
        </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="space-x-1">
          <button className="text-gray-400 hover:text-gray-500 rounded-full" onClick={() => {dispatch(setCurrentPDF(props.obj));history.push(`/pdf-sample/edit`);}} >
            <span className="sr-only">Edit</span>
            <svg className="fill-current h-6 w-6" viewBox="0 0 24 24">
              <path className={`fill-current text-gray-600 hover:text-green-600`} d="M0 20h24v2H0z" />
              <path className={`fill-current text-gray-400 hover:text-green-400`} d="M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z" />
            </svg>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default PDFTableItem;
