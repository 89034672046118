import React, {useEffect, useState} from 'react';
import {days12After, prepareRoatPerDay} from "../../utils/Utils";
import { CSVLink } from "react-csv";
import {getCSV, getEntradas, getStatus} from "../../utils/csv";
import Info from "../Info";
import {backFletcher, forwardFletcher} from "./DashboardCard10";
import moment from "moment";

interface ContainerProps {
  builds: any;
  roats: any;
  pay: any;
  ack: any;
  setDetailedRow: Function;
}
const DashboardCard07roats:React.FC<ContainerProps>  = ({builds, roats, pay, ack,setDetailedRow}) => {

  const [rows, setRows] = useState<any>({});
  const [splitted, setSplitted] = useState<any>({});

  useEffect(()=>{
    const rows:any = prepareRoatPerDay(builds,roats,pay);
    setRows(rows);
    const wek:any = {};
    Object.keys(rows).filter(f=>moment(moment(f).add(1,'days')).isAfter()).forEach((m:string)=>wek[m]=rows[m])
    setSplitted(wek);
  },[])

  const showStatus = (date:string) =>{
    setDetailedRow(getEntradas(date,builds,roats,pay, ack).filter((f:any)=>{return f.status!='CANCELED'}));
  }
  const left = () => {
    const wek:any = {};
    Object.keys(rows).filter(f=>moment(moment(f).add(1,'days')).isBefore()).reverse().forEach((m:string)=>wek[m]=rows[m]);
    setSplitted(wek);
  }
  const right = () => {
    const wek:any = {};
    Object.keys(rows).filter(f=>moment(moment(f).add(1,'days')).isAfter()).forEach((m:string)=>wek[m]=rows[m]);
    setSplitted(wek);
  }

  return ( <>
        { roats &&
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center justify-between">
        <div className={'flex items-center'}>
          <h2 className="font-semibold text-gray-800">Estado de los ROATs </h2>
          <Info className="ml-2" containerClassName="min-w-80">
            <div className="text-sm">Gestiona desde aquí la obtención de los ROATs de los viajeros. Exportar a csv para enviar a Govern</div>
          </Info>
        </div>
        <div className={'flex items-center'}>
          <button className="text-gray-800 hover:text-indigo-800" onClick={(e) => { e.stopPropagation(); left(); }}><div className="sr-only">Left</div>{backFletcher('text-gray-800')}</button>
          <button className="text-gray-800 hover:text-indigo-800" onClick={(e) => { e.stopPropagation(); right(); }}><div className="sr-only">Right</div>{forwardFletcher('text-gray-800')}</button>
        </div>
      </header>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2"><div className="font-semibold text-left">  Fecha</div></th>
                <th className="p-2"><div className="font-semibold text-center">Reserva</div></th>
                <th className="p-2"><div className="font-semibold text-center">Pago</div></th>
                <th className="p-2"><div className="font-semibold text-center">Pers.</div></th>
                <th className="p-2"><div className="font-semibold text-center">Recibido</div></th>
                <th className="p-2"><div className="font-semibold text-center">Accion</div></th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-gray-100">
              {/* Row */}
              { Object.keys(splitted).map( (d,i) => {
                const roat = splitted[d];
                return <tr key={`c07R_${i}`} className="hover:bg-gray-100">
                    <td className="p-1">
                      <div className="flex items-center">
                        <svg className="flex-shrink-0 mr-1 sm:mr-1" width="32" height="32" viewBox="0 0 32 32">
                          <circle fill={roat.roats>0?'#047857':'#24292E'} cx="14" cy="14" r="14" />
                        </svg>
                        <div className="text-gray-800">{new Date(d).toLocaleDateString()}</div>
                      </div>
                    </td>
                    <td className="p-2"><div className="text-center">{roat.reservas}</div></td>
                    <td className="p-2"><div className={`text-center ${roat.pagos==0?'text-red-800':'text-green-800'}`}>{`${roat.pagos} - ${(roat.pagos/(roat.reservas)*100).toFixed(0)}%`}</div></td>
                    <td className="p-2"><div className="text-center text-light-blue-500">{roat.adults}</div></td>
                    <td className="p-2"><div className={`text-center ${roat.roats==0?'text-indigo-100':'text-indigo-800'}`}>{`${roat.roats} - ${(roat.adults)>0?(roat.roats/(roat.adults)*100).toFixed(0):0}%`}</div></td>
                    <td className="p-2"><div className="text-center text-indigo-500">
                      {roat.roats>0 && <>
                        <CSVLink
                            filename={`pisos_a_dias__roat_${d.substring(0,10)}.csv`}
                            className="btn bg-white border-gray-200 hover:border-gray-300 text-nexoGreen-500 mx-1"
                            data={getCSV(d.substring(0,10),roats)}>csv</CSVLink>
                      </> }
                        <button className="btn bg-white border-gray-200 hover:border-gray-300 text-nexoGreen-500"
                                onClick={(e) => { e.stopPropagation(); showStatus(d.substring(0,10)) }}
                        >status</button>
                    </div></td>
                  </tr>
                })
              }
            </tbody>
          </table>

        </div>
      </div>
    </div> }
      </>
  );
}

export default DashboardCard07roats;
