import React from 'react';
import Clients from '../../components/sections/Clients';
import Cta from '../../components/sections/Cta';
import HeroVideo from "../../components/sections/HeroVideo";
import FeaturesSplit from "../../components/sections/FeaturesSplit";
import GenericSection from "../../components/sections/GenericSection";
import Image from "../../components/elements/Image";
import imagePlaceholder from "../../images/image-placeholder.png";
import SectionHeader from "../../components/sections/partials/SectionHeader";
import Accordion from "../../components/elements/Accordion";
import AccordionItem from "../../components/elements/AccordionItem";
import videoPlaceholder from "../../images/video-placeholder.svg";
import Modal from "../../components/elements/Modal";
import HeroPricing from "../../components/sections/HeroPricing";
import HeroTeam from "../../components/sections/HeroTeam";
import Roadmap from "../../components/sections/Roadmap";
import Pricing from "../../components/sections/Pricing";
import FeaturesTiles from "../../components/sections/FeaturesTiles";
import FeaturesTabs from "../../components/sections/FeaturesTabs";

class AllComponents extends React.Component {

    state = {
        demoModalActive: false,
        videoModalActive: false
    }

    openVideoModal = (e) => {
        e.preventDefault();
        this.setState({ videoModalActive: true });
    }

    closeVideoModal = (e) => {
        e.preventDefault();
        this.setState({ videoModalActive: false });
    }
  render() {

      const genericSection02Header = {
          title: 'FAQ - Lorem ipsum is placeholder text commonly used.'
      }

      const genericSection05Header = {
          title: 'Video - Lorem ipsum is placeholder text commonly used.'
      }

    return (
      <React.Fragment>
        <HeroVideo className="illustration-section-01" />
        <Clients topDivider bottomDivider />
          <Cta split />
        <FeaturesSplit topDivider invertMobile imageFill />
        <Cta hasBgColor invertColor split />
          <FeaturesTiles/>
          <FeaturesTabs/>
          <HeroPricing className="illustration-section-01" />
          <Pricing/>
      <GenericSection>
          <div className="container-xs">
              <h2 className="mt-0">Lorem ipsum is placeholder text commonly used in the graphic.</h2>
              <p>

                  The most comprehensive, automated cloud communication and decision-making solutions, made easy for you and your clients. Ameniti answers texts, phone calls and voice search inquiries to provide excellent service, promote sales and reduce costs. Real-time analysis and automated decision-making to optimize results.
              </p>
              <p>
                  Smartcountry is on a mission to democratize AI with best-in-class customer communication management and business process automation.
              </p>
              <figure className={' pt-8 pb-8'}>
                  <Image className="image-larger" src={imagePlaceholder} alt="Placeholder" width={712} height={400} />
                  <figcaption className="text-color-low">A super-nice image <span role="img" aria-label="mile">😀</span></figcaption>
              </figure>
              <h4>Flexibility</h4>
              <p>
                  The most comprehensive, automated cloud communication and business intelligence solutions, made easy for you and your clients. Ameniti answers texts, phone calls and voice search inquiries to provide excellent service, promote sales and reduce costs. Real-time analysis and automated decision-making to optimize results.
              </p>
              <ul>
                  <li>Lorem ipsum dolor sit amet, consectetur.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur.</li>
              </ul>
              <p>
                  A strong, multifaceted team has launched Smartcountry in businesses as different as night and day. We are always on the hunt for new talent. Feel free to reach out to learn about new opportunities.
              </p>
          </div>
      </GenericSection>
      <GenericSection topDivider>
          <div className="container-xs">
              <SectionHeader data={genericSection02Header} className="center-content" />
              <Accordion>
                  <AccordionItem title="Nisi porta lorem mollis aliquam ut." active>
                      Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                  </AccordionItem>
                  <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                      Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                  </AccordionItem>
                  <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                      Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                  </AccordionItem>
                  <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                      Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                  </AccordionItem>
                  <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                      Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                  </AccordionItem>
              </Accordion>
          </div>
      </GenericSection>

      <GenericSection topDivider className="center-content">
          <div className="container-xs">
              <SectionHeader data={genericSection05Header} />
          </div>
          <div className="container-sm">
              <a
                  data-video="https://player.vimeo.com/video/174002812"
                  href="#0"
                  aria-controls="video-modal"
                  onClick={this.openVideoModal}
              >
                  <Image
                      className="has-shadow"
                      src={videoPlaceholder}
                      alt="Video"
                      width={712}
                      height={400} />
              </a>
              <Modal
                  id="video-modal"
                  show={this.state.videoModalActive}
                  handleClose={this.closeVideoModal}
                  video="https://player.vimeo.com/video/174002812"
                  videoTag="iframe" />
          </div>
      </GenericSection>
      <Cta hasBgColor invertColor split />
          <HeroTeam className="illustration-section-01" />
          <Roadmap topDivider />


      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default AllComponents;
