import TestProjectApi from "../api/TestProjectApi";
import ApiCaller from "../lib/ApiCaller";
// @ts-ignore
import FileSaver from "file-saver";
import {setCurrentSignedDocuments} from "../store/docsStore";
import {useDispatch} from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AssuranceApi from "../api/AssuranceApi";
import PadApi from "../api/PadApi";

export const sendToWriteDoc = (base:string, slug:string) => {
    const legalAPI = new TestProjectApi(new ApiCaller());
    legalAPI.sendToWriteDoc(base, slug).then((file) => {
        FileSaver.saveAs(file,slug+'.doc');
    }).catch(err => {
        console.error(err);
    });
}

export const saveJson = (json:any, fileName: string ) => {
    FileSaver.saveAs(new Blob([JSON.stringify(json)]),fileName);
}

export const sendToWritePDF = (sending:Function, base:string, slug:string) => {
    sending(true)
    const legalAPI = new TestProjectApi(new ApiCaller());
    legalAPI.sendToWritePDF(base, slug).then((file) => {
        FileSaver.saveAs(file,slug+'.pdf');
        sending(false);
    }).catch(err => {
        console.error(err);
    });
}
export const getPartePDF = (hash: string) => {
    const legalAPI = new AssuranceApi(new ApiCaller());
    legalAPI.getPartePDF(hash).then((file) => {
        FileSaver.saveAs(file,hash+'.pdf');
    }).catch(err => {
        console.error(err);
    });
}

export const getInstruccionesPDF = (place: string) => {
    const api = new PadApi(new ApiCaller());
    api.getInstruccionesPDF(place).then((file) => {
        FileSaver.saveAs(file,place+'.pdf');
    }).catch(err => {
        console.error(err);
    });
}

export const getInstruccionesPDFCustom = (id: string, lang:number) => {
    const api = new PadApi(new ApiCaller());
    api.getInstruccionesPDFCustom(id,lang).then((file) => {
        FileSaver.saveAs(file,id+'TEST.pdf');
    }).catch(err => {
        console.error(err);
    });
}

export const getVcard = (hash: string) => {
    const legalAPI = new AssuranceApi(new ApiCaller());
    legalAPI.getPisosContacts(hash).then((file) => {
        FileSaver.saveAs(file,'contacts.vcf');
    }).catch(err => {
        console.error(err);
    });
}

export const createPDFWithCertificate = (sending:Function, base:string, slug:string, userId:string) => {
    sending(true)
    const legalAPI = new TestProjectApi(new ApiCaller());
    legalAPI.createPDFWithCertificate(base, slug, userId).then((file) => {
        sending(false);
    }).catch(err => {
        console.error(err);
    });
}

export const updateCertificateList = async (dispatch: Function, userId: string) => {
    var legalAPI = new TestProjectApi(new ApiCaller());
    const items = await legalAPI.getCertificatesRaw(userId);
    dispatch(setCurrentSignedDocuments(items))
}
export const createPDFLocal = async ( quotes: any) => {
    // @ts-ignore
    //var quotes:any = document.getElementById('to-print-pdf');
    // @ts-ignore
    html2canvas(quotes).then((canvas:any) => {
        var pdf = new jsPDF('p', 'pt', 'letter');
        for (var i = 0; i <= quotes.clientHeight/980; i++) {
            //! This is all just html2canvas stuff
            var srcImg  = canvas;
            var sX      = 0;
            var sY      = 980*i; // start 980 pixels down for every new page
            var sWidth  = 900;
            var sHeight = 980;
            var dX      = 0;
            var dY      = 0;
            var dWidth  = 900;
            var dHeight = 980;
            // @ts-ignore
            const onePageCanvas = document.createElement("canvas");
            onePageCanvas.setAttribute('width', '900');
            onePageCanvas.setAttribute('height', '980');
            var ctx:any = onePageCanvas.getContext('2d');
            // details on this usage of this function:
            // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
            ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

            var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

            var width         = onePageCanvas.width;
            var height        = onePageCanvas.clientHeight;

            if (i > 0) {
                pdf.addPage(); //8.5" x 11" in pts (in*72)
            }
            pdf.setPage(i+1);
            pdf.addImage(canvasDataURL, 'PNG', 20, 20, (width*.62), (height*.62));

        }
        pdf.save('Test.pdf');
    });
}
