import React from 'react';

// import sections


function Contact() {

    return (
        <React.Fragment>
              
        {/* Page header */}

            <div className="sub-title-smart text-smartCountry-50 max-w-3xl mx-auto text-center mt-20 mb-20">
                <h1 className="h1 text-smartCountry-50' mb-4">Hablemos</h1>
                <p className="text-xl text-gray-600 dark:text-gray-400">Gracias por su interés. Ingrese sus datos a la derecha y alguien de nuestro equipo se comunicará con usted en breve.</p>
            </div>

            {/* Contact form */}
              <form className="max-w-xl mx-auto">
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">Nombre<span className="text-red-600">*</span></label>
                    <input id="first-name" type="text" className="form-input w-full" placeholder="Su nombre" required />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">Apellido <span className="text-red-600">*</span></label>
                    <input id="last-name" type="text" className="form-input w-full" placeholder="Su apellido" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" htmlFor="company">Correo electrónico profesional <span className="text-red-600">*</span></label>
                    <input id="email" type="text" className="form-input w-full" placeholder="Necesitamos su correo electrónico profesional" required />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <div className="flex justify-between items-center mb-1">
                      <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium" htmlFor="message">Mensaje</label>
                      <span className="text-sm text-gray-500">Optional</span>
                    </div>
                    <textarea id="message" rows="4" className="form-textarea w-full" placeholder="¿Quiere una demostración?"></textarea>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mt-6 mb-40">
                  <div className="w-full px-3">
                    <button className="py-4 btn text-white bg-nexoGreen-500 hover:bg-nexoGreen-600 w-full flex items-center">
                      <span>Quiero Demostración</span>
                      <svg className="w-3 h-3 flex-shrink-0 mt-px ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path className="fill-current" d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
 
        </React.Fragment>
    );
  }
  
  export default Contact;
  