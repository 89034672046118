import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import {Link} from "react-router-dom";

import heromedia from "./../../images/hero-image.svg"
import SectionHeader from "./partials/SectionHeader";
import videoPlaceholder from "../../images/video-placeholder.svg";
import Modal from "../elements/Modal";
import GenericSection from "./GenericSection";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroIvr extends React.Component {

  state = {
    demoModalActive: false,
    videoModalActive: false
  }

  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {

  const {className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, ...props} = this.props;
  const outerClasses = classNames('hero section center-content', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);
  const innerClasses = classNames('hero-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              <h1 className="title-smart text-6xl md:text-6xl text-smartCountry-50 font-bold mb-1" data-reveal-delay="300">
              Respuesta de Voz Interactiva
              </h1>
              <div className="container-xs">
                <p className="sub-title-smart text-4xl md:text-2xl text-smartCountry-51 mt-10 mb-10 reveal-from-top" data-reveal-delay="400">
                Haga crecer su negocio con mensajería conversacional.
<br/>
                </p>
              </div>
            </div>
              <div className="container-sm">
                <a
                    data-video="https://player.vimeo.com/video/207817280"
                    href="#0"
                    aria-controls="video-modal"
                    onClick={this.openVideoModal}
                >
                  <Image
                      className="has-shadow"
                      src={videoPlaceholder}
                      alt="Video"
                      width={712}
                      height={400} />
                </a>
                  <Modal
                    id="video-modal"
                    show={this.state.videoModalActive}
                    handleClose={this.closeVideoModal}
                    video="https://player.vimeo.com/video/207817280"
                    videoTag="iframe" />
              </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: 'absolute',
  left: '-5.9%',
  right: '5.9%',
  top: '-12.74%'
}

HeroIvr.propTypes = propTypes;
HeroIvr.defaultProps = defaultProps;

export default HeroIvr;
