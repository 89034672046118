
//------------  state definition
export interface DocsState {
    pdf: any,
    pdfs: any[]
}

//------------ actions
export const SET_CURRENT_DOC = 'SET_CURRENT_DOC'
export const GET_DRAFTS_LIST = 'GET_DRAFTS_LIST'

interface SetCurrentDocAction {
  type: typeof SET_CURRENT_DOC,
  payload: any
}

interface GetDraftsAction {
  type: typeof GET_DRAFTS_LIST,
  payload: any[]
}


export function setCurrentPDF(doc: any): DocsActionTypes {
    return {
      type: SET_CURRENT_DOC,
      payload: doc
    }
}
export function setCurrentPDFs(drafts: any[]) : DocsActionTypes  {
    return {
      type: GET_DRAFTS_LIST,
      payload: drafts
    }
}


export type DocsActionTypes = SetCurrentDocAction | GetDraftsAction ;

//------------ reducer
const initialState: DocsState = {
    pdf: {},
    pdfs: []

}

export function pdfReducer( state = initialState, action: DocsActionTypes): DocsState {
  switch (action.type) {
    case SET_CURRENT_DOC: {
      return {
        ...state,
          pdf: action.payload
      }
    }
    case GET_DRAFTS_LIST: {
      return {
        ...state,
          pdfs: action.payload
      }
    }
    default:
      return state
  }
}
