import React from 'react';

// import sections
import HeroHospitality from '../../components/sections/HeroHospitality';
import Clients from '../../components/sections/Clients';
import FeaturesTilesHospitaly from '../../components/sections/FeaturesTilesHospitaly';


// import elements

function IAHospitality() {

  return (
      <React.Fragment>
        <HeroHospitality className="illustration-section-01"/>
        <Clients topDivider bottomDivider />
        <FeaturesTilesHospitaly />

      </React.Fragment>
  );
}

export default IAHospitality;
