import React, { useState } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import SearchForm from '../../partials/actions/SearchForm';
import ContactosTable from "../../partials/pisosadias/ContactosTable";
import PisosADiasContactos from "./PisosADiasContactos";
import moment from "moment";


const VCardsContacts = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const handleSelectedItems = (selectedItems:any[]) => {setSelectedItems([...selectedItems]);};

    return (
        <>
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="sm:flex sm:justify-between sm:items-center mb-5">

                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">VCards ✨</h1>
                            </div>
                            {/* Right: Actions */}
                            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                                {/* Add member button */}
                                <a href={`https://smartcountry.io/api/v1/pad/2022/${moment(new Date()).week()}/${moment(new Date()).week()+4}`} className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" aria-controls="feedback-modal" >
                                    <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                    </svg>
                                    <span className="hidden xs:block ml-2">Descarga Todos</span>
                                </a>
                            </div>
                        </div>

                        {/* Table */}
                        <PisosADiasContactos />

                    </div>
                </main>

            </div>

        </div>

            </>
    );
}

export default VCardsContacts;
