import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {forwardFletcher} from "./DashboardCard10";
import {formatDateTimeConversational, getParsedDate} from "../../shared/utils/dateTime";
import Notifications from "../../lib/notifications";
import WhatsappChatForm from "./WhatsappChatForm";
import Info from "../Info";

function DashboardCard11() {

  const notifications = useSelector((state) => state.notification.options);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [wass, setMsg] = useState({recipient:{}});

  return (
      <> { notifications.length>0 &&
          <div className="col-span-full xl:col-span-6 bg-white rounded-sm border border-gray-200 pb-6 mb-6">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Mensajes en tiempo real</h2>
          <Info className="ml-2" containerClassName="min-w-80">
              <div className="text-sm">Visiona aquí los mensajes que ocurren mientras tienes la aplicación encendida, para poder contestar</div>
          </Info>
      </header>
      <div className="p-3">

        {/* Card content */}
        {/* "Today" group */}
        <div>
          <header className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2">Hoy</header>
          <ul className="my-1">
            { notifications && notifications.reverse().map(n => {
                const msg = n.title;
                if (!msg) return;
                const recipient = msg.recipient;
                if (!recipient) return;
                return <li className="flex px-2">
                <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2 hover:bg-gray-100">

                  <div className="flex-grow flex" >

                      <button onClick={(e) => { e.stopPropagation();setMsg(msg);setFeedbackModalOpen(true)}} className="w-9 h-9 rounded-full flex-shrink-0 bg-green-500 my-2 mr-3">
                          {forwardFletcher}
                      </button>

                    <div className="self-center">
                        <div>
                            <p className="text-sm text-gray-800">{recipient.place||''}{recipient.phone||''}</p>
                            <p className="text-xm text-indigo-800">{recipient.name||''}</p>
                            <p className="text-xm text-indigo-300 italic">{formatDateTimeConversational(new Date(msg.timestamp * 1000))}</p>
                        </div>
                        <p className="font-medium  text-gray-400 hover:text-gray-900">{msg.body||''}</p>
                    </div>
                  </div>
                </div>
              </li> }
            ) }
          </ul>
        </div>

      </div>
        <WhatsappChatForm feedbackModalOpen={feedbackModalOpen} setFeedbackModalOpen={setFeedbackModalOpen} msg={wass}/>
    </div> } </>
  );
}

export default DashboardCard11;
