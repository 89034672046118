import TestProject, {ProjectType,TestProjectStatus} from '../lib/common/models/testProject';

//------------  state definition
export interface DocsState {
    doc: TestProject,
    drafts: TestProject[],
    signed: TestProject[],
    ready: boolean
}

//------------ actions
export const SET_CURRENT_DOC = 'SET_CURRENT_DOC'
export const GET_DRAFTS_LIST = 'GET_DRAFTS_LIST'
export const GET_SIGNED_LIST = 'GET_SIGNED_LIST'

interface SetCurrentDocAction {
  type: typeof SET_CURRENT_DOC,
  payload: TestProject
}

interface GetDraftsAction {
  type: typeof GET_DRAFTS_LIST,
  payload: TestProject[]
}

interface GetSignedAction {
  type: typeof GET_SIGNED_LIST,
  payload: TestProject[]
}

export function setCurrentDoc(doc: TestProject): DocsActionTypes {
    return {
      type: SET_CURRENT_DOC,
      payload: doc
    }
}
export function setCurrentDrafts(drafts: TestProject[]) : DocsActionTypes  {
    return {
      type: GET_DRAFTS_LIST,
      payload: drafts
    }
}

export function setCurrentSignedDocuments(drafts: TestProject[]) : DocsActionTypes  {
    return {
      type: GET_SIGNED_LIST,
      payload: drafts
    }
}

export type DocsActionTypes = SetCurrentDocAction | GetDraftsAction | GetSignedAction;

//------------ reducer
const initialState: DocsState = {
    doc: new TestProject(
        {type:ProjectType.GENERAL_PURPOSE,customName:'',customDescription:'', content:'',
            id: '',
            _id: '',
            displayName:'',
            description:'',
            createdBy: '',
            sourceSlug: '',
            expirationDate: new Date(),
            status: TestProjectStatus.PENDING,
            createdAt: new Date(),
            updatedAt: new Date(),
            platformIds: []}
        ),
    ready: false,
    drafts: [],
    signed: [],

}

export function docsReducer( state = initialState, action: DocsActionTypes): DocsState {
  switch (action.type) {
    case SET_CURRENT_DOC: {
      return {
        ...state,
          doc: action.payload,
          ready: true
      }
    }
    case GET_DRAFTS_LIST: {
      return {
        ...state,
          drafts: action.payload,
          ready: true
      }
    }
    case GET_SIGNED_LIST: {
      return {
        ...state,
          signed: action.payload,
          ready: true
      }
    }
    default:
      return state
  }
}
