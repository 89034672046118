interface NZDocumentInfo {
  id: string;
  _id: string;
  createdBy: string;
  type: NZDocumentType;
  fileHash: string;
  mimeType: string;
  expirationDate: Date;
  status: NZDocumentStatus;
  displayName: string;
  description: string;
  createdAt: Date;
  projectId: string;
}

export enum NZDocumentType {
  GENERAL_PURPOSE = 'GENERAL_PURPOSE',
  LEGAL_DOCUMENT = 'LEGAL_DOCUMENT',
  CONTRACT = 'CONTRACT',
  PARTE_IMAGE = 'PARTE_IMAGE',
  OTHER = 'OTHER',
  INSTRUCCIONES_IMAGE = 'INSTRUCCIONES_IMAGE',
  PDF_IMAGE = 'PDF_IMAGE',
}

export enum NZDocumentStatus {
  REVOKED = 'REVOKED',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

class NZDocument {
  id: string|undefined;
  slug: string;
  createdBy: string|undefined;
  parentId: string|undefined;
  type: NZDocumentType;
  fileHash: string;
  mimeType: string;
  expirationDate: Date|undefined;
  status: NZDocumentStatus|undefined;
  displayName: string|undefined;
  description: string|undefined;
  createdAt: Date|undefined;
  updatedAt: Date|undefined;
  content: string|undefined;
  projectId: string|undefined;


  constructor(file: NZDocumentInfo|any) {
    this.id = file.id ? file.id : file._id;
    this.slug = file.slug;
    this.parentId = file.parentId;
    this.type = file.type;
    this.fileHash = file.fileHash;
    this.mimeType = file.mimeType;
    this.expirationDate = file.expirationDate;
    this.status = file.status;
    this.displayName = file.displayName;
    this.description = file.description;
    this.createdAt = file.createdAt;
    this.createdBy = file.createdBy;
    this.updatedAt = file.updatedAt;
    this.content = file.content;
    this.projectId = file.projectId;
  }

  toObject(): any {
    return {
      id: this.id,
      slug: this.slug,
      parentId: this.parentId,
      type: this.type,
      fileHash: this.fileHash,
      mimeType: this.mimeType,
      expirationDate: this.expirationDate,
      status: this.status,
      displayName: this.displayName,
      description: this.description,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      content: this.content,
      projectId: this.projectId
    };
  }
}

export default NZDocument;
