import React, {useState} from "react";
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import './antd.css';
import {UploadFile} from "antd/lib/upload/interface";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/store";
import ApiCaller from "../../lib/ApiCaller";
import { toast } from "react-toastify";
import ImageGalleryComp from "../../components/ImageGalleryComp";
import DocumentsApi from "../../api/DocumentsApi";
import UploadImagePresenter from "./UploadImagePresenter";

export interface upload {
    selectedFile: string[];
    loaded: number
}

const  QuestionUploadPictures = ( {questionName='', images=[],uploadFromGallery=false,updateImages}:{questionName?:string, images?:any[], uploadFromGallery?:boolean, updateImages: Function} ) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { Dragger } = Upload;
    const [uploadState, setUploadState] = useState<upload>({ selectedFile: [], loaded: 0 });
    const currentUser = useSelector((state: AppState) => state.session.userInfo);

    const [modal, setModal] = useState<boolean>(false);
    let view = { setUploadState: setUploadState, alertSuccess: (data:any) => {updateImages(data)}, alertError: toast.error };
    const presenter:UploadImagePresenter  = new UploadImagePresenter(currentUser, view);

    const updateFileList = (customfileList: UploadFile[]) => {
        setFileList(customfileList);

    }

    React.useEffect(() => {
        if (images) updateFileList([...fileList, ...images]);
    }, []);


    const onChangeDrag = (info:any) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            updateFileList(info.fileList);
        }
        if (status === 'done') {
            //toast.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            //toast.error(`${info.file.name} file upload failed.`);
        }
    }
    const onPreview = async (file:any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        // @ts-ignore
        imgWindow.document.write(image.outerHTML);
    };

    const upload = async (uploadOptions:any) => {
        presenter.upload(uploadOptions, questionName);
    }

    const remove = (payload:any) => {
        updateImages(payload.response.fileHash);
        //const {uid} = payload;
        //const questionApi = new QuestionApi(new ApiCaller(currentUser.token));
        //questionApi.removeQuestionImage(uid).then(data=>console.log(data)).catch(e=>console.error(e));
    }

    const imageClick = async (event:any) => {
        setModal(false);
        const src = event.target.src;
        const hash=src.substring(src.lastIndexOf('/')+1);

        const documentsApi = new DocumentsApi(new ApiCaller());
        const imageDocument:any = await documentsApi.getByHash(hash);
        console.log(JSON.stringify(imageDocument));
        const fileDoc:any = {
                uid:imageDocument.slug,
                name: imageDocument.displayName,
                size: 100,
                type: imageDocument.mimeType,
                status: "done",
                url: src
            };
        updateFileList([...fileList, fileDoc])
        await documentsApi.addResourcefromGalleryToQuestion(imageDocument.id, questionName);
    }

    return (
        <>
            {fileList.length>0 && <p>Perfecto, el parte tiene imágenes</p>}
            { questionName=='' && <div className="foto-upload  bg-gray"><p>Please inform syllabus, module and topic to upload images</p></div>}
            { questionName!=='' && <div className="foto-upload  bg-gray">
                <ImgCrop grid aspect={400/300}>
                    <Dragger
                        accept="image/png, image/jpeg"
                        onPreview={onPreview}
                        name='file'
                        multiple={true}
                        listType="picture-card"
                        onChange={onChangeDrag}
                        onRemove={remove}
                        customRequest={upload}
                        fileList={fileList}>

                        <p className="ant-upload-text">upload...</p>
                        <p className="ant-upload-hint">+10</p>

                    </Dragger>
                </ImgCrop>
                {uploadFromGallery && <button className="btn-round" color="success"  onClick={()=>setModal(true)} >Add Image from Gallery</button>}
            </div>

            }

            { modal && <ImageGalleryComp dispachClick={imageClick}></ImageGalleryComp> }
            {/*<ul className="foto-upload  bg-gray"><li><ImgCrop grid aspect={400/300}><Upload accept="image/png, image/jpeg" name='file' action='https://www.mocky.io/v2/5cc8019d300000980a055e76' listType="picture-card" fileList={fileList} onChange={onChange} onPreview={onPreview}>{fileList.length < 10 && '+ Insert a picture'}</Upload></ImgCrop></li></ul>*/}
        </>
    );
}

export default QuestionUploadPictures;
