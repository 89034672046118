import React from 'react';
import TestingProjectBase from "./TestingProjectBase";
import TestingPanel from "../../../partials/testing/TestingPanel";
import TestingExecutions from "../../../partials/testing/TestingExecutions";
import TestingPlan from "../../../partials/testing/TestingPlan";
import TestingCrowd from "../../../partials/testing/TestingCrowd";

export const TestingProjectDetail = () => <TestingProjectBase><TestingPanel/></TestingProjectBase>
export const TestingProjectExecutions = () => <TestingProjectBase><TestingExecutions/></TestingProjectBase>
export const TestingProjectTestingPlan = () => <TestingProjectBase><TestingPlan/></TestingProjectBase>
export const TestingProjectCrowd = () => <TestingProjectBase><TestingCrowd/></TestingProjectBase>

