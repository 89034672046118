import React from 'react';

// import sections
import HeroIvr from '../../../components/sections/HeroIvr';
import FeaturesTilesManager from '../../../components/sections/FeaturesTilesManager';
import FeaturesSplitManager from '../../../components/sections/FeaturesSplitManager';
import CtaManager from '../../../components/sections/CtaManager';


function InteractiveVoiceResponse () {

    return (

         <React.Fragment>
             <HeroIvr />
         </React.Fragment>
 
   );
 }
 
    
    export default InteractiveVoiceResponse;