import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import registered from '../../images/testing_usabilidad.svg';
import SectionHeader from "../../components/sections/partials/SectionHeader";

function Registered() {

    const sectionHeader = {
        title: 'Hemos creado tu cuenta de usuario',
        paragraph: 'Te hemos enviado un correo para confirmar tu email, Por favor, accede al enlace y validaremos tu cuenta.'
    };

  return (
    <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <div className="max-w-2xl m-auto mt-16">

              <div className="text-center px-4">
                <div className="inline-flex mb-8">
                  <img src={registered} width="300" height="176" alt="Registered" />
                </div>
                <SectionHeader data={sectionHeader} className="center-content" />
                <Link to="/" className="btn bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white">Volver al inicio</Link>
              </div>

            </div>

          </div>
        </main>
  );
}

export default Registered;
