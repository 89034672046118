import React, {useEffect, useState} from 'react';
import ModalBasic from "../../components/ModalBasic";
import Tooltip from "../../components/Tooltip";
import {useDispatch, useSelector} from "react-redux";
import TestProjectApi from "../../api/TestProjectApi";
import ApiCaller from "../../lib/ApiCaller";
import {setCurrentDoc, setCurrentDrafts} from "../../store/docsStore";
import toast from "../../shared/utils/toast";
import {AppState} from "../../store/store";
import {str2Slug} from "../../utils/Base64Content";
import {generateErrors, is} from "../../shared/utils/validation";
import {useHistory} from "react-router-dom";
import TestProject from "../../lib/common/models/testProject";
import {getCSV} from "../../utils/csv";
import bed from "../../images/transactions-image-02.svg";

interface props {
    feedbackModalOpen:boolean;
    setFeedbackModalOpen: Function;
    msg: any;
}

const WhatsappChatForm: React.FC<props> = ({feedbackModalOpen,setFeedbackModalOpen, msg}) => {

    const currentUser:any = useSelector((state: AppState) => state.session.userInfo);
    const [description,setDescription] = useState('');
    const [errors,setErrors] = useState<any>({});

    useEffect(()=>{
        setErrors({});
    },[]);


    const butonainens:any = [
        {t:'Bienvenido',b:'Perfecto! os esperamos, tenéis las instruciones en el pdf que os hemos enviado'},
        {t:'Entrada',b:'Muy bien! la llaves en la caja'},
        {t:'Salida',b:'De acuerdo. Muchas gracias y hassta la próxima'},
    ]

    const buttonFriend = () => butonainens.map((b:any,i:any)=><button key={`b_${i}`} onClick={(e)=>{e.stopPropagation();setDescription(b.b)}} className="text-xs inline-flex font-medium bg-light-blue-100 text-light-blue-600 rounded-full text-center px-2.5 py-1">{b.t}</button>)

    return (
            <div className="w-full max-h-full">
            {/* Start */}
            <ModalBasic id="feedback-modal-2" modalOpen={feedbackModalOpen} setModalOpen={setFeedbackModalOpen} title={`Mensaje de Whatsapp ${msg.from||''}`}>
            {/* Modal content */}
            <div className="px-5 py-4">
                <div className="space-y-3">
                    <div className="flex items-center">
                        <img className="op sv rounded-full"
                             src={bed} width="36" height="36"
                             alt="Transaction 02"/>
                        <div className="text-gray-800">{msg.recipient.place||''} {msg.recipient.date||''} </div>
                    </div>
                    <div>
                        {buttonFriend()}
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">Mensaje <span className="text-red-500">*</span></label>
                        <p className="text-sm text-gray-400 hover:text-gray-900">{msg.body||''}</p>
                    </div>
                    {msg.botResponse && <div>
                        <label className="block text-sm font-medium mb-1">Respuesta del bot</label>
                        <p className="text-sm text-gray-400 hover:text-gray-900">{msg.botResponse}</p>
                    </div> }
                    <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="feedback">Respuesta<span className="text-red-500">*</span></label>
                        <textarea id="feedback" className={`form-textarea w-full px-2 py-1 ${errors.description && 'border-red-300'}`} rows={4} required  value={description} onChange={(e)=> setDescription(e.target.value)}></textarea>
                        {errors.description && <div className="text-xs mt-1 text-red-500">This field is required!</div>}
                    </div>
                </div>
            </div>
            {/* Modal footer */}
            <div className="px-5 py-4 border-t border-gray-200">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button className="btn-sm bg-nexoGreen-500 hover:bg-nexoGreen-600 text-white" >Send</button>
                </div>
            </div>
        </ModalBasic>
            {/* End */}
    </div>
    )
}

export default WhatsappChatForm;
