import React, {useState} from 'react';
import SendedParte from "./SendedParte";
import { RouteComponentProps } from 'react-router';

interface IExampleComponentProps extends RouteComponentProps<{
    parteHash:string;
}> {};

const SucceedParte:React.FC<IExampleComponentProps> = ({match}) => {

    const parteHash =match.params.parteHash;
    React.useEffect( () =>  {
    }, []);

    return (
        <>
            <SendedParte message={'El parte se envió correctamente'} subTitle={'La compañía aseguradora se pondrá en contacto en los próximos días.'} pdfLink={parteHash} />
        </>
    )
}

export default SucceedParte;
