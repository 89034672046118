

class UserDetail {
    _id: any;
    userId: any|undefined;
    description: string|undefined;
    images: string[]|undefined;
    country: string|undefined;
    phone: string|undefined;
    businessName: string|undefined;
    businessId: string|undefined;
    location: string|undefined;

    constructor(description: string, country?: string, phone?: string, images?: string[], businessName?: string, businessId?: string, location?: string ) {
        this.description = description;
        this.country = country;
        this.phone = phone;
        this.images = images;
        this.businessName= businessName;
        this.businessId= businessId;
        this.location= location;
    }

    load(data: any): UserDetail {
        return new UserDetail(data.description, data.country, data.phone, data.images,data.businessName,data.businessId,data.location,);
    }

    init(): UserDetail{
        return new UserDetail('','','',[],'','','');
    }

    toObject(): any {
        return { description: this.description, country: this.country, phone: this.phone, images: this.images, userId: this.userId,
            businessName: this.businessName, businessId: this.businessId, location: this.location };
    }

}

export default UserDetail
