import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Team from "./Team";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class HeroTeam extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-03 reveal-from-top',
      pushLeft && 'push-left'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="hero-content center-content">
              <div className="container-xs">
                <h1 className="m-0 reveal-from-top">
                  Llevamos más de 15 años en el sector de seguros
                </h1>
              </div>
            </div>
            <div className="hero-figure">
              <div className={tilesClasses} data-reveal-delay="100">
                {/*<Team />*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroTeam.propTypes = propTypes;
HeroTeam.defaultProps = defaultProps;

export default HeroTeam;
